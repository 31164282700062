import { Col, Input, Row, notification } from "antd";
import { ValidateErrorEntity } from "rc-field-form/es/interface";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import CommonButton from "../../components/Common/Button";
import CommonCheckbox from "../../components/Common/Checkbox";
import CommonForm from "../../components/Common/Form";
import CommonFormItem from "../../components/Common/FormItem";
import CommonSelect from "../../components/Common/Select";
import { saveProfile } from "../../redux/actions/profile.actions";
import background from "../../resources/images/login/background-login.jpg";
import { ReactComponent as Logo } from "../../resources/images/logo_small.svg";
import userServices from "../../services/accountManagement.service";
import { LANGUAGE_LIST } from "../../utils/constans";
import LocalStorage from "../../utils/localStorage";
import { LoginData } from "../../utils/types/accountManagement";

const localLanguage = LocalStorage.getInstance().read("language");
function Login() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const formRef = useRef<any>();
  const [currentLanguage, setCurrentLanguage] = useState(localLanguage || LANGUAGE_LIST[0]?.value);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    dispatch(saveProfile(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = async (values: LoginData) => {
    setIsLoading(true);
    const resp = await userServices.login(values);
    const data = resp?.data?.data;
    if (resp.code == 408) {
      notification.error({
        message: data?.message || t("commonError.network"),
      });
      setIsLoading(false)
      return
    }
    if (resp?.status === 200) {
      LocalStorage.getInstance().save("accessToken", data?.accessTokenInfo?.accessToken);
      getProfile();
    } else {
      if (formRef?.current) {
        formRef?.current?.setFields([
          {
            name: "username",
            errors: [""],
          },
          {
            name: "password",
            errors: [t("login.wrongPassword")],
          },
        ]);
      }
    }
    setIsLoading(false);
  };

  const getProfile = async () => {
    const resp = await userServices.getProfile();
    const data = resp?.data;

    if (resp?.code === "408") {
      LocalStorage.getInstance().save("accessToken", null);
      notification.error({
        message: t("commonError.vendorBlock"),
      });
    } else if (resp?.status === 200) {
      dispatch(saveProfile(data?.data));
      const redirectUrl = LocalStorage.getInstance().read("redirectUrl");
      if (redirectUrl) {
        LocalStorage.getInstance().save("redirectUrl", redirectUrl);
        window.location.href = redirectUrl;
      } else {
        window.location.reload();
      }
    } else {
      notification.error({
        message: resp?.data?.message || t("commonError.oopsSystem"),
      });
    }
  };

  const onFinishFailed = (errorInfo: ValidateErrorEntity<LoginData>) => {
    console.log("Failed:", errorInfo);
  };

  const handleChangeLanguage = (value: string) => {
    setCurrentLanguage(value);
    LocalStorage.getInstance().save("language", value);
    window.location.reload();
  };

  return (
    <div className="login-container" style={{ backgroundImage: `url(${background})` }}>
      <div className="login-box">
        <div className="text-center">
          <Logo className="style-size-logo" />
        </div>

        <h3 className="txt-welcome">{t("login.welcome")}</h3>
        <h2 className="txt-title">{t("login.title")}</h2>
        <CommonForm
          formRef={formRef}
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          size={"large"}
        >
          <CommonFormItem
            name="username"
            validateTrigger={"onBlur"}
            rules={[{ required: true, message: t("validate.usernameRequired") as string }]}
          >
            <Input placeholder={t("login.usernamePlaceholder") as string} allowClear />
          </CommonFormItem>
          <CommonFormItem
            name="password"
            validateTrigger={"onBlur"}
            rules={[{ required: true, message: t("validate.passwordRequired") as string }]}
          >
            <Input.Password allowClear placeholder={t("login.passwordPlaceholder") as string} />
          </CommonFormItem>

          <Row>
            <Col span={12}>
              <CommonFormItem name="remember" valuePropName="checked" style={{ marginBottom: 0 }}>
                <CommonCheckbox>{t("login.rememberLabel")}</CommonCheckbox>
              </CommonFormItem>
            </Col>
            <Col span={12}>
              <CommonButton
                loading={isLoading}
                type="primary"
                className="btn-login"
                htmlType="submit"
              >
                {t("login.submit")}
              </CommonButton>
            </Col>
          </Row>

          <div className="language_select">
            <CommonSelect
              value={currentLanguage}
              onChange={handleChangeLanguage}
              options={LANGUAGE_LIST}
            />
          </div>
        </CommonForm>
      </div>
    </div>
  );
}

export default Login;
