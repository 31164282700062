import { UserOutlined } from "@ant-design/icons";
import { Avatar, Dropdown, Space } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { SIDEBAR_ITEM_HREF } from "../../utils/constans/sidebar";
import LocalStorage from "../../utils/localStorage";

function DashboardSearch() {
  const { t } = useTranslation();
  const { profile } = useSelector((state: any) => state?.profileReducer);

  const logout = () => {
    window.location.href = SIDEBAR_ITEM_HREF.home;
    LocalStorage.getInstance().save("accessToken", null);
  };

  const itemsDropdownProfile = [
    {
      key: "1",
      label: (
        <Link to={`${SIDEBAR_ITEM_HREF.profile}?tab=account-profile`}>
          {t("layout.header.personalInfo")}
        </Link>
      ),
    },
    {
      key: "2",
      label: (
        <Link to={`${SIDEBAR_ITEM_HREF.profile}?tab=change-password`}>
          {t("layout.header.changePassword")}
        </Link>
      ),
    },
    {
      key: "3",
      label: <div onClick={logout}>{t("layout.header.logout")}</div>,
    },
  ];

  return (
    <Dropdown menu={{ items: itemsDropdownProfile }} trigger={["click"]} placement="bottomRight">
      <Space className="cursor-pointer">
        <Avatar className="header-avatar" src={profile?.avatar} icon={<UserOutlined />} />
      </Space>
    </Dropdown>
  );
}

export default DashboardSearch;
