import { EditOutlined } from "@ant-design/icons";
import { Form, Space, notification } from "antd";
import { forwardRef, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CommonButton from "../../../components/Common/Button";
import CommonForm from "../../../components/Common/Form";
import CommonFormItem from "../../../components/Common/FormItem";
import { saveProfile } from "../../../redux/actions/profile.actions";
import {
  default as accountsServices,
  default as userServices,
} from "../../../services/accountManagement.service";
import { REGEX_EMAIL, REGEX_PHONE_NUMBER } from "../../../utils/constans";
import { IGroupDetailData } from "../../../utils/types/groupManagement";

function AccountProfile(props: any, ref: any) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const { profile } = useSelector((state: any) => state?.profileReducer);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    isEdit,
    onClear,
  }));

  const onFinish = (values: any) => {
    onSubmit(values);
  };

  const getProfile = async () => {
    const resp = await userServices.getProfile();
    const data = resp.data;
    if (resp?.status === 200) {
      dispatch(saveProfile(data?.data));
    }
  };

  const onSubmit = async (values: any) => {
    const body = {
      ...profile,
      name: values?.name,
      phone: values?.phone,
      email: values?.email,
      authorities: null,
      grantedAuths: null,
    };

    setIsLoading(true);
    const resp = await accountsServices.updateAccount(profile.id, body);
    const data = resp?.data;
    if (resp?.status === 200) {
      notification.success({
        message: t("profilePage.message.updateSuccess"),
      });
      setIsEdit(false);
      getProfile();
    } else {
      notification.error({
        message: data?.message || t("commonError.oopsSystem"),
      });
    }
    setIsLoading(false);
  };

  const onClear = () => {
    form.resetFields();
    setIsEdit(false);
  };

  return (
    <div className="profile-tab-content">
      <div className="profile-tab-content-header">
        <div className="profile-tab-content-header-left">
          {t("profilePage.accountProfileTabTitle")}
        </div>
        <div className="profile-tab-content-header-right">
          {!isEdit && <EditOutlined className="cursor-pointer" onClick={() => setIsEdit(true)} />}
        </div>
      </div>

      <CommonForm
        form={form}
        onFinish={onFinish}
        layout="horizontal"
        initialValues={{
          name: profile?.name,
          username: profile?.username,
          role: profile?.groups?.map((item: IGroupDetailData) => item?.groupName)?.join(", "),
          email: profile?.email,
          phone: profile?.phone,
          sourceStr: profile?.sourceStr,
          modifiedDate: profile?.modifiedDateStr,
        }}
        isLoading={isLoading}
      >
        <CommonFormItem
          isView={!isEdit}
          name="name"
          label={t("profilePage.label.fullName")}
          placeholder={t("profilePage.placeholder.fullName") as string}
          rules={[
            {
              whiteSpace: true,
              required: true,
              message: `${t("validate.input")} ${t("profilePage.label.fullName")}.`,
            },
          ]}
          showRequiredIcon={isEdit}
        />
        <CommonFormItem
          isView={!isEdit}
          name="username"
          label={t("profilePage.label.userName")}
          placeholder={t("profilePage.placeholder.userName") as string}
          disabled={true}
        />
        <CommonFormItem
          isView={!isEdit}
          name="sourceStr"
          label={t("profilePage.label.source")}
          placeholder={t("profilePage.placeholder.source") as string}
          disabled={true}
        />
        <CommonFormItem
          isView={!isEdit}
          name="role"
          label={t("profilePage.label.roles")}
          placeholder={t("profilePage.placeholder.roles") as string}
          disabled={true}
        />
        <CommonFormItem
          isView={!isEdit}
          name="email"
          label={t("profilePage.label.email")}
          placeholder={t("profilePage.placeholder.email") as string}
          showRequiredIcon={isEdit}
          rules={[
            {
              whitespace: true,
              required: true,
              message: `${t("validate.input")} ${t("profilePage.label.email")}.`,
            },
            {
              pattern: REGEX_EMAIL,
              message: t("validate.emailFormat") as string,
            },
          ]}
        />
        <CommonFormItem
          isView={!isEdit}
          name="phone"
          label={t("profilePage.label.phoneNumber")}
          placeholder={t("profilePage.placeholder.phoneNumber") as string}
          showRequiredIcon={isEdit}
          rules={[
            {
              whitespace: true,
              required: true,
              message: `${t("validate.input")} ${t("profilePage.label.phoneNumber")}.`,
            },
            {
              pattern: REGEX_PHONE_NUMBER,
              message: t("validate.phoneNumberFormat") as string,
            },
          ]}
        />
        <CommonFormItem
          isView={!isEdit}
          name="modifiedDate"
          label={t("profilePage.label.modifiedDate")}
          disabled={true}
          placeholder={t("profilePage.placeholder.modifiedDate") as string}
        />
        {isEdit && (
          <Space className="form-btn-container">
            <CommonButton size={"small"} onClick={onClear}>
              {t("common.button.cancel")}
            </CommonButton>
            <CommonButton btnType="primary" size={"small"} htmlType="submit">
              {t("common.button.save")}
            </CommonButton>
          </Space>
        )}
      </CommonForm>
    </div>
  );
}

export default forwardRef(AccountProfile);
