// API danh mục vị trí
import APIService from "../utils/apiServices";
import { ICommonSearchParams } from "../utils/types";
import { ILocationAddData, ILocationDetailData } from "../utils/types/locationCategory";

class LocationCategoryServices extends APIService {
  // Danh sách vị trí
  async getPosition(params: ICommonSearchParams) {
    let apiParams = {
      page: params.page || 0,
      size: params.size || 10,
      sortType: params.sortType || "DESC",
      sortBy: params.sortBy || "modifiedDate",
      search: params.search || JSON.stringify({}),
    };
    return await this.request(
      "GET",
      `/api/v1/system/category/position/getPage`,
      {},
      { params: apiParams },
    );
  }
  // Danh sách lịch sử ra vào vị trí
  async getHistoryPosition(params: ICommonSearchParams) {
    let apiParams = {
      page: params.page || 0,
      size: params.size || 10,
      sortType: params.sortType || "DESC",
      sortBy: params.sortBy || "modifiedDate",
      search: params.search || JSON.stringify({}),
    };
    return await this.request("GET", `/api/v1/position-history/getPage`, {}, { params: apiParams });
  }

  //chi tiết
  async detailPosition(id: number) {
    return await this.request("GET", `/api/v1/system/category/position/${id}`);
  }

  // Thêm mới
  async createPosition(data: ILocationAddData) {
    return await this.request("POST", `/api/v1/system/category/position`, data);
  }

  // Chỉnh sửa
  async updatePosition(id: number, data: ILocationDetailData) {
    return await this.request("PUT", `/api/v1/system/category/position/${id}`, data);
  }

  // Xóa
  async deletePosition(data: number[]) {
    return await this.request("DELETE", `/api/v1/system/category/position`, data);
  }

  // Khóa
  async lockPosition(data: number[]) {
    return await this.request("POST", `/api/v1/system/category/position/lock`, data);
  }

  // Mở khóa
  async unlockPosition(data: number[]) {
    return await this.request("POST", `/api/v1/system/category/position/unlock`, data);
  }

  // xuất excel
  async exportPosition(params: ICommonSearchParams) {
    return await this.request(
      "GET",
      `/api/v1/system/category/position/exportExcel`,
      {},
      {
        responseType: "blob",
        params: params,
      },
    );
  }
  // xuất excel
  async exportHistoryPosition(params: ICommonSearchParams) {
    return await this.request(
      "GET",
      `/api/v1/position-history/exportExcel`,
      {},
      {
        responseType: "blob",
        params: params,
      },
    );
  }
}
const service = new LocationCategoryServices();
export default service;
