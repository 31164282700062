//Quản lý  nhóm quyền
import APIService from "../utils/apiServices";
import { ICommonSearchParams } from "../utils/types";
import { IGroupAddData, IGroupDetailData } from "../utils/types/groupManagement";

class GroupManagementServices extends APIService {
  // danh sách
  async getPermission(params: ICommonSearchParams) {
    let apiParams = {
      page: params.page || 0,
      size: params.size || 10,
      sortType: params.sortType || "DESC",
      sortBy: params.sortBy || "modifiedDate",
      search: params.search || JSON.stringify({}),
    };
    return await this.request("GET", `/api/v1/system/group/getPage`, {}, { params: apiParams });
  }

  // chi tiết
  async getPermissionById(id: number) {
    return await this.request("GET", `/api/v1/system/group/${id}`);
  }

  // thêm mới
  async createPermission(data: IGroupAddData) {
    return await this.request("POST", `/api/v1/system/group`, data);
  }

  // chỉnh sửa
  async updatePermission(id: number, data: IGroupAddData | IGroupDetailData) {
    return await this.request("PUT", `/api/v1/system/group/${id}`, data);
  }

  // xóa nhiều
  async deletePermission(data: number[]) {
    return await this.request("DELETE", `/api/v1/system/group/delete`, data);
  }

  // khóa
  async lockPermission(data: number[]) {
    return await this.request("POST", `/api/v1/system/group/lock`, data);
  }

  // mở khóa
  async unlockPermission(data: number[]) {
    return await this.request("POST", `/api/v1/system/group/unlock`, data);
  }

  // lấy tất cả quyền
  async getFullAuthority() {
    return await this.request("GET", `/api/v1/system/group/getFullAuthority`);
  }

  // Xuất excel
  async exportGroup(params: ICommonSearchParams) {
    return await this.request(
      "GET",
      `/api/v1/system/group/exportExcel`,
      {},
      {
        responseType: "blob",
        params: params,
      },
    );
  }
}
const service = new GroupManagementServices();
export default service;
