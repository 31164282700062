import ViIcon from "../../resources/images/vietnamese.svg";
import { ICommonSelectDataType, ILANGUAGE_OPTIONS_TYPE } from "../types";
// import UkIcon from "../../resources/images/english.svg";

export const ROLE_LIST = {
  QLHT: "QLHT", //Quản lý hành trình
  QLHT_VIEW: "QLHT_VIEW", //Xem danh sách đăng ký hành trình
  QLHT_CREATE: "QLHT_CREATE", //Đăng ký thông tin xe
  QLHT_VIEW_DETAIL_CAR: "QLHT_VIEW_DETAIL_CAR", //Thông tin xe vào
  QLHT_EDIT: "QLHT_EDIT", //Sửa thông tin xe vào SEV
  QLHT_EDIT_POSITION: "QLHT_EDIT_POSITION", //Sửa thông tin vị trí giao/ nhận hàng
  QLHT_CHECKIN_CHECKOUT: "QLHT_CHECKIN_CHECKOUT", //Checkin/ checkout hành trình
  QLHT_ADD_IMEI: "QLHT_ADD_IMEI", //Gán IMEI thủ công
  QLHT_REPORT_LOCK: "QLHT_REPORT_LOCK", //Báo cáo khóa hỏng
  QLGT_EXPORT_HISTORY_ACTION: "QLGT_EXPORT_HISTORY_ACTION", //Xuất excel danh sách lịch sử thao tác và vi phạm
  QLHT_EXPORT: "QLHT_EXPORT", //Xuất excel danh sách
  QLHT_DELETE: "QLHT_DELETE", //Xóa hành trình
  QLHT_CANCEL: "QLHT_CANCEL", //Hủy hành trình
  QLHT_VIEW_DETAIL: "QLHT_VIEW_DETAIL", //Xem chi tiết hành trình
  QLHT_VIEW_DETAIL_INFO_DELI: "QLHT_VIEW_DETAIL_INFO_DELI", //Thông tin giao nhận hàng
  QLHT_VIEW_DETAIL_ACTION_AND_VIO: "QLHT_VIEW_DETAIL_ACTION_AND_VIO", //Lịch sử thao tác và vi phạm

  MANAGER_LOCK: "MANAGER_LOCK", //Quản lý khóa
  MANAGER_LOCK_VIEW_LIST: "MANAGER_LOCK_VIEW_LIST", //Xem danh sách khóa
  MANAGER_LOCK_DETAIL_INFO: "MANAGER_LOCK_DETAIL_INFO", //Thông tin khóa
  MANAGER_LOCK_EXPORT_HISTORY: "MANAGER_LOCK_EXPORT_HISTORY", //Xuất excel lịch sử sử dụng khóa
  MANAGER_LOCK_EXPORT: "MANAGER_LOCK_EXPORT", //Xuất excel danh sách khóa
  MANAGER_LOCK_TO_USER: "MANAGER_LOCK_TO_USER", //Phân quyền/hủy phân quyền sử dụng khóa
  MANAGER_LOCK_DELETE: "MANAGER_LOCK_DELETE", //Xóa khóa
  MANAGER_LOCK_CREATE: "MANAGER_LOCK_CREATE", //Thêm mới khóa
  MANAGER_LOCK_TO_USER_VENDOR: "MANAGER_LOCK_TO_USER_VENDOR", //Phân quyền/ hủy phân quyền user thuộc vendor sử dụng khóa
  MANAGER_LOCK_EXPORT_USER: "MANAGER_LOCK_EXPORT_USER", //Xuất excel tài khoản được sử dụng khóa
  MANAGER_LOCK_DETAIL: "MANAGER_LOCK_DETAIL", //Xem chi tiết khóa
  MANAGER_LOCK_DETAIL_HISTORY_USE: "MANAGER_LOCK_DETAIL_HISTORY_USE", //Lịch sử sử dụng khóa
  MANAGER_LOCK_DETAIL_ACC_VENDOR_USE: "MANAGER_LOCK_DETAIL_ACC_VENDOR_USE", //Tài khoản thuộc vendor được sử dụng

  MANAGER_VENDOR: "MANAGER_VENDOR", //Quản lý vendor
  MANAGER_VENDOR_ADD: "MANAGER_VENDOR_ADD", //Thêm mới vendor
  MANAGER_VENDOR_EDIT: "MANAGER_VENDOR_EDIT", //Sửa thông tin vendor
  MANAGER_VENDOR_DELETE: "MANAGER_VENDOR_DELETE", //Xóa vendor
  MANAGER_VENDOR_VIEW_LIST: "MANAGER_VENDOR_VIEW_LIST", //Xem danh sách
  MANAGER_VENDOR_EXCEL: "MANAGER_VENDOR_EXCEL", //Xuất excel danh sách vendor
  MANAGER_VENDOR_VIEW_DETAIL_INFO: "MANAGER_VENDOR_VIEW_DETAIL_INFO", //Thông tin vendor
  MANAGER_VENDOR_EXCEL_LOCK: "MANAGER_VENDOR_EXCEL_LOCK", //Xuất excel danh sách khóa thuộc vendor
  MANAGER_VENDOR_EXCEL_USER: "MANAGER_VENDOR_EXCEL_USER", //Xuất excel danh sách user thuộc vendor
  MANAGER_VENDOR_VIEW_DETAIL: "MANAGER_VENDOR_VIEW_DETAIL", //Xem chi tiết vendor
  MANAGER_VENDOR_VIEW_DETAIL_INFO_STAFF: "MANAGER_VENDOR_VIEW_DETAIL_INFO_STAFF", //Thông tin nhân viên
  MANAGER_VENDOR_VIEW_DETAIL_LOCK_MANAGER: "MANAGER_VENDOR_VIEW_DETAIL_LOCK_MANAGER", //Danh sách khóa đang quản lý

  MANAGER_ACCOUNT: "MANAGER_ACCOUNT", //Quản lý tài khoản
  MANAGER_ACCOUNT_VIEW_LIST: "MANAGER_ACCOUNT_VIEW_LIST", //Xem danh sách tài khoản
  MANAGER_ACCOUNT_VIEW_DETAIL: "MANAGER_ACCOUNT_VIEW_DETAIL", //Xem chi tiết tài khoản
  MANAGER_ACCOUNT_EXPORT: "MANAGER_ACCOUNT_EXPORT", //Xuất excel danh sách tài khoản
  MANAGER_ACCOUNT_CREATE: "MANAGER_ACCOUNT_CREATE", //Tạo tài khoản
  MANAGER_ACCOUNT_RESET_PASS: "MANAGER_ACCOUNT_RESET_PASS", //đặt lại mật khẩu tài khoản
  MANAGER_ACCOUNT_EDIT: "MANAGER_ACCOUNT_EDIT", //Sửa thông tin tài khoản
  MANAGER_ACCOUNT_DELETE: "MANAGER_ACCOUNT_DELETE", //Xóa tài khoản

  MANAGER_GROUP: "MANAGER_GROUP", //Quản lý nhóm quyền
  MANAGER_GROUP_VIEW_LIST: "MANAGER_GROUP_VIEW_LIST", //Xem danh sách nhóm quyền
  MANAGER_GROUP_VIEW_DETAIL: "MANAGER_GROUP_VIEW_DETAIL", //Xem chi tiết nhóm quyền
  MANAGER_GROUP_CREATE: "MANAGER_GROUP_CREATE", //thêm mới nhóm quyền
  MANAGER_GROUP_EDIT: "MANAGER_GROUP_EDIT", //chỉnh sửa nhóm quyền
  MANAGER_GROUP_EXPORT: "MANAGER_GROUP_EXPORT", //Xuất excel danh sách nhóm quyền
  MANAGER_GROUP_EXPORT_USER: "MANAGER_GROUP_EXPORT_USER", //Xuất excel danh sách tài khoản thuộc nhóm quyền
  MANAGER_GROUP_DELETE: "MANAGER_GROUP_DELETE", //Xóa nhóm quyền
  MANAGER_GROUP_VIEW_DETAIL_INFO: "MANAGER_GROUP_VIEW_DETAIL_INFO", //Thông tin nhóm quyền
  MANAGER_GROUP_VIEW_DETAIL_ACCOUNT: "MANAGER_GROUP_VIEW_DETAIL_ACCOUNT", //Danh sách tài khoản thuộc nhóm quyền

  MANAGER_POSITION: "MANAGER_POSITION", //Danh mục vị trí
  MANAGER_POSITION_VIEW_LIST: "MANAGER_POSITION_VIEW_LIST", //Xem danh sách vị trí
  MANAGER_POSITION_CREATE: "MANAGER_POSITION_CREATE", //Thêm mới vị trí
  MANAGER_POSITION_VIEW_DETAIL: "MANAGER_POSITION_VIEW_DETAIL", //Xem chi tiết thông tin vị trí
  MANAGER_POSITION_EDIT: "MANAGER_POSITION_EDIT", //Sửa thông tin vị trí
  MANAGER_POSITION_VIEW_HISTORY: "MANAGER_POSITION_VIEW_HISTORY", //Xem lịch sử ra vào vị trí
  MANAGER_POSITION_EXPORT: "MANAGER_POSITION_EXPORT", //xuất excel danh sách vị trí
  MANAGER_POSITION_EXPORT_HISTORY: "MANAGER_POSITION_EXPORT_HISTORY", //Xuất excel lịch sử ra vào
  MANAGER_POSITION_DELETE: "MANAGER_POSITION_DELETE", //Xóa vị trí

  MANAGER_DRIVER: "MANAGER_DRIVER",
  MANAGER_DRIVER_VIEW_LIST: "MANAGER_DRIVER_VIEW_LIST",
  MANAGER_DRIVER_VIEW_DETAIL: "MANAGER_DRIVER_VIEW_DETAIL",
  MANAGER_DRIVER_CREATE: "MANAGER_DRIVER_CREATE",
  MANAGER_DRIVER_EDIT: "MANAGER_DRIVER_EDIT",
  MANAGER_DRIVER_DELETE: "MANAGER_DRIVER_DELETE",
  MANAGER_DRIVER_EXPORT: "MANAGER_DRIVER_EXPORT",

  MANAGER_CAR: "MANAGER_CAR", //Danh mục loại xe
  MANAGER_CAR_VIEW_LIST: "MANAGER_CAR_VIEW_LIST", //Xem danh sách loại xe
  MANAGER_CAR_VIEW_DETAIL: "MANAGER_CAR_VIEW_DETAIL", //Xem chi tiết loại xe
  MANAGER_CAR_CREATE: "MANAGER_CAR_CREATE", //thêm mới loại xe
  MANAGER_CAR_EDIT: "MANAGER_CAR_EDIT", //sửa loại xe
  MANAGER_CAR_DELETE: "MANAGER_CAR_DELETE", //Xóa loại xe
  MANAGER_CAR_EXPORT: "MANAGER_CAR_EXPORT", //Xuất excel danh sách

  SECURITY: "SECURITY", //Bảo vệ kho
  SECURITY_GATEIN: "SECURITY_GATEIN", //Bảo vệ cổng vào
  SECURITY_GATEOUT: "SECURITY_GATEOUT", //Bảo vệ cổng ra
  SECURITY_VENDOR: "SECURITY_VENDOR", //Bảo vệ vendor
};

export const LANGUAGE_LIST: ILANGUAGE_OPTIONS_TYPE[] = [
  {
    value: "vi",
    label: "Tiếng Việt",
    icon: ViIcon,
  },
  // {
  //   value: "en",
  //   label: "English",
  //   icon: UkIcon,
  // },
];
export const DATE_FORMAT = "DD/MM/YYYY";
export const TIME_FORMAT = "HH:mm";
export const DATE_TIME_FORMAT = "DD/MM/YYYY, HH:mm";
export const DATE_TIME_FORMAT_SECOND = "DD/MM/YYYY, HH:mm:ss";
export const DOB_FORMAT = "DD MMMM, YYYY";
export const PAGE_SIZE_LIST = [5, 10, 20, 50, 100];
export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_PAGE_NUMBER = 1;
// export const SAMSUNG_LOCATION = [21.201509, 105.991802]; //Tọa độ samsung
// Đổi toạ độ SEVT
export const SAMSUNG_LOCATION = [21.424814278955136, 105.89560505475062]; //Tọa độ samsung
export const HANOI_LOCATION = [21.02840045, 105.77753169273271]; // tọa độ Hà nội
export const HOANGSA_LOCATION = [16.4671, 112.3392]; // tọa độ Hoàng sa
export const TRUONGSA_LOCATION = [9.7833, 115.15]; // tọa độ Trường sa

// export const REGEX_PASSWORD = /(?=^.{8,}$)(?=.*[0-9])(?=.*[A-Za-z]).*/g
export const REGEX_USERNAME = /^([a-zA-Z0-9_]+)$/g;
export const REGEX_CODE = /^([a-zA-Z0-9]+)$/g;
export const REGEX_PHONE_NUMBER = /^[0-9]([0-9]+){9}$/g;
export const REGEX_ONLY_NUMBER = /^[0-9]+$/g;
export const REGEX_NUMBER_GREATER_ZERO = /^[1-9][0-9]*$/;
export const REGEX_NO_SPACE = /^\S*$/;
export const REGEX_COORDINATES =
  /^[-+]?([0-9]+([.][0-9]*)?|[.][0-9]+),\s[-+]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/;
// export const REGEX_EMAIL = /^[a-zA-Z0-9_.+-]+@gmail\.com$/;
export const REGEX_EMAIL = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const AT_LEAST_8_CHARS = /^.{8,}$/; // Ít nhất 8 kí tự
// export const AT_LEAST_1_UPPERCASE = /^(?=.*[A-Z]).*$/;    // Ít nhất 1 kí tự viết hoa
export const AT_LEAST_1_NUMBER = /^(?=.*\d).*$/; // Ít nhất 1 số
export const REGEX_PASSWORD = /^(?=.*\d).{8,}$/;

export const VI_MOMENT_CONFIG = {
  week: {
    dow: 1, /// Date offset
  },
  months: [
    "Tháng 1",
    "Tháng 2",
    "Tháng 3",
    "Tháng 4",
    "Tháng 5",
    "Tháng 6",
    "Tháng 7",
    "Tháng 8",
    "Tháng 9",
    "Tháng 10",
    "Tháng 11",
    "Tháng 12",
  ],
  monthsShort: [
    "Thg 1",
    "Thg 2",
    "Thg 3",
    "Thg 4",
    "Thg 5",
    "Thg 6",
    "Thg 7",
    "Thg 8",
    "Thg 9",
    "Thg 10",
    "Thg 11",
    "Thg 12",
  ],
  weekdays: ["Chủ nhật", "Thứ 2", "Thứ 3", "Thứ 4", "Thứ 5", "Thứ 6", "Thứ 7"],
  weekdaysShort: ["CN", "Th 2", "Th 3", "Th 4", "Th 5", "Th 6", "Th 7"],
  weekdaysMin: ["CN", "T2", "T3", "T4", "T5", "T6", "T7"],
  relativeTime: {
    future: "trong %s",
    past: "%s trước",
    s: "vài giây trước",
    ss: "%d giây",
    m: "1 phút",
    mm: "%d phút",
    h: "1 giờ",
    hh: "%d giờ",
    d: "1 ngày",
    dd: "%d ngày",
    w: "1 tuần",
    ww: "%d tuần",
    M: "1 tháng",
    MM: "%d tháng",
    y: "1 năm",
    yy: "%d năm",
  },
};

export const SEV_CODE: string = "SEV";

export const COMMON_PAGE_STATUS: ICommonSelectDataType[] = [
  {
    value: 0,
    label: "common.options.active",
    type: "success",
  },
  {
    value: 1,
    label: "common.options.inactive",
    type: "default",
  },
];
