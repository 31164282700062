import { Form, FormProps } from "antd";

import CommonSpin from "./Spin";

interface CommonFormProps extends FormProps {
  children: any;
  isLoading?: boolean;
  formRef?: any;
}

const CommonForm = (props: CommonFormProps) => {
  const formProps = { ...props };
  delete formProps.isLoading;
  delete formProps.formRef;

  return (
    <CommonSpin isLoading={props?.isLoading}>
      <Form
        ref={props?.formRef}
        {...formProps}
        className={`avic-form ${formProps?.className || ""}`}
      >
        {formProps?.children}
      </Form>
    </CommonSpin>
  );
};

export default CommonForm;
