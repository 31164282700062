import { Col, Form, Row, TableColumnsType, TablePaginationConfig, notification } from "antd";
import { FilterValue, TableCurrentDataSource } from "antd/es/table/interface";
import { saveAs } from "file-saver";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import CommonButton from "../../components/Common/Button";
import CommonForm from "../../components/Common/Form";
import CommonFormItem from "../../components/Common/FormItem";
import CommonInput from "../../components/Common/Input";
import CommonTable from "../../components/Common/Table";
import CommonTag from "../../components/Common/Tag";
import MapComponent from "../../components/Map";
import { ReactComponent as SearchIcon } from "../../resources/images/search-1.svg";
import locationServices from "../../services/locationCategory.service";
import {
  COMMON_PAGE_STATUS,
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
  ROLE_LIST,
} from "../../utils/constans";
import { LOCATION_TYPES } from "../../utils/constans/locationCategory";
import { SIDEBAR_ITEM_HREF } from "../../utils/constans/sidebar";
import { useQuery } from "../../utils/customHooks";
import { ICommonResponsePaging, ICommonSelectDataType, IUseQueryResponse } from "../../utils/types";
import { ILocationDetailData } from "../../utils/types/locationCategory";
import { buildQueryString, isHavePermission } from "../../utils/utilFunctions";
import LocationControl from "./LocationControl";
// Thêm chọn khu vực
import journeyServices from "../../services/journeyManagement.service";
import { IArea } from "../../utils/types/journeyManagement";
import LocalStorage from "../../utils/localStorage";

function LocationCategory() {
  const componentPath = SIDEBAR_ITEM_HREF.location_category;
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const queryObj: IUseQueryResponse = useQuery();
  const { params = {}, search } = queryObj;
  const {
    page: pageQuery,
    pageSize: pageSizeQuery,
    search: searchQuery,
    sortBy: sortByQuery,
    sortType: sortTypeQuery,
  } = params;
  const page = pageQuery ? parseFloat(pageQuery) : DEFAULT_PAGE_NUMBER;
  const pageSize = pageSizeQuery ? parseFloat(pageSizeQuery) : DEFAULT_PAGE_SIZE;
  const searchQueryData = searchQuery ? JSON.parse(searchQuery) : {};
  const [form] = Form.useForm();
  const [data, setData] = useState<ICommonResponsePaging<ILocationDetailData>>();
  const [isLoadingExcel, setIsLoadingExcel] = useState<boolean>(false);
  const [currentLocation, setCurrentLocation] = useState<ILocationDetailData>();
  const navigate = useNavigate();

  const { profile } = useSelector((state: any) => state?.profileReducer);
  const isCanViewHistory = isHavePermission(ROLE_LIST.MANAGER_POSITION_VIEW_HISTORY, profile);
  const isCanExport = isHavePermission(ROLE_LIST.MANAGER_POSITION_EXPORT, profile);
  const isCanViewDetail = isHavePermission(ROLE_LIST.MANAGER_POSITION_VIEW_DETAIL, profile);
  const isCanEdit = isHavePermission(ROLE_LIST.MANAGER_POSITION_EDIT, profile);
  // Thêm chọn khu vực
  const [listArea, setListArea] = useState<IArea[]>([]);

  const getDataArea = async () => {
    const res = await journeyServices.getAllArea();
    if (res.status === 200) {
      setListArea([...res.data.data]);
    } else {
      setListArea([]);
    }
  };

  useEffect(() => {
    getDataArea();
  }, []);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const getData = async (reload?: boolean) => {
    setIsLoading(true);
    setCurrentLocation(undefined);
    const paramsSearch = {
      page: reload ? 0 : page - 1,
      size: pageSize,
      sortBy: sortByQuery,
      sortType: sortTypeQuery,
      search: searchQuery,
    };
    const resp = await locationServices.getPosition(paramsSearch);
    const data = resp?.data;
    if (resp.code == 408) {
      notification.error({
        message: data?.message || t("commonError.network"),
      });
      return;
    }
    if (resp?.status === 200) {
      setData(data?.data);
    } else if (resp?.status === 401) {
      // Tạm thời xử lý sự kiện nếu gặp lỗi 401
      // Sẽ được code lại trong service nếu gặp 401 sẽ văng ra login
      window.location.href = SIDEBAR_ITEM_HREF.home;
      LocalStorage.getInstance().save("accessToken", null);
      notification.error({
        message: t("commonError.sessionExpired"),
      });
    } else {
      setData(undefined);
      notification.error({
        message: data?.message || t("commonError.oopsSystem"),
      });
    }
    setIsLoading(false);
  };

  const onPageChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: any,
    extra: TableCurrentDataSource<ILocationDetailData>,
  ) => {
    setCurrentLocation(undefined);
    let queryString = buildQueryString({
      ...params,
      page: pageSize === pagination?.pageSize ? pagination?.current : DEFAULT_PAGE_NUMBER,
      pageSize: pagination?.pageSize,
      sortBy: sorter?.order ? sorter?.field : "",
      sortType: sorter?.order ? (sorter?.order === "ascend" ? "asc" : "desc") : "",
    });
    navigate(`${componentPath}${queryString || ""}`);
  };

  const columns: TableColumnsType<ILocationDetailData> = [
    {
      title: t("locationCategoryPage.columns.STT"),
      dataIndex: "index",
      key: "index",
      width: "5%",
      align: "center",
      render: (value: any, record: ILocationDetailData, index: number) =>
        (page - 1) * pageSize + index + 1,
    },
    {
      title: t("locationCategoryPage.columns.locationCode"),
      dataIndex: "id",
      key: "id",
      sorter: true,
      render: (value: any, record: ILocationDetailData, index: number) => {
        return isCanViewDetail || isCanEdit ? (
          <div
            className="link"
            onClick={() => {
              setCurrentLocation(record);
            }}
          >
            {value || "--"}
          </div>
        ) : (
          value || "--"
        );
      },
    },
    {
      title: t("locationCategoryPage.columns.name"),
      dataIndex: "name",
      key: "name",
      sorter: true,
      render: (value: any, record: ILocationDetailData, index: number) => {
        return value || "--";
      },
    },
    {
      title: t("locationCategoryPage.columns.area"),
      dataIndex: "factoryAreaId",
      key: "factoryAreaId",
      sorter: true,
      render: (value: any, record: ILocationDetailData, index: number) => {
        return listArea.find((item) => item.id === value)?.name || "--";
      },
    },
    {
      title: t("locationCategoryPage.columns.locationType"),
      dataIndex: "type",
      key: "type",
      sorter: true,
      render: (value: any, record: ILocationDetailData, index: number) => {
        const curType = LOCATION_TYPES.find((x: ICommonSelectDataType) => x.value === value);
        return <>{t(curType?.label as string) || "--"}</>;
      },
    },
    {
      title: t("locationCategoryPage.columns.status"),
      dataIndex: "status",
      key: "status",
      sorter: true,
      render: (value: any, record: ILocationDetailData, index: number) => {
        const curStatus = COMMON_PAGE_STATUS.find((x: ICommonSelectDataType) => x.value === value);
        return curStatus ? (
          <CommonTag tagType={curStatus?.type}>{t(curStatus?.label)}</CommonTag>
        ) : (
          "--"
        );
      },
    },
  ];

  const onValuesChange = _.debounce((values: any) => {
    onSearch(values);
  }, 200);

  const onSearch = (data: any = {}) => {
    const dataSearch = { ...data };
    setCurrentLocation(undefined);
    let queryString = buildQueryString({
      page: DEFAULT_PAGE_NUMBER,
      search: JSON.stringify(dataSearch),
    });
    // detect if query not change => call api
    if (queryString !== search) {
      navigate(`${componentPath}${queryString}`);
    } else {
      getData();
    }
  };

  // xuất excel
  const handleExport = async () => {
    if (!isLoadingExcel) {
      setIsLoadingExcel(true);
      const paramsSearch = {
        page: 0,
        size: 10000,
        sortBy: sortByQuery,
        sortType: sortTypeQuery,
        search: searchQuery,
      };
      const resp = await locationServices.exportPosition(paramsSearch);
      const data = resp?.data;
      if (resp?.status === 200) {
        const fileName = `LocationCategory_Data_Export_${moment().format(
          "YYYYMMDD",
        )}_${moment().unix()}.xlsx`;
        saveAs(data, fileName);
      } else {
        notification.error({
          message: data?.message || t("commonError.oopsSystem"),
        });
      }
      setIsLoadingExcel(false);
    }
  };

  return (
    <>
      <div className="location-wrapper content-box">
        <div style={{width: "50%"}}>
          <MapComponent>
            <LocationControl
              getData={() => {
                setCurrentLocation(undefined);
                getData(true);
              }}
              listLocation={data?.content || []}
              currentLocation={currentLocation}
            />
          </MapComponent>
        </div>

        <div className="box-wrapper location-list">
          <div className="avic-search-box" style={{ width: "100%", height: "100%" }}>
            <div className="advance-search-box" style={{ width: "100%", height: "100%" }}>
              <CommonForm
                form={form}
                onValuesChange={onValuesChange}
                layout="vertical"
                initialValues={{
                  keyword: searchQueryData?.keyword,
                  status: searchQueryData?.status,
                }}
              >
                <Row gutter={20}>
                  <Col span={12}>
                    <CommonFormItem name="keyword" label={t("locationCategoryPage.label.keyword")}>
                      <CommonInput
                        placeholder={t("locationCategoryPage.placeholder.keyword") as string}
                        prefix={<SearchIcon className="prefix-icon" />}
                      />
                    </CommonFormItem>
                  </Col>
                  <Col span={12}>
                    <CommonFormItem
                      name="status"
                      label={t("locationCategoryPage.label.status")}
                      placeholder={t("locationCategoryPage.placeholder.status") as string}
                      options={COMMON_PAGE_STATUS.map((x) => ({ ...x, label: t(x.label) }))}
                      type="select"
                    />
                  </Col>
                </Row>
              </CommonForm>
              <div className=" mgt-10" style={{ display: "flex", justifyContent: "flex-end" }}>
                {isCanViewHistory && (
                  <Link to={`${componentPath}/history`}>
                    <CommonButton btnType="primary" size={"small"} className=" mgr-10">
                      {t("common.button.locationHistory")}
                    </CommonButton>
                  </Link>
                )}
                {isCanExport && (
                  <CommonButton
                    loading={isLoadingExcel}
                    btnType="success"
                    onClick={handleExport}
                    size={"small"}
                  >
                    {t("common.button.exportExcel")}
                  </CommonButton>
                )}
              </div>
              <br />
              <CommonTable
                isLoading={isLoading}
                rowKey={"id"}
                dataSource={data?.content || []}
                columns={columns}
                data={data}
                onChange={onPageChange}
                defaultSorter={{
                  order: sortTypeQuery,
                  field: sortByQuery,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LocationCategory;
