// Quản lý tài khoản
import APIService from "../utils/apiServices";
import { ICommonSearchParams } from "../utils/types";
import {
  ChangePasswordData,
  IAccountAddData,
  IAccountDetailData,
  LoginData,
} from "../utils/types/accountManagement";

class AccountManagementServices extends APIService {
  //đăng nhập
  async login(data: LoginData) {
    return await this.request("POST", `/public/v1/auth/jwt-login`, data);
  }

  // lấy thông tin tài khoản đăng nhập
  async getProfile() {
    return await this.request("GET", `/api/v1/system/user/me`, {});
  }

  //đổi mật khẩu
  async changePassword(data: ChangePasswordData) {
    return await this.request("POST", `/api/v1/system/user/changePassword`, data);
  }

  //reset mật khẩu bới admin
  async changePasswordByAdmin(data: ChangePasswordData) {
    return await this.request("POST", `/api/v1/system/user/changePasswordByAdmin`, data);
  }

  // lấy danh sách tài khoản
  async getAccount(params: ICommonSearchParams) {
    let apiParams = {
      page: params.page || 0,
      size: params.size || 10,
      sortType: params.sortType || "DESC",
      sortBy: params.sortBy || "modifiedDate",
      search: params.search || JSON.stringify({}),
    };
    return await this.request("GET", `/api/v1/system/user/getPage`, {}, { params: apiParams });
  }

  // chi tiết
  async getAccountById(id: number) {
    return await this.request("GET", `/api/v1/system/user/${id}`);
  }

  // thêm mới
  async createAccount(data: IAccountAddData) {
    return await this.request("POST", `/api/v1/system/user`, data);
  }

  // Chỉnh sửa
  async updateAccount(id: number, data: IAccountDetailData) {
    return await this.request("PUT", `/api/v1/system/user/${id}`, data);
  }

  // Xóa
  async deleteAccount(data: number[]) {
    return await this.request("DELETE", `/api/v1/system/user/delete`, data);
  }

  // Khóa
  async lockAccount(data: number[]) {
    return await this.request("POST", `/api/v1/system/user/lock`, data);
  }

  // Mở khóa
  async unlockAccount(data: number[]) {
    return await this.request("POST", `/api/v1/system/user/unlock`, data);
  }

  // Xuất excel
  async exportAccount(params: ICommonSearchParams) {
    return await this.request(
      "GET",
      `/api/v1/system/user/exportExcel`,
      {},
      {
        responseType: "blob",
        params: params,
      },
    );
  }
}
const service = new AccountManagementServices();
export default service;
