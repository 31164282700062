// table drop row
import { MenuOutlined } from "@ant-design/icons";
import type { DragEndEvent } from "@dnd-kit/core";
import { DndContext } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import type { ColumnsType } from "antd/es/table";
// table drop row

import { Col, Form, notification, Row, Space } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import CommonButton from "../../components/Common/Button";
import CommonConfirmModal from "../../components/Common/ConfirmModal";
import CommonForm from "../../components/Common/Form";
import CommonFormItem from "../../components/Common/FormItem";
import CommonSelect from "../../components/Common/Select";
import CommonTable from "../../components/Common/Table";
import { ReactComponent as DeleteIcon } from "../../resources/images/delete-red.svg";
import { ReactComponent as AddIcon } from "../../resources/images/plus-blue.svg";
import journeyServices from "../../services/journeyManagement.service";
import positionServices from "../../services/locationCategory.service";
import vehicleTypeServices from "../../services/vehicleTypeCategory.service";
import driverCategory from "../../services/driverCategory.service";
import {
  REGEX_CODE,
  REGEX_NUMBER_GREATER_ZERO,
  REGEX_ONLY_NUMBER,
  SEV_CODE,
} from "../../utils/constans";
import { OPTION_DELIVERY } from "../../utils/constans/journeyManagement";
import { LOCATION_VALUES } from "../../utils/constans/locationCategory";
import { SIDEBAR_ITEM_HREF } from "../../utils/constans/sidebar";
import { IArea, ICompanyDeliverySitesAddData } from "../../utils/types/journeyManagement";
import { ILocationDetailData } from "../../utils/types/locationCategory";
import { IVehicleTypeDetailData } from "../../utils/types/vehicleType";

// table drop row
// Dragable
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { IDriverDetailData } from "../../utils/types/driverCategory";

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  "data-row-key": string;
}

interface Gate {
  gateIn: number | null;
  gateOut: number | null;
}

interface FactoryArea {
  id: number
  rFAId: number
  name: string
}

function JourneyAddForm() {
  const componentPath = SIDEBAR_ITEM_HREF.journey_management;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const naviagte = useNavigate();
  const [dataSource, setDataSource] = useState<ICompanyDeliverySitesAddData[]>([]);
  const [itemDeleteId, setItemDeleteId] = useState<number | undefined>(undefined);
  const [visible, setVisible] = useState<boolean>(false);
  const [positionSelectData, setPositionSelectData] = useState<ILocationDetailData[]>([]);
  const [vehicleTypeSelectData, setVehicleTypeSelectData] = useState<IVehicleTypeDetailData[]>([]);
  const [driverSelectData, setDriverSelectData] = useState<IDriverDetailData[]>([]);
  const [checkDuplicate, setCheckDuplicate] = useState<any[]>([]);
  const [loadingConfirm, setLoadingConfirm] = useState<boolean>(false);
  const [isLoadingCreate, setIsLoadingCreate] = useState<boolean>(false);
  const { profile } = useSelector((state: any) => state?.profileReducer);
  const isSEV = profile?.source === SEV_CODE;
  // Thêm danh sách areaOrder
  // const [listAreaOrder, setListAreaOrder] = useState<string[]>(['1']);
  const [txtAreaOrder, setTxtAreaOrder] = useState<string>('Chưa có');
  const [areaOrderTab, setAreaOrderTab] = useState<Number>(0);
  // const [gates, setGates] = useState<{ [key: string]: Gate }>({ '1': { gateIn: null, gateOut: null } })
  // const [listCDS, setListCDS] = useState<{ [key: string]: [] }>({ '1': [] })
  // Danh sách khu vực
  const [listArea, setListArea] = useState<IArea[]>([])
  // Thêm dữ liệu FactoryArea
  const [listFactoryArea, setListFactoryArea] = useState<FactoryArea[]>([{
    id: 0,
    rFAId: 0,
    name: ""
  }])
  const [listCDSFactory, setListCDSFactory] = useState<{ [key: string]: ICompanyDeliverySitesAddData[] }>({})
  const [gatesFactory, setGatesFactory] = useState<{ [key: string]: Gate }>({})
  const [currentArea, setCurrentArea] = useState<FactoryArea>({} as FactoryArea)
  const [isCantChange, setIsCantChange] = useState<boolean>(true)
  // Thêm trường hợp tạo với xe nội bộ
  const [isInternalCar, setIsInternalCar] = useState<boolean>(false)

  const getDataArea = async () => {
    const res = await journeyServices.getAllArea()
    if (res.status === 200) {
      setListArea([...res.data.data])
    } else {
      setListArea([])
    }
  }

  useEffect(() => {
    getDataArea()
    form.setFieldsValue({
      companyName: "SEVT",
      totalDoor: "1"
    })
    setCurrentArea(listFactoryArea[0])
  }, [])

  useEffect(() => {
    if (currentArea.id !== undefined) {
      if (currentArea.id === 0 || gatesFactory[currentArea.name] === undefined) {
        form.setFieldsValue({
          gateIn: null,
          gateOut: null,
        })
        setDataSource([])
        return
      } else {
        form.setFieldsValue({
          gateIn: gatesFactory[currentArea.name].gateIn,
          gateOut: gatesFactory[currentArea.name].gateOut,
        })
        if (listCDSFactory[currentArea.name] === undefined) {
          listCDSFactory[currentArea.name] = []
        }
        setDataSource(listCDSFactory[currentArea.name])
      }
    } else {
      setDataSource([])
    }
  }, [currentArea])

  useEffect(() => {
    if (!isSEV) {
      form.setFieldValue(
        "categoryCar",
        vehicleTypeSelectData?.find((item) => item?.type === 2)?.id,
      );
    }
  }, [form, isSEV, vehicleTypeSelectData]);

  useEffect(() => {
    // listCDS[txtAreaOrder] = dataSource as []
    listCDSFactory[currentArea.name] = dataSource
  }, [dataSource])

  const RowTable = ({ children, ...props }: RowProps) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({ id: props["data-row-key"] });

    const style: React.CSSProperties = {
      ...props.style,
      transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
      transition,
      ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
    };

    return (
      <tr {...props} ref={setNodeRef} style={style} {...attributes}>
        {React.Children.map(children, (child) => {
          if ((child as React.ReactElement).key === "sort") {
            return React.cloneElement(child as React.ReactElement, {
              children: (
                <MenuOutlined
                  ref={setActivatorNodeRef}
                  style={{ touchAction: "none", cursor: "move" }}
                  {...listeners}
                />
              ),
            });
          }
          return child;
        })}
      </tr>
    );
  };

  const getSelectData = useCallback(async () => {
    const paramsSearch = {
      page: 0,
      size: 1000,
      search: JSON.stringify({ status: 0 }),
    };
    if (isSEV) {
      //select vị trí
      const respPosition = await positionServices.getPosition(paramsSearch);
      const dataPosition = respPosition?.data;
      if (respPosition?.status === 200) {
        setPositionSelectData(dataPosition?.data?.content);
      } else {
        setPositionSelectData([]);
      }
    }

    //select Loại xe
    const respVehicleType = await vehicleTypeServices.getListVehicleType(paramsSearch);
    const dataVehicleType = respVehicleType?.data;
    if (respVehicleType?.status === 200) {
      setVehicleTypeSelectData(dataVehicleType?.data?.content);
    } else {
      setVehicleTypeSelectData([]);
    }

    // select tài xế
    const respDriverCategory = await driverCategory.getListDriver(paramsSearch)
    const dataDriverCategory = respDriverCategory?.data
    if (respDriverCategory?.status === 200) {
      setDriverSelectData(dataDriverCategory?.data?.content)
    } else {
      setDriverSelectData([])
    }

  }, [isSEV]);

  useEffect(() => {
    getSelectData();
  }, [getSelectData]);

  // check duplicate vị trí kho
  useEffect(() => {
    if (dataSource.length > 1) {
      const duplicateData = dataSource?.filter((item: any, index: number) => {
        if (index === 0) {
          return item?.name && item?.name === dataSource[1]?.name;
        } else if (index === dataSource.length - 1) {
          return item?.name && item?.name === dataSource[dataSource.length - 2].name;
        } else {
          return (
            item?.name &&
            (item.name === dataSource[index + 1].name || item.name === dataSource[index - 1].name)
          );
        }
      });
      setCheckDuplicate(duplicateData?.map((item: any) => item.key));
    } else {
      setCheckDuplicate([]);
    }
  }, [dataSource, t]);

  // Xoá một dòng vị trí
  const onDeleteRow = () => {
    setLoadingConfirm(true);
    setDataSource((prevData: ICompanyDeliverySitesAddData[]) => {
      const newDataSource = prevData.filter(
        (item: ICompanyDeliverySitesAddData) => item.index !== itemDeleteId,
      );
      return newDataSource;
    });
    setVisible(false);
    setLoadingConfirm(false);
  };

  const checkOut = () => {
    let isNotAllRight = false;
    if (!isInternalCar) {
      listFactoryArea.map((value, index) => {
        if (listCDSFactory[value.name].length === 0 && !isInternalCar) {
          handleClickAreaOrder(value.name, index)
          notification.error({
            message: t("journeyManagementPage.validate.deliveryRequired"),
          });
          setIsLoadingCreate(false);
          return isNotAllRight = true;
        }
        if ((listCDSFactory[value.name].find((item: any) => item.name === "" || item.name === undefined)) && !isInternalCar) {
          handleClickAreaOrder(value.name, index)
          notification.error({
            message: t("journeyManagementPage.validate.deliveryWhiteSpace"),
          });
          setIsLoadingCreate(false);
          return isNotAllRight = true;
        }
        if ((gatesFactory[value.name].gateIn == null || gatesFactory[value.name].gateOut == null) && !isInternalCar) {
          handleClickAreaOrder(value.name, index)
          notification.error({
            message: t("journeyManagementPage.validate.gateNull"),
          });
          setIsLoadingCreate(false);
          return isNotAllRight = true;
        }
      })
    }
    // if (dataSource?.length === 0) {
    //   notification.error({
    //     message: t("journeyManagementPage.validate.deliveryRequired"),
    //   });
    //   setIsLoadingCreate(false);
    //   return true;
    // }
    // if (dataSource?.find((item: any) => item.name === "" || item.name === undefined)) {
    //   notification.error({
    //     message: t("journeyManagementPage.validate.deliveryWhiteSpace"),
    //   });
    //   setIsLoadingCreate(false);
    //   return true;
    // }
    if (checkDuplicate.length > 0) {
      notification.error({
        message: t("journeyManagementPage.validate.deliveryDuplicate"),
      });
      setIsLoadingCreate(false);
      return true;
    }
    return isNotAllRight;
  }

  // Xử lý call API đăng ký xe vào công ty
  const onFinish = async (values: any) => {
    setIsLoadingCreate(true);
    let dataSubmit = {};
    if (isSEV) {
      if (checkOut()) {
        return;
      }

      const driver = driverSelectData.find(value => value.id === values.driver)

      dataSubmit = {
        companyName: values.companyName,
        categoryCar: { id: values?.categoryCar },
        plateNumber: values.plateNumber,
        optionDelivery: values.optionDelivery,
        driverName: driver?.name,
        driverIdentity: driver?.uniqueid,
        totalDoor: values.totalDoor,
        gateIn: values.gateIn,
        gateOut: values.gateOut,
        source: profile.source,
        registerFactoryArea: !isInternalCar ? listFactoryArea.map((value, index) => {
          return {
            factoryArea: { id: value.id },
            areaOrder: index + 1,
            companyDeliverySites: [
              {
                categoryPosition: {
                  id: gatesFactory[value.name].gateIn
                },
                orderNumber: 0
              },
              ...listCDSFactory[value.name].map((v, i) => {
                return {
                  categoryPosition: {
                    id: v.id,
                  },
                  orderNumber: i + 1
                }
              }),
              {
                categoryPosition: {
                  id: gatesFactory[value.name].gateOut
                },
                orderNumber: 1000
              }
            ]
          }
        }) : []
      };
    } else {
      dataSubmit = {
        ...values,
        categoryCar: { id: vehicleTypeSelectData?.find((item) => item?.type === 2)?.id },
        source: profile.source,
      };
    }
    console.log(`Datasubmit: ${JSON.stringify(dataSubmit)}`);

    const resp = await journeyServices.createJourney(dataSubmit);
    const data = resp?.data;
    if (resp?.status === 200) {
      notification.success({
        message: t(
          isSEV
            ? "journeyManagementPage.message.createJourneySuccess"
            : "journeyManagementPage.message.registerJourneySuccess",
        ),
      });
      naviagte(componentPath);
    } else {
      notification.error({
        message: data?.message || t("commonError.oopsSystem"),
      });
    }

    setIsLoadingCreate(false);
  };

  // Get name by id
  const getNamePositonByID = (id: any) => {
    const item = positionSelectData.find(position => position.id === id)
    return item!.name
  }

  // Thay đổi cổng vào
  const onChangeGateIn = (values: any) => {
    // gates[txtAreaOrder].gateIn = values
    if (gatesFactory[currentArea.name] === undefined) {
      gatesFactory[currentArea.name] = {
        gateIn: null,
        gateOut: null
      }
    }
    gatesFactory[currentArea.name].gateIn = values
  }

  // Thay đổi cổng ra
  const onChangeGateOut = (values: any) => {
    // gates[txtAreaOrder].gateOut = values
    if (gatesFactory[currentArea.name] === undefined) {
      gatesFactory[currentArea.name] = {
        gateIn: null,
        gateOut: null
      }
    }
    gatesFactory[currentArea.name].gateOut = values
  }

  // Thêm dòng
  const handleAddNewRow = () => {
    const newData: ICompanyDeliverySitesAddData = {
      cdsId: 0,
      id: 0,
      key: (dataSource?.length + 1).toString(),
      index: dataSource?.length + 1,
      name: "",
      receiptNumber: 0,
      receiptUpdateTime: '',
      disabled: false
    };
    const updatedDataSource = [...dataSource, newData];
    setDataSource(updatedDataSource);
    // setListCDS({ ...listCDS, [txtAreaOrder]: updatedDataSource as [] })
    setListCDSFactory({ ...listCDSFactory, [currentArea.name]: updatedDataSource })
  };

  //Sort row
  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setDataSource((previous) => {
        const activeIndex = previous.findIndex((i) => i.key === active.id);
        const overIndex = previous.findIndex((i) => i.key === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };

  const columns: ColumnsType<ICompanyDeliverySitesAddData> = [
    {
      key: "sort",
      width: "5%",
    },
    {
      title: t("journeyManagementPage.columns.ordernumber"),
      key: "index",
      align: "center",
      width: "15%",
      render: (value: any, record: ICompanyDeliverySitesAddData, index: number) => index + 1,
    },
    {
      title: t("journeyManagementPage.columns.name"),
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (value: any, record: ICompanyDeliverySitesAddData, index: number) => {
        return (
          <div
            className={`row-table ${!value || checkDuplicate?.includes(record.key) ? "dangerous" : ""
              }`}
          >
            {/* Dropdown select Vị trí/giao nhận hàng */}
            <CommonSelect
              style={{ width: "60%", textAlign: "left" }}
              disabled={record?.disabled}
              options={positionSelectData
                ?.filter((item: ILocationDetailData) => item?.type === LOCATION_VALUES.WAREHOUSE && Number(item.factoryAreaId) === currentArea.id)
                ?.map((item: ILocationDetailData) => ({
                  id: item.id,
                  value: item.id,
                  label: item.name,
                  name: item.name,
                  areaOrder: txtAreaOrder
                }))}
              placeholder={t("journeyManagementPage.placeholder.name")}
              onChange={(values) => {
                setDataSource((prevState) => {
                  const newData = [...prevState];
                  newData[index].id = values;
                  newData[index].name = getNamePositonByID(values);
                  return newData;
                });
              }}
              value={value || undefined}
              allowClear
              showSearch
            />
          </div>
        );
      },
    },
    {
      key: "action",
      align: "center",
      width: "10%",
      render: (value: any, record: ICompanyDeliverySitesAddData, index: number) => {
        if (record?.disabled) {
          return <DeleteIcon className="delete-icon" style={{ cursor: "not-allowed" }} />;
        }
        return (
          <DeleteIcon
            className="delete-icon"
            onClick={() => {
              setVisible(true);
              setItemDeleteId(record.index);
            }}
          />
        );
      },
    },
  ];

  // Xoá một lộ trình
  const handleRemoveRowJourney = (index: number) => {
    form.setFieldsValue({
      [index]: null
    })
    if (listFactoryArea[index].id === currentArea.id) {
      form.setFieldsValue({
        gateIn: null,
        gateOut: null
      })
      setDataSource([])
    }
    let newA = [...listFactoryArea]
    let removeP: FactoryArea[] = newA.splice(index, 1)
    delete listCDSFactory[removeP[0].name]
    delete gatesFactory[removeP[0].name]
    setAreaOrderTab(-1)
    setListFactoryArea(newA)
    // </>
  }

  // Thêm một lộ trình
  const handleAddNewRowJourney = () => {
    setListFactoryArea(prev => [
      ...prev,
      {
        id: 0,
        rFAId: 0,
        name: ""
      }
    ])
    // </>
  }

  // Thay đổi view lộ trình
  const handleClickAreaOrder = (props: any, index: number) => {
    if (checkDuplicate.length > 0) {
      notification.error({
        message: t("journeyManagementPage.validate.deliveryDuplicate"),
      });
      setIsLoadingCreate(false);
      return
    }
    if (listFactoryArea[index].id === 0) {
      setIsCantChange(true)
    } else {
      setIsCantChange(false)
    }
    setAreaOrderTab(index)
    // setTxtAreaOrder(`${props.name.split('/')[0]}`)
    setCurrentArea(listFactoryArea[index])
  }

  // Thay đổi loại xe
  const handleChangeCategoryCar = (key: any, item: any) => {
    if (item !== undefined) {
      if (item.type === 99) {
        setIsInternalCar(true)
      } else {
        setIsInternalCar(false)
      }
    }
  }

  // Thay đổi một khu vực
  const handleChangeArea = (key: any, item: any) => {
    setIsCantChange(true)
    // Dữ liệu temp
    const temp = {
      id: 0,
      rFAId: 0,
      name: ""
    }
    // Set dữ liệu
    if (item !== undefined) {
      listFactoryArea[item.index] = {
        id: key,
        rFAId: 0,
        name: `${item.label}/${item.index}`
      }
      // Trường hợp nhiều hơn một khu vực trong lộ trình
      if (listFactoryArea.length > 1) {
        // Kiểm tra phần tử có vị trí đứng trước
        if (item.index - 1 >= 0) {
          if (listFactoryArea[item.index].id === listFactoryArea[item.index - 1].id) {
            delete gatesFactory[currentArea.name]
            delete listCDSFactory[currentArea.name]
            listFactoryArea[item.index] = temp
            setCurrentArea(temp)
            setIsCantChange(true)
            form.setFieldsValue({
              [item.index]: temp
            })
            notification.error({
              message: "Lộ trình gần nhau không được giống nhau",
            });
            return
          }
        }
        // Kiểm tra phần tử có vị trí đứng đằng sau
        if (item.index + 1 < listFactoryArea.length) {
          if (listFactoryArea[item.index].id === listFactoryArea[item.index + 1].id) {
            delete gatesFactory[currentArea.name]
            delete listCDSFactory[currentArea.name]
            listFactoryArea[item.index] = temp
            setCurrentArea(temp)
            setIsCantChange(true)
            form.setFieldsValue({
              [item.index]: temp
            })
            notification.error({
              message: "Lộ trình gần nhau không được giống nhau",
            });
            return
          }
        }
      }
    }
    setIsCantChange(false)
  }

  // Kéo thả khu vực
  const handleDragEnd = (result: any) => {
    // Nếu không có hành động nào
    if (!result.destination) {
      return;
    }

    // Nếu trong danh sách khu vực có một khu vực rỗng
    if (listFactoryArea.some(value =>
      value.id === 0
    )) {
      notification.error({
        message: "Tồn tại một khu vực chưa được xác định. Hãy xác định khu vực trước khi sắp xếp!",
      });
      return
    }

    // Tráo đổi vị trị sắp xếp
    const items = Array.from(listFactoryArea);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    // Nếu tồn tại một khu vực giống nhau ở gần nhau
    if (items.length > 2) {
      for (let i = 0; i < items.length - 1; i++) {
        if (items[i].id === items[i + 1].id) {
          notification.error({
            message: "Các khu vực giống nhau không được gần nhau!",
          });
          return
        }
      }
    }

    setAreaOrderTab(items.findIndex(value => value.name === currentArea.name))
    items.map((v, index) => {
      form.setFieldsValue({
        [index]: v.id
      })
    })

    setListFactoryArea(items);

    console.log(JSON.stringify(listFactoryArea));
  };

  return (
    <div className="box-wrapper">
      <CommonForm form={form} onFinish={onFinish} layout="vertical">
        <div className="avic-search-box">
          <div className="advance-search-box journey-box">
            <div style={{ padding: "0 4rem 1rem 4rem" }}>
              <div className="form-title">{t("journeyManagementPage.registerTitle")}</div>
              <Row gutter={30}>
                {isSEV ? (
                  <>
                    <Col span={12}>
                      <CommonFormItem
                        name="companyName"
                        label={t("journeyManagementPage.label.companyName")}
                        placeholder={t("journeyManagementPage.placeholder.companyName") as string}
                        showRequiredIcon
                        maxLength={128}
                        disabled={true}
                        rules={[
                          {
                            required: true,
                            message: `${t("validate.input")} ${t(
                              "journeyManagementPage.label.companyName",
                            )}.`,
                          },
                        ]}
                      />
                    </Col>
                    <Col span={12}>
                      <CommonFormItem
                        name="categoryCar"
                        onChange={handleChangeCategoryCar}
                        label={t("journeyManagementPage.label.vehicleType")}
                        placeholder={t("journeyManagementPage.placeholder.vehicleType") as string}
                        options={vehicleTypeSelectData.map((item: IVehicleTypeDetailData) => ({
                          value: item.id,
                          label: item.name,
                          type: item.type
                        }))}
                        type="select"
                        allowClear={false}
                        showRequiredIcon={isSEV}
                        disabled={!isSEV}
                        rules={[
                          {
                            required: true,
                            message: `${t("validate.select")} ${t(
                              "journeyManagementPage.label.vehicleType",
                            )}.`,
                          },
                        ]}
                      />
                    </Col>
                  </>
                ) : (
                  <></>
                )}
                <Col span={12}>
                  <CommonFormItem
                    name="plateNumber"
                    label={t("journeyManagementPage.label.plateNumber")}
                    placeholder={t("journeyManagementPage.placeholder.plateNumber") as string}
                    showRequiredIcon
                    rules={[
                      {
                        whiteSpace: true,
                        required: true,
                        message: `${t("validate.input")} ${t(
                          "journeyManagementPage.label.plateNumber",
                        )}.`,
                      },
                      {
                        pattern: REGEX_CODE,
                        message: t("journeyManagementPage.validate.plateNumber"),
                      },
                    ]}
                  />
                </Col>
                <Col span={12}>
                  <CommonFormItem
                    name="optionDelivery"
                    label={t("journeyManagementPage.label.optionDelivery")}
                    placeholder={t("journeyManagementPage.placeholder.optionDelivery") as string}
                    showRequiredIcon
                    options={OPTION_DELIVERY?.map((x) => ({ ...x, label: t(x?.label) }))}
                    type="select"
                    rules={[
                      {
                        required: true,
                        message: `${t("validate.select")} ${t(
                          "journeyManagementPage.label.optionDelivery",
                        )}.`,
                      },
                    ]}
                  />
                </Col>
                {/* <Col span={12}>
                  <CommonFormItem
                    name="driverName"
                    label={t("journeyManagementPage.label.driverName")}
                    placeholder={t("journeyManagementPage.placeholder.driverName") as string}
                    showRequiredIcon
                    maxLength={128}
                    rules={[
                      {
                        whiteSpace: true,
                        required: true,
                        message: `${t("validate.input")} ${t(
                          "journeyManagementPage.label.driverName",
                        )}.`,
                      },
                    ]}
                  />
                </Col> */}
                <Col span={12}>
                  <CommonFormItem
                    name="driver"
                    type="select"
                    label={t("journeyManagementPage.label.driver")}
                    placeholder={t("journeyManagementPage.placeholder.driver") as string}
                    showRequiredIcon
                    maxLength={12}
                    options={driverSelectData.map((item: IDriverDetailData) => ({
                      value: item.id,
                      label: item.uniqueid + " - " + item.name
                    }))}
                    rules={[
                      {
                        required: true,
                        message: `${t("validate.input")} ${t(
                          "journeyManagementPage.label.driver",
                        )}.`
                      }
                    ]}
                  />
                </Col>
                <Col span={12}>
                  <CommonFormItem
                    name="totalDoor"
                    label={t("journeyManagementPage.label.totalDoor")}
                    placeholder={t("journeyManagementPage.placeholder.totalDoor") as string}
                    showRequiredIcon
                    disabled={true}
                    maxLength={128}
                    rules={[
                      {
                        whiteSpace: true,
                        required: true,
                        message: `${t("validate.input")} ${t(
                          "journeyManagementPage.label.totalDoor",
                        )}.`,
                      },
                      {
                        pattern: REGEX_NUMBER_GREATER_ZERO,
                        message: `${t("journeyManagementPage.label.totalDoor")} ${t(
                          "validate.greaterThanZore",
                        )}.`,
                      },
                    ]}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </div>

        {isSEV && !isInternalCar ? (
          <div className="avic-search-box">
            <div className="advance-search-box journey-box">
              <div className="list" style={{ flex: "0.6 1.4", width: "100%", marginRight: "10px" }}>
                <div className="form-title"
                  onClick={() => {
                    console.log(`listFactoryArea: ${JSON.stringify(listFactoryArea)}`)
                    console.log(`gatesFactory: ${JSON.stringify(gatesFactory)}`)
                    console.log(`listCDSFactory: ${JSON.stringify(listCDSFactory)}`)
                  }}>
                  {t("journeyManagementPage.journeyListTitle")}
                </div>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {listFactoryArea.map((props, index) => (
                          <Draggable key={index} draggableId={index.toString()} index={index}>
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  cursor: "pointer",
                                  width: "100%",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  background: areaOrderTab === index ? "#3f80fe" : "#FFFFFF",
                                  borderRadius: "8px",
                                  paddingLeft: "15px",
                                  paddingRight: "15px",
                                  fontSize: "12px",
                                  fontWeight: 500,
                                  lineHeight: "30px",
                                  marginBottom: "10px",
                                  border: areaOrderTab === index ? "none" : "1px solid #d9d9d9",
                                  color: areaOrderTab === index ? "#FFFFFF" : "#000000",
                                  display: "flex",
                                  ...provided.draggableProps.style
                                }}
                              >
                                <div
                                  style={{
                                    width: "30px",
                                    borderRight: areaOrderTab === index ? "1px solid #FFFFFF" : "1px solid #000000",
                                    margin: "10px"
                                  }}
                                >
                                  {index + 1}
                                </div>
                                <div
                                  onClick={() => handleClickAreaOrder(props, index)}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: '100%'
                                  }}
                                >
                                  <CommonFormItem
                                    name={index}
                                    label={null}
                                    placeholder={t("journeyManagementPage.placeholder.area") as string}
                                    options={listArea.map((item: IArea) => ({
                                      value: item.id,
                                      label: item.name,
                                      index: index
                                    }))}
                                    allowClear={false}
                                    onChange={handleChangeArea}
                                    type="select"
                                    style={{ marginBottom: '0px', width: '75%' }}
                                  />
                                </div>
                                <DeleteIcon style={{ marginLeft: '15px' }} onClick={() => handleRemoveRowJourney(index)} />
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                <AddIcon className="add-icon" onClick={handleAddNewRowJourney} />
              </div>
              <div className="itemList" style={{ flex: "1.4 0.6", width: "100%", borderLeft: "1px solid #7f7f7f", paddingLeft: "10px" }}>
                <div className="form-title">{t("journeyManagementPage.deliveryTitle")}</div>
                {/* <h4 style={{ textAlign: "center", fontWeight: 800 }}>
                  {txtAreaOrder}
                </h4> */}
                <Row gutter={30}>
                  <Col span={12}>
                    <CommonFormItem
                      name="gateIn"
                      onChange={onChangeGateIn}
                      label={t("journeyManagementPage.label.gateIn")}
                      placeholder={t("journeyManagementPage.placeholder.gateIn") as string}
                      options={positionSelectData
                        ?.filter(
                          (item: ILocationDetailData) => item?.type === LOCATION_VALUES.GATE_IN && Number(item.factoryAreaId) === currentArea.id,
                        )
                        ?.map((item: ILocationDetailData) => ({
                          value: item.id,
                          label: item.name,
                          name: item.name
                        }))}
                      type="select"
                      showRequiredIcon
                      disabled={isCantChange}
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: `${t("validate.select")} ${t(
                    //       "journeyManagementPage.label.gateIn",
                    //     )}.`,
                    //   },
                    // ]}
                    />
                  </Col>
                  <Col span={12}>
                    <CommonFormItem
                      name="gateOut"
                      onChange={onChangeGateOut}
                      label={t("journeyManagementPage.label.gateOut")}
                      placeholder={t("journeyManagementPage.placeholder.gateOut") as string}
                      options={positionSelectData
                        ?.filter((item: any) => item.type === LOCATION_VALUES.GATE_OUT && Number(item.factoryAreaId) === currentArea.id)
                        ?.map((item: any) => ({
                          value: item.id,
                          label: item.name,
                          name: item.name
                        }))}
                      type="select"
                      disabled={isCantChange}
                      showRequiredIcon
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: `${t("validate.select")} ${t(
                    //       "journeyManagementPage.label.gateOut",
                    //     )}.`,
                    //   },
                    // ]}
                    />
                  </Col>
                  <Col span={24} className="mgt-10">
                    <DndContext onDragEnd={onDragEnd}>
                      <SortableContext
                        // rowKey array
                        items={dataSource.map((i) => i.key)}
                        strategy={verticalListSortingStrategy}
                      >
                        <CommonTable
                          components={{
                            body: {
                              row: RowTable,
                            },
                          }}
                          scroll={{ y: "20.833vw" }}
                          pagination={false}
                          rowKey="key"
                          columns={columns}
                          dataSource={dataSource}
                        />
                      </SortableContext>
                    </DndContext>
                  </Col>
                </Row>
                {isCantChange ? <></> : <AddIcon className="add-icon" onClick={handleAddNewRow} />}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        <Space className="form-btn-container" style={{ justifyContent: "center" }}>
          <Link to={componentPath}>
            <CommonButton btnType="default" size={"large"}>
              {t("common.button.cancel")}
            </CommonButton>
          </Link>
          <CommonButton
            btnType="primary"
            size={"large"}
            htmlType="submit"
            loading={isLoadingCreate}
          >
            {t("common.button.register")}
          </CommonButton>
        </Space>
      </CommonForm>

      <CommonConfirmModal
        onCancel={() => {
          setVisible(false);
        }}
        content={t("journeyManagementPage.message.deleteDelivery")}
        visible={visible}
        onOk={onDeleteRow}
        loadingBtnOk={loadingConfirm}
      />
    </div>
  );
}

export default JourneyAddForm;
