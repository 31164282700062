import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

interface CommonSpinProps {
  children?: any;
  isLoading?: boolean;
}

const CommonSpin = (props: CommonSpinProps) => {
  const { children, isLoading = false } = props;

  if (!children) {
    return (
      <div className="dpl-flex align-items-center justify-content-center pdt-30 pdbt-30">
        <LoadingOutlined style={{ fontSize: "1.25vw" }} spin />
      </div>
    );
  }

  return (
    <Spin indicator={<LoadingOutlined style={{ fontSize: "1.25vw" }} spin />} spinning={isLoading}>
      {children}
    </Spin>
  );
};

export default CommonSpin;
