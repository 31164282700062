import { Layout, Menu } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as CollapseIcon } from "../../resources/images/sidebar-collapse-icon.svg";
import { ReactComponent as UnCollapseIcon } from "../../resources/images/sidebar-uncollapse-icon.svg";
import { ISideBarProps } from "../../utils/types/layout";

const { Sider } = Layout;

function Sidebar({ config }: ISideBarProps) {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState<boolean>(false);

  return (
    <Sider className="sidebar-container" collapsible collapsed={collapsed} trigger={null}>
      <div className="sidebar-box">
        <div className="sidebar-content">
          <div>
            {collapsed && (
              <div className="un-collapse-icon" onClick={() => setCollapsed(false)}>
                <UnCollapseIcon />
              </div>
            )}
            {!collapsed && (
              <div className="collapse-icon" onClick={() => setCollapsed(true)}>
                <CollapseIcon />
              </div>
            )}

            <div className="logo">{!collapsed ? t("layout.sidebar.title") : null}</div>
          </div>

          {config?.arrMenus ? (
            <Menu
              defaultSelectedKeys={[config?.selectedKeys]}
              defaultOpenKeys={[config?.openKeys]}
              selectedKeys={[config?.selectedKeys]}
              openKeys={[config?.openKeys]}
              mode="inline"
              items={config?.arrMenus}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </Sider>
  );
}

export default Sidebar;
