import { Col, Form, Row, notification } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CommonButton from "../../components/Common/Button";
import CommonForm from "../../components/Common/Form";
import CommonFormItem from "../../components/Common/FormItem";
import locationServices from "../../services/locationCategory.service";
import { COMMON_PAGE_STATUS, ROLE_LIST } from "../../utils/constans";
import { LOCATION_BUILDINGS, LOCATION_TYPES } from "../../utils/constans/locationCategory";
import { ILocationAddModalProps } from "../../utils/types/locationCategory";
import CommonModal from "./../../components/Common/Modal";
import { useSelector } from "react-redux";
import { isHavePermission } from "../../utils/utilFunctions";
// Thêm chọn khu vực
import journeyServices from "../../services/journeyManagement.service";
import { IArea } from "../../utils/types/journeyManagement";

const LocationAddModal = ({
  newLocation,
  dataDetail,
  visible,
  handleOk,
  handleCancel,
}: ILocationAddModalProps) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const { profile } = useSelector((state: any) => state?.profileReducer);
  const isCanEdit = isHavePermission(ROLE_LIST.MANAGER_POSITION_EDIT, profile);
  // Thêm chọn khu vực
  const [listArea, setListArea] = useState<IArea[]>([])

  useEffect(() => {
    getDataArea()
  }, [])

  const onFinish = (values: any) => {
    onCreatePosition(values);
  };

  const getDataArea = async () => {
    const res = await journeyServices.getAllArea()
    if (res.status === 200) {
      setListArea([...res.data.data])
    } else {
      setListArea([])
    }
  }

  const onCreatePosition = async (values: any) => {
    setIsLoading(true);
    const dataUpdate = {
      latitude: newLocation?.lat,
      longitude: newLocation?.lng,
      ...dataDetail,
      ...values,
    };
    const resp = dataDetail?.id
      ? await locationServices.updatePosition(dataDetail?.id, dataUpdate)
      : await locationServices.createPosition(dataUpdate);
    const data = resp?.data;
    if (resp?.status === 200) {
      notification.success({
        message: dataDetail?.id
          ? t("locationCategoryPage.message.editSuccess")
          : t("locationCategoryPage.message.createSuccess"),
      });
      setIsEditing(false);
      handleOk();
    } else {
      notification.error({
        message: data?.message || t("commonError.oopsSystem"),
      });
    }
    setIsLoading(false);
  };

  return (
    <CommonModal
      className="lock-modal-create"
      title={
        dataDetail?.id ? t("locationCategoryPage.editTile") : t("locationCategoryPage.addTile")
      }
      open={visible}
      maskClosable={false}
      onCancel={handleCancel}
      footer={[
        isEditing && dataDetail?.id ? (
          <CommonButton onClick={() => setIsEditing(false)} size={"small"} btnType="default">
            {t("common.button.cancel")}
          </CommonButton>
        ) : (
          <CommonButton onClick={handleCancel} size={"small"} btnType="default">
            {t("common.button.exit")}
          </CommonButton>
        ),
        isCanEdit && dataDetail?.id ? (
          isEditing ? (
            <CommonButton
              form="myForm"
              onClick={() => form.submit()}
              btnType="primary"
              size={"small"}
              loading={isLoading}
            >
              {t("common.button.update")}
            </CommonButton>
          ) : (
            <CommonButton onClick={() => setIsEditing(true)} btnType="primary" size={"small"}>
              {t("common.button.edit")}
            </CommonButton>
          )
        ) : (
          <></>
        ),

        !dataDetail?.id ? (
          <CommonButton
            form="myForm"
            onClick={() => form.submit()}
            btnType="primary"
            size={"small"}
            loading={isLoading}
          >
            {t("common.button.addNew")}
          </CommonButton>
        ) : (
          <></>
        ),
      ]}
    >
      <CommonForm
        form={form}
        onFinish={onFinish}
        layout="vertical"
        initialValues={{
          id: dataDetail?.id,
          name: dataDetail?.name,
          type: dataDetail?.type,
          factoryAreaId: dataDetail?.factoryAreaId,
          status: dataDetail?.status,
        }}
      >
        <Row gutter={30}>
          {dataDetail?.id ? (
            <Col span={24}>
              <CommonFormItem
                name="id"
                label={t("locationCategoryPage.label.locationCode")}
                placeholder={t("locationCategoryPage.placeholder.locationCode") as string}
                disabled={true}
              />
            </Col>
          ) : (
            <></>
          )}
          <Col span={24}>
            <CommonFormItem
              name="name"
              label={t("locationCategoryPage.label.name")}
              placeholder={t("locationCategoryPage.placeholder.name") as string}
              showRequiredIcon={!(!!dataDetail?.id && (!isCanEdit || !isEditing))}
              disabled={!!dataDetail?.id && (!isCanEdit || !isEditing)}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: `${t("validate.input")} ${t("locationCategoryPage.label.name")}.`,
                },
              ]}
            />
          </Col>
          <Col span={24}>
            <CommonFormItem
              name="type"
              label={t("locationCategoryPage.label.locationType")}
              placeholder={t("locationCategoryPage.placeholder.locationType") as string}
              options={LOCATION_TYPES.map((x) => ({ ...x, label: t(x.label) }))}
              showRequiredIcon={!(!!dataDetail?.id && (!isCanEdit || !isEditing))}
              disabled={!!dataDetail?.id && (!isCanEdit || !isEditing)}
              type="select"
              rules={[
                {
                  required: true,
                  message: `${t("validate.select")} ${t(
                    "locationCategoryPage.label.locationType",
                  )}.`,
                },
              ]}
            />
          </Col>
          <Col span={24}>
            {/* Thêm chọn Khu vực – Ẩn Thuộc tòa nhà */}
            {/* <CommonFormItem
              name="area"
              label={t("locationCategoryPage.label.building")}
              placeholder={t("locationCategoryPage.placeholder.building") as string}
              options={listArea.map((x) => ({ ...x, label: x.name }))}
              showRequiredIcon={!(!!dataDetail?.id && (!isCanEdit || !isEditing))}
              disabled={!!dataDetail?.id && (!isCanEdit || !isEditing)}
              type="select"
              rules={[
                {
                  required: true,
                  message: `${t("validate.select")} ${t("locationCategoryPage.label.building")}.`,
                },
              ]}
            /> */}
            <CommonFormItem
              name="factoryAreaId"
              label={t("locationCategoryPage.label.building")}
              placeholder={t("locationCategoryPage.placeholder.building") as string}
              showRequiredIcon={!(!!dataDetail?.id && (!isCanEdit || !isEditing))}
              options={listArea.map((item: IArea) => ({
                value: item.id,
                label: item.name
              }))}
              disabled={!!dataDetail?.id && (!isCanEdit || !isEditing)}
              type="select"
              rules={[
                {
                  required: true,
                  message: `${t("validate.select")} ${t("locationCategoryPage.label.building")}.`,
                },
              ]}
            />
          </Col>
          <Col span={24}>
            <CommonFormItem
              name="status"
              label={t("locationCategoryPage.label.status")}
              placeholder={t("locationCategoryPage.placeholder.status") as string}
              options={COMMON_PAGE_STATUS.map((x) => ({ ...x, label: t(x.label) }))}
              showRequiredIcon={!(!!dataDetail?.id && (!isCanEdit || !isEditing))}
              disabled={!!dataDetail?.id && (!isCanEdit || !isEditing)}
              type="select"
              rules={[
                {
                  required: true,
                  message: `${t("validate.select")} ${t("locationCategoryPage.label.status")}.`,
                },
              ]}
            />
          </Col>
        </Row>
      </CommonForm>
    </CommonModal>
  );
};

export default LocationAddModal;
