import { LatLngExpression } from "leaflet";
import moment from "moment";
import { Dispatch, SetStateAction, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Marker, Popup, useMap } from "react-leaflet";
import { leafIcon } from "../../../../components/Map";
import marker from "../../../../resources/images/marker/marker-icon.png";
import markerSelected from "../../../../resources/images/marker/marker_selected.png";
import markerVio from "../../../../resources/images/marker/marker_vio.png";
import { DATE_TIME_FORMAT_SECOND } from "../../../../utils/constans";
import { ACTION_TYPE_VALUES } from "../../../../utils/constans/journeyManagement";
import { IActionAndVioDetailData } from "../../../../utils/types/journeyManagement";

const VioControl = ({
  listData,
  currentVioSelected,
  setCurrentVioSelected,
}: {
  listData: IActionAndVioDetailData[];
  currentVioSelected: IActionAndVioDetailData | undefined;
  setCurrentVioSelected: Dispatch<SetStateAction<IActionAndVioDetailData | undefined>>;
}) => {
  const mapComponent = useMap();
  const { t } = useTranslation();
  const popupRefs = useRef<any[]>([]);
  const iconMarker = leafIcon(marker);
  const iconMarkerSelected = leafIcon(markerSelected);
  const iconMarkerVio = leafIcon(markerVio);

  mapComponent.on("click", function (event) {
    setCurrentVioSelected(undefined);
  });

  // fly đến vị trí được chọn
  useEffect(() => {
    if (currentVioSelected?.position?.latitude && currentVioSelected?.position?.longitude) {
      mapComponent.flyTo(
        [currentVioSelected?.position?.latitude, currentVioSelected?.position?.longitude],
        18,
      );

      // mở popup chuyến được chọn
      const selectedIndex = listData?.findIndex((item) => item?.id === currentVioSelected?.id);
      if (
        typeof selectedIndex === "number" &&
        selectedIndex >= 0 &&
        popupRefs.current[selectedIndex]
      ) {
        popupRefs.current[selectedIndex]?.openPopup();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVioSelected]);

  return (
    <>
      {listData?.map((item, index: number) =>
        item?.position?.latitude && item?.position?.longitude ? (
          <Marker
            key={item?.id}
            position={[item?.position?.latitude, item?.position?.longitude] as LatLngExpression}
            icon={
              item?.id === currentVioSelected?.id
                ? iconMarkerSelected
                : item?.type === ACTION_TYPE_VALUES.ACCTION
                ? iconMarker
                : iconMarkerVio
            }
            alt="Marker alt"
            title={`${item?.createdDate} (${item?.description})`}
            ref={(el) => (popupRefs.current[index] = el)}
          >
            <Popup>
              <h3 className="mgbt-10">{`${t("journeyManagementPage.label.time")}: ${
                item?.createdDate ? moment(item?.createdDate).format(DATE_TIME_FORMAT_SECOND) : "--"
              }`}</h3>
              <h3 className="mgbt-10">{`${t("journeyManagementPage.label.description")}: ${
                item?.description || "--"
              }`}</h3>
            </Popup>
          </Marker>
        ) : (
          <></>
        ),
      )}
    </>
  );
};

export default VioControl;
