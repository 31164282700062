// Api danh mục loại xe
import APIService from "../utils/apiServices";
import { ICommonSearchParams } from "../utils/types";
import { IVehicleTypeAddData, IVehicleTypeDetailData } from "../utils/types/vehicleType";

class VehicleTypeCategoryServices extends APIService {
  // Lấy danh sách
  async getListVehicleType(params: ICommonSearchParams) {
    let apiParams = {
      page: params.page || 0,
      size: params.size || 10,
      sortType: params.sortType || "DESC",
      sortBy: params.sortBy || "modifiedDate",
      search: params.search || JSON.stringify({}),
    };
    return await this.request(
      "GET",
      `/api/v1/system/category/car/getPage`,
      {},
      { params: apiParams },
    );
  }

  // chi tiết
  async detailVehicleType(id: number) {
    return await this.request("GET", `/api/v1/system/category/car/${id}`);
  }

  // Thêm mới
  async createVehicleType(data: IVehicleTypeAddData) {
    return await this.request("POST", `/api/v1/system/category/car`, data);
  }

  // Chỉnh sửa
  async updateVehicleType(id: number, data: IVehicleTypeDetailData) {
    return await this.request("PUT", `/api/v1/system/category/car/${id}`, data);
  }

  // Xóa
  async deleteVehicleType(data: number[]) {
    return await this.request("DELETE", `/api/v1/system/category/car`, data);
  }

  // Khóa
  async lockVehicleType(data: number[]) {
    return await this.request("POST", `/api/v1/system/category/car/lock`, data);
  }

  // Mở khóa
  async unlockTypeVehicleType(data: number[]) {
    return await this.request("POST", `/api/v1/system/category/car/unlock`, data);
  }

  // Xuất excel
  async exportVehicleType(params: ICommonSearchParams) {
    return await this.request(
      "GET",
      `/api/v1/system/category/car/exportExcel`,
      {},
      {
        responseType: "blob",
        params: params,
      },
    );
  }
}
const service = new VehicleTypeCategoryServices();
export default service;
