import { Col, Form, FormProps, Input, List, Modal, Row, notification } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CommonButton from "../../components/Common/Button";
import CommonForm from "../../components/Common/Form";
import CommonFormItem from "../../components/Common/FormItem";
import { ReactComponent as Checkbox } from "../../resources/images/checkbox-circle.svg";
import { ReactComponent as Error } from "../../resources/images/error.svg";
import userServices from "../../services/accountManagement.service";
import { AT_LEAST_1_NUMBER, AT_LEAST_8_CHARS } from "../../utils/constans";
import { ChangePasswordData } from "../../utils/types/accountManagement";

export interface ChangePasswordProps {
  userId: number | undefined;
  openModal: boolean;
  handleOk: () => void;
}

function ChangePassword(props: ChangePasswordProps) {
  const { openModal, handleOk, userId } = props;
  const { t } = useTranslation();
  const [activeIndexes, setActiveIndexes] = useState<number[]>([]);
  const [repeatError, setRepeatError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formChanged, setFormChanged] = useState<boolean>(false);
  const [form] = Form.useForm<FormProps<ChangePasswordData>>();
  const newPassword = Form.useWatch("newPassword", form);

  const onFinish = async (values: ChangePasswordData) => {
    setIsLoading(true);
    const resp = await userServices.changePasswordByAdmin({ ...values, userId });
    if (resp?.status === 200) {
      notification.success({
        message: t("profilePage.message.changePasswordSuccess"),
      });
      // LocalStorage.getInstance().save("accessToken", null);
      // window.location.href = "/";
      handleOk();
    } else {
      notification.error({
        message: resp?.data?.message || t("commonError.oopsSystem"),
      });
    }
    setIsLoading(false);
  };

  const ListItems = [
    t("profilePage.condition.atLeast8Chars"),
    // t('profilePage.condition.atLeast1Uppercase'),
    t("profilePage.condition.atLeast1Number"),
  ];

  useEffect(() => {
    if (newPassword) {
      let newActiveIndexes: number[] = [];
      if (newPassword.match(AT_LEAST_8_CHARS)) {
        newActiveIndexes.push(0);
      }
      // if (newPassword.match(atLeast1Uppercase)) {
      //     newActiveIndexes.push(1);
      // }
      if (newPassword.match(AT_LEAST_1_NUMBER)) {
        newActiveIndexes.push(1);
      }
      setActiveIndexes(newActiveIndexes);
    } else {
      setActiveIndexes([]);
    }
  }, [newPassword]);

  const onValuesChange = (changedValues: ChangePasswordData) => {
    setFormChanged(true);
  };

  const onCancel = () => {
    if (formChanged) {
      Modal.confirm({
        title: t("common.confirmAction"),
        okText: t("common.button.accept"),
        centered: true,
        onOk: async () => {
          form.resetFields();
          setFormChanged(false);
          handleOk();
        },
        onCancel: () => {
          console.log("Cancel");
        },
        cancelText: t("common.button.cancel"),
      });
    } else {
      handleOk();
    }
  };

  return (
    <Modal
      className="modal-change-password"
      title={t("profilePage.changePasswordTabTitle")}
      open={openModal}
      centered
      maskClosable={false}
      onCancel={onCancel}
      footer={[
        <CommonButton key="cancel" onClick={onCancel} size={"small"}>
          {t("common.button.cancel")}
        </CommonButton>,
        <CommonButton
          form="myForm"
          key="submit"
          htmlType="submit"
          btnType="primary"
          size={"small"}
          loading={isLoading}
          onClick={() => form.submit()}
        >
          {t("common.button.save")}
        </CommonButton>,
      ]}
    >
      <CommonForm
        form={form}
        layout="vertical"
        name="change-password"
        requiredMark={false}
        onFinish={onFinish}
        onValuesChange={onValuesChange}
        scrollToFirstError={{ behavior: "smooth" }}
      >
        <Row gutter={[16, 24]}>
          <Col span={12}>
            <CommonFormItem
              validateTrigger={["onChange", "onBlur"]}
              name="newPassword"
              label={t("profilePage.label.newPassword")}
              rules={[
                {
                  required: true,
                  message: `${t("validate.input")} ${t("profilePage.label.newPassword")}.`,
                },
              ]}
            >
              <Input.Password
                allowClear
                placeholder={t("profilePage.placeholder.newPassword") as string}
              />
            </CommonFormItem>
            <CommonFormItem
              style={{ marginBottom: 0 }}
              validateTrigger={["onChange", "onBlur"]}
              name="confirmPassword"
              label={t("profilePage.label.reNewPassword")}
              rules={[
                {
                  required: true,
                  message: t("profilePage.validate.reNewPassword") as string,
                },
                ({ getFieldValue }: any) => ({
                  validator(_: any, value: any) {
                    if (!value) {
                      setRepeatError(false);
                      return Promise.reject();
                    }
                    if (getFieldValue("newPassword") !== value) {
                      setRepeatError(true);
                      return Promise.reject();
                    }
                    setRepeatError(false);
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input.Password
                allowClear
                placeholder={t("profilePage.placeholder.reNewPassword") as string}
              />
            </CommonFormItem>
            {repeatError ? (
              <p className="password-error">{t("profilePage.validate.mismatched")}</p>
            ) : (
              <></>
            )}
          </Col>
          <Col
            span={12}
            style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          >
            <div className="change-password-condition">
              <div className="change-password-condition-title">
                {t("profilePage.label.requirementNewPassword")}
              </div>
              <List
                split={false}
                dataSource={ListItems}
                renderItem={(item, index) => (
                  <List.Item
                    className={
                      activeIndexes.includes(index)
                        ? "password-condition-active"
                        : newPassword
                        ? "password-condition-error"
                        : ""
                    }
                    style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}
                  >
                    {newPassword && !activeIndexes.includes(index) ? (
                      <>
                        <Error />
                        {item}
                      </>
                    ) : (
                      <>
                        <Checkbox />
                        {item}
                      </>
                    )}
                  </List.Item>
                )}
              />
            </div>
          </Col>
        </Row>
      </CommonForm>
    </Modal>
  );
}

export default ChangePassword;
