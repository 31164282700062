import { Col, Form, Popover, Row, Space, notification } from "antd";
import { saveAs } from "file-saver";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CommonButton from "../../../../components/Common/Button";
import CommonForm from "../../../../components/Common/Form";
import CommonFormItem from "../../../../components/Common/FormItem";
import CommonTable from "../../../../components/Common/Table";
import MapComponent from "../../../../components/Map";
import { ReactComponent as BackIcon } from "../../../../resources/images/back_step-icon.svg";
import { ReactComponent as NextIcon } from "../../../../resources/images/next_step-icon.svg";
import { ReactComponent as PauseIcon } from "../../../../resources/images/pause-icon.svg";
import { ReactComponent as ContinueIcon } from "../../../../resources/images/play-icon.svg";
import { ReactComponent as ResetIcon } from "../../../../resources/images/reset_icon.svg";
import { ReactComponent as ExclamationIcon } from "../../../../resources/images/exclamation_mark.svg";
import journeyServices from "../../../../services/journeyManagement.service";
import { DATE_TIME_FORMAT_SECOND, ROLE_LIST, SEV_CODE } from "../../../../utils/constans";
import {
  ACTION_TYPE_OPTIONS,
  JOURNEY_STATUS_VALUES,
} from "../../../../utils/constans/journeyManagement";
import { SIDEBAR_ITEM_HREF } from "../../../../utils/constans/sidebar";
import { useQuery } from "../../../../utils/customHooks";
import { ICommonResponsePaging, IUseQueryResponse } from "../../../../utils/types";
import {
  IActionAndVioDetailData,
  IJourneyDetailData,
} from "../../../../utils/types/journeyManagement";
import { buildQueryString, isHavePermission } from "../../../../utils/utilFunctions";
import PlayBackControl from "./PlayBackControl";
import RealTimeControl from "./RealTimeControl";
import VioControl from "./VioControl";
import { Popup } from "react-leaflet";

function HistoryTab({ detailData }: { detailData: IJourneyDetailData }) {
  const componentPath = SIDEBAR_ITEM_HREF.journey_management;
  const navigate = useNavigate();
  const [formHistory] = Form.useForm();
  const { t } = useTranslation();
  const [isPlay, setIsPlay] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingExport, setIsLoadingExport] = useState<boolean>(false);
  const [currentVioSelected, setCurrentVioSelected] = useState<IActionAndVioDetailData>();
  const [listData, setListData] = useState<ICommonResponsePaging<IActionAndVioDetailData>>();
  const queryObj: IUseQueryResponse = useQuery();
  const { params = {}, search } = queryObj;
  const {
    tabKey: tabKeyQuery,
    sortBy: sortByQuery,
    sortType: sortTypeQuery,
    search: searchQuery,
  } = params;
  const searchQueryData = searchQuery ? JSON.parse(searchQuery) : {};

  const { profile } = useSelector((state: any) => state?.profileReducer);
  const isCanExport = isHavePermission(ROLE_LIST.QLGT_EXPORT_HISTORY_ACTION, profile);

  useEffect(() => {
    if (
      detailData?.status === JOURNEY_STATUS_VALUES.GIAO_THANH_CONG ||
      detailData?.status === JOURNEY_STATUS_VALUES.HUY_GIAO_HANG
    ) {
      const increaseTime = () => {
        if (isPlay) {
          setCurrentTime((prev) => prev + 3);
        }
      };
      increaseTime();
      const interval = setInterval(increaseTime, 5000);
      return () => clearInterval(interval);
    }
  }, [detailData?.status, isPlay]);

  const getData = useCallback(
    async (reload?: boolean) => {
      setIsLoading(true);
      const paramsSearch = {
        page: 0,
        size: 10000,
        sortBy: sortByQuery,
        sortType: sortTypeQuery,
        search: JSON.stringify({
          ...searchQueryData,
          registerCarId: detailData?.id,
          source: profile?.source,
        }),
      };
      const resp = await journeyServices.getListHistoryJourney(paramsSearch);
      const data = resp?.data;
      if (resp?.status === 200) {
        setListData(data?.data);
      } else {
        setListData(undefined);
        notification.error({
          message: data?.message || t("commonError.oopsSystem"),
        });
      }
      setIsLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sortByQuery, sortTypeQuery, t, search, detailData?.id, profile?.source],
  );

  useEffect(() => {
    if (tabKeyQuery !== "history-tab") {
      formHistory.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabKeyQuery]);

  useEffect(() => {
    if (tabKeyQuery === "history-tab") {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getData]);

  const columns = [
    {
      title: t("journeyManagementPage.columns.STT"),
      dataIndex: "index",
      key: "index",
      width: "10%",
      align: "center",
      render: (value: any, record: IActionAndVioDetailData, index: number) => index + 1,
    },
    {
      title: t("journeyManagementPage.columns.time"),
      dataIndex: "createdDate",
      key: "createdDate",
      render: (value: any, record: IActionAndVioDetailData) => {
        return value ? (
          <div className="link" onClick={() => setCurrentVioSelected(record)}>
            {moment(value).format(DATE_TIME_FORMAT_SECOND)}
          </div>
        ) : (
          "--"
        );
      },
    },
    {
      title: t("journeyManagementPage.columns.typeVio"),
      dataIndex: "type",
      key: "type",
      render: (value: any, record: IActionAndVioDetailData) => {
        const curType = ACTION_TYPE_OPTIONS?.find((x) => x.value === value);
        return curType ? <div style={record.type === 1 ? { color: "red" } : {}}>{t(curType?.label)}</div> : "--";
      },
    },
    {
      title: t("journeyManagementPage.columns.description"),
      dataIndex: "description",
      key: "description",
      render: (value: any, record: IActionAndVioDetailData) => {
        if (record.description) {
          return <div style={record.type === 1 ? { color: "red" } : {}}>{value}</div>
        }
        return "--";
      },
    },
    {
      title: t("journeyManagementPage.columns.position"),
      key: "addressStr",
      render: (value: any, record: IActionAndVioDetailData) => {
        return record.addressStr.replace("- null", "") || '--'
      },
    },
    {
      title: t("journeyManagementPage.columns.user"),
      dataIndex: "user",
      key: "user",
      render: (value: any, record: IActionAndVioDetailData) => {
        return (
          <>
            <div style={record.type === 1 ? { color: "red" } : {}} >{value?.username || "--"}</div>
            {value?.isDelete ? <div style={{ color: "red" }}>({t("common.deleted")})</div> : <></>}
          </>
        );
      },
    },
    {
      title: t("journeyManagementPage.label.reason"),
      dataIndex: "reason",
      key: "reason",
      width: "10%",
      align: "center",
      render: (value: any, record: IActionAndVioDetailData) => {
        return (
          record.reason === null
            ? <></>
            : <Popover
              title={t("journeyManagementPage.label.reason")}
              content={<div style={{ maxWidth: '300px', wordWrap: 'break-word' }}>
                {record.reason}
              </div>}>
              <ExclamationIcon
                style={{
                  cursor: 'pointer',
                  width: '2.5rem',
                  height: '2.5rem'
                }}
              />
            </Popover>
        );
      },
    },
  ];

  const onFinish = (values: any) => {
    onSearch(values);
  };

  const onSearch = (data: any = {}) => {
    const dataSearch = { ...data };
    if (data?.createdDate?.length) {
      dataSearch["fromCreatedDateLong"] = moment(data?.createdDate?.[0]).startOf("day").valueOf();
      dataSearch["toCreatedDateLong"] = moment(data?.createdDate?.[1]).endOf("day").valueOf();
    }
    delete dataSearch?.createdDate;
    let queryString = buildQueryString({
      tabKey: tabKeyQuery,
      search: JSON.stringify(dataSearch),
    });

    // detect if query not change => call api
    if (queryString !== search) {
      navigate(`${componentPath}/detail/${detailData?.id}${queryString}`);
    } else {
      getData();
    }
  };

  const onClear = (clearParams = {}) => {
    onSearch({ ...clearParams });
    setTimeout(() => {
      formHistory.resetFields();
    }, 100);
  };

  // xuất excel
  const handleExport = async () => {
    if (!isLoadingExport) {
      setIsLoadingExport(true);
      const paramsSearch = {
        page: 0,
        size: 10000,
        sortBy: sortByQuery,
        sortType: sortTypeQuery,
        search: JSON.stringify({
          ...searchQueryData,
          registerCarId: detailData?.id,
          source: profile?.source,
        }),
      };
      const resp = await journeyServices.exportHistoryVio(paramsSearch);
      const data = resp?.data;
      if (resp?.status === 200) {
        const fileName = `ACtionAndViolation_Data_Export_${moment().format(
          "YYYYMMDD",
        )}_${moment().unix()}.xlsx`;
        saveAs(data, fileName);
      } else {
        notification.error({
          message: data?.message || t("commonError.oopsSystem"),
        });
      }
      setIsLoadingExport(false);
    }
  };

  return (
    <div className="journey-history">
      {(detailData?.status === JOURNEY_STATUS_VALUES.GIAO_THANH_CONG ||
        detailData?.status === JOURNEY_STATUS_VALUES.HUY_GIAO_HANG) && (
          <div className="journey-list-icons">
            <div
              className={`jounrney-icon ${isPlay ? "disabled-icon" : ""}`}
              onClick={() => {
                setCurrentTime((prev) => (prev - 3 > 0 ? prev - 3 : 0));
              }}
            >
              <BackIcon />
            </div>
            {isPlay ? (
              <div
                className="jounrney-icon"
                onClick={() => {
                  setIsPlay(false);
                }}
              >
                <PauseIcon />
              </div>
            ) : (
              <div
                className="jounrney-icon"
                onClick={() => {
                  setIsPlay(true);
                }}
              >
                <ContinueIcon />
              </div>
            )}
            <div
              className={`jounrney-icon ${isPlay ? "disabled-icon" : ""}`}
              onClick={() => {
                setCurrentTime((prev) => prev + 3);
              }}
            >
              <NextIcon />
            </div>
            <div
              className={`mgl-10 jounrney-icon ${isPlay ? "disabled-icon" : ""}`}
              onClick={() => {
                setCurrentTime(0);
                setIsPlay(true);
              }}
            >
              <ResetIcon />
            </div>
          </div>
        )}
      <MapComponent isShowCurrentBtn={false}>
        <VioControl
          listData={listData?.content || []}
          currentVioSelected={currentVioSelected}
          setCurrentVioSelected={setCurrentVioSelected}
        />
        {detailData?.status === JOURNEY_STATUS_VALUES.GIAO_THANH_CONG ||
          detailData?.status === JOURNEY_STATUS_VALUES.HUY_GIAO_HANG ? (
          <PlayBackControl
            detailData={detailData}
            tabKey={tabKeyQuery}
            setIsPlay={setIsPlay}
            currentTime={currentTime}
          />
        ) : (
          <RealTimeControl detailData={detailData} tabKey={tabKeyQuery} />
        )}
      </MapComponent>
      {isCanExport && (
        <div className="btn-export">
          <CommonButton
            loading={isLoadingExport}
            btnType="success"
            onClick={handleExport}
            size={"small"}
            htmlType="button"
          >
            {t("common.button.exportExcel")}
          </CommonButton>
        </div>
      )}
      <div className="journey-history-detail">
        <div className="info-left">
          <CommonForm
            form={formHistory}
            onFinish={onFinish}
            layout="vertical"
            initialValues={{
              type: searchQueryData?.type,
              createdDate:
                searchQueryData?.fromcreatedDateLong && searchQueryData?.toCreatedDateLong
                  ? [
                    moment(searchQueryData?.fromcreatedDateLong),
                    moment(searchQueryData?.toCreatedDateLong),
                  ]
                  : [],
            }}
          >
            <Row gutter={30}>
              <Col span={24}>
                <CommonFormItem
                  name="type"
                  label={t("journeyManagementPage.label.typeVio")}
                  placeholder={t("journeyManagementPage.placeholder.typeVio") as string}
                  type="select"
                  options={ACTION_TYPE_OPTIONS?.map((x) => ({ ...x, label: t(x.label) }))}
                />
              </Col>
              <Col span={24}>
                <CommonFormItem
                  name="createdDate"
                  type="rangePicker"
                  label={t("journeyManagementPage.label.time")}
                  placeholder={[t("common.fromDate"), t("common.toDate")]}
                />
              </Col>
            </Row>

            <Space className="form-btn-container">
              <CommonButton btnType="default" size={"small"} onClick={() => onClear()}>
                {t("common.button.deleteCondition")}
              </CommonButton>
              <CommonButton btnType="primary" size={"small"} onClick={() => formHistory.submit()}>
                {t("common.button.search")}
              </CommonButton>
            </Space>
          </CommonForm>
        </div>
        <div className="info-right">
          <CommonTable
            isLoading={isLoading}
            rowKey={"id"}
            dataSource={listData?.content || []}
            columns={columns}
            data={listData}
            pagination={false}
            scroll={{ y: "20.833vw" }}
          />
        </div>
      </div>
    </div>
  );
}

export default HistoryTab;
