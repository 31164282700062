import { Layout, Space } from "antd";
import { ItemType } from "antd/es/breadcrumb/Breadcrumb";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as BeadcrumbIcon } from "../../resources/images/breadcrumb-icon.svg";
import { ReactComponent as VenderManagementIcon } from "../../resources/images/building_icon.svg";
import { ReactComponent as HomeIcon } from "../../resources/images/home.svg";
import { ReactComponent as LocationCategoryIcon } from "../../resources/images/location-dot_icon.svg";
import { ReactComponent as LockManagementIcon } from "../../resources/images/lock_icon.svg";
import { ReactComponent as Logo } from "../../resources/images/logo.svg";
import { ReactComponent as OperateIcon } from "../../resources/images/operate_icon.svg";
import { ReactComponent as JourneyManagementIcon } from "../../resources/images/road_icon.svg";
import { ReactComponent as PermissionIcon } from "../../resources/images/shield_icon.svg";
import { ReactComponent as VehicleCategoryIcon } from "../../resources/images/truck_icon.svg";
import { ReactComponent as AccountManagementIcon } from "../../resources/images/user.svg";
import { ReactComponent as ProfileIcon } from "../../resources/images/user_settings.svg";
import { ROLE_LIST, SEV_CODE } from "../../utils/constans";
import { BREADCRUMB_ITEM, SIDEBAR_ITEM_HREF, SIDEBAR_ITEM_KEY } from "../../utils/constans/sidebar";
import {
  IBreadcrumbItemType,
  IConfigSideBar,
  ILayoutProps,
  ISidebarItemType,
} from "../../utils/types/layout";
import { isHavePermission } from "../../utils/utilFunctions";
import CommonBreacrumb from "../Common/Breacrumb";
import Language from "../Language";
import HeaderProfile from "./HeaderProfile";
import Sidebar from "./Sidebar";

const { Header, Content } = Layout;

function SystemLayout(props: ILayoutProps) {
  const location = useLocation();
  const { t } = useTranslation();
  const { profile } = useSelector((state: any) => state?.profileReducer);
  const isCanViewListJourney = isHavePermission(ROLE_LIST.QLHT_VIEW, profile);
  const isCanViewListLock = isHavePermission(ROLE_LIST.MANAGER_LOCK_VIEW_LIST, profile);
  const isCanViewListVendor = isHavePermission(ROLE_LIST.MANAGER_VENDOR_VIEW_LIST, profile);
  const isCanViewListAccount = isHavePermission(ROLE_LIST.MANAGER_ACCOUNT_VIEW_LIST, profile);
  const isCanViewListLocation = isHavePermission(ROLE_LIST.MANAGER_POSITION_VIEW_LIST, profile);
  const isCanViewListVehicle = isHavePermission(ROLE_LIST.MANAGER_CAR_VIEW_LIST, profile);
  const isCanViewListGroup = isHavePermission(ROLE_LIST.MANAGER_GROUP_VIEW_LIST, profile);
  const isCanViewListDriver = isHavePermission(ROLE_LIST.MANAGER_DRIVER_VIEW_LIST, profile);

  const items: ISidebarItemType[] = useMemo(() => {
    const newItems: ISidebarItemType[] = [
      {
        label: (
          <Link to={SIDEBAR_ITEM_HREF.home}>{t(BREADCRUMB_ITEM[SIDEBAR_ITEM_KEY.home].title)}</Link>
        ),
        key: SIDEBAR_ITEM_KEY.home,
        icon: (
          <Link to={SIDEBAR_ITEM_HREF.home}>
            <div className="sidebar-icon">
              <HomeIcon />
            </div>
          </Link>
        ),
      },
      // Tạm ẩn màn vận hành
      // {
      //   label: (
      //     <Link to={SIDEBAR_ITEM_HREF.operate}>
      //       {t(BREADCRUMB_ITEM[SIDEBAR_ITEM_KEY.operate].title)}
      //     </Link>
      //   ),
      //   key: SIDEBAR_ITEM_KEY.operate,
      //   icon: (
      //     <Link to={SIDEBAR_ITEM_HREF.operate}>
      //       <div className="sidebar-icon">
      //         <OperateIcon />
      //       </div>
      //     </Link>
      //   ),
      //   hidden: !isCanViewListJourney,
      // },
      {
        label: (
          <Link to={SIDEBAR_ITEM_HREF.operate_list}>
            {t(BREADCRUMB_ITEM[SIDEBAR_ITEM_KEY.operate_list].title)}
          </Link>
        ),
        key: SIDEBAR_ITEM_KEY.operate_list,
        icon: (
          <Link to={SIDEBAR_ITEM_HREF.operate_list}>
            <div className="sidebar-icon">
              <OperateIcon />
            </div>
          </Link>
        ),
        hidden: !isCanViewListJourney,
      },
      {
        label: (
          <Link to={SIDEBAR_ITEM_HREF.journey_management}>
            {t(BREADCRUMB_ITEM[SIDEBAR_ITEM_KEY.journey_management].title)}
          </Link>
        ),
        key: SIDEBAR_ITEM_KEY.journey_management,
        icon: (
          <Link to={SIDEBAR_ITEM_HREF.journey_management}>
            <div className="sidebar-icon">
              <JourneyManagementIcon />
            </div>
          </Link>
        ),
        hidden: !isCanViewListJourney,
      },
      {
        label: (
          <Link to={SIDEBAR_ITEM_HREF.lock_management}>
            {t(BREADCRUMB_ITEM[SIDEBAR_ITEM_KEY.lock_management].title)}
          </Link>
        ),
        key: SIDEBAR_ITEM_KEY.lock_management,
        icon: (
          <Link to={SIDEBAR_ITEM_HREF.lock_management}>
            <div className="sidebar-icon">
              <LockManagementIcon />
            </div>
          </Link>
        ),
        hidden: !isCanViewListLock,
      },
      // Ẩn hiển thị vendor
      // {
      //   label: (
      //     <Link to={SIDEBAR_ITEM_HREF.vendor_management}>
      //       {t(BREADCRUMB_ITEM[SIDEBAR_ITEM_KEY.vendor_management].title)}
      //     </Link>
      //   ),
      //   key: SIDEBAR_ITEM_KEY.vendor_management,
      //   icon: (
      //     <Link to={SIDEBAR_ITEM_HREF.vendor_management}>
      //       <div className="sidebar-icon">
      //         <VenderManagementIcon />
      //       </div>
      //     </Link>
      //   ),
      //   hidden: profile?.source !== SEV_CODE || !isCanViewListVendor,
      // },
      {
        label: (
          <Link to={SIDEBAR_ITEM_HREF.account_management}>
            {t(BREADCRUMB_ITEM[SIDEBAR_ITEM_KEY.account_management].title)}
          </Link>
        ),
        key: SIDEBAR_ITEM_KEY.account_management,
        icon: (
          <Link to={SIDEBAR_ITEM_HREF.account_management}>
            <div className="sidebar-icon">
              <AccountManagementIcon />
            </div>
          </Link>
        ),
        hidden: !isCanViewListAccount,
      },
      {
        label: (
          <Link to={SIDEBAR_ITEM_HREF.group_management}>
            {t(BREADCRUMB_ITEM[SIDEBAR_ITEM_KEY.group_management].title)}
          </Link>
        ),
        key: SIDEBAR_ITEM_KEY.group_management,
        icon: (
          <Link to={SIDEBAR_ITEM_HREF.group_management}>
            <div className="sidebar-icon">
              <PermissionIcon />
            </div>
          </Link>
        ),
        hidden: profile?.source !== SEV_CODE || !isCanViewListGroup,
      },
      {
        label: (<Link to={SIDEBAR_ITEM_HREF.driver_category}>
          {t(BREADCRUMB_ITEM[SIDEBAR_ITEM_KEY.driver_category].title)}
        </Link>),
        key: SIDEBAR_ITEM_KEY.driver_category,
        icon: (
          <Link to={SIDEBAR_ITEM_HREF.vehicle_category}>
            <div className="sidebar-icon">
              <VehicleCategoryIcon />
            </div>
          </Link>
        ),
        hidden: profile?.source !== SEV_CODE || !isCanViewListDriver
      },
      {
        label: (
          <Link to={SIDEBAR_ITEM_HREF.location_category}>
            {t(BREADCRUMB_ITEM[SIDEBAR_ITEM_KEY.location_category].title)}
          </Link>
        ),
        key: SIDEBAR_ITEM_KEY.location_category,
        icon: (
          <Link to={SIDEBAR_ITEM_HREF.location_category}>
            <div className="sidebar-icon">
              <LocationCategoryIcon />
            </div>
          </Link>
        ),
        hidden: profile?.source !== SEV_CODE || !isCanViewListLocation,
      },
      // Ẩn module quản lý loại xe
      // {
      //   label: (
      //     <Link to={SIDEBAR_ITEM_HREF.vehicle_category}>
      //       {t(BREADCRUMB_ITEM[SIDEBAR_ITEM_KEY.vehicle_category].title)}
      //     </Link>
      //   ),
      //   key: SIDEBAR_ITEM_KEY.vehicle_category,
      //   icon: (
      //     <Link to={SIDEBAR_ITEM_HREF.vehicle_category}>
      //       <div className="sidebar-icon">
      //         <VehicleCategoryIcon />
      //       </div>
      //     </Link>
      //   ),
      //   hidden: profile?.source !== SEV_CODE || !isCanViewListVehicle,
      // },
      // {
      //   label: (
      //     <Link to={SIDEBAR_ITEM_HREF.vehicle_category}>
      //       {t(BREADCRUMB_ITEM[SIDEBAR_ITEM_KEY.profile].title)}
      //     </Link>
      //   ),
      //   key: SIDEBAR_ITEM_KEY.profile,
      //   icon: (
      //     <div className="sidebar-icon">
      //       <ProfileIcon />
      //     </div>
      //   ),
      //   hidden: true,
      // },
    ];
    return newItems?.filter((item: ISidebarItemType) => item);
  }, [
    t,
    profile?.source,
    isCanViewListJourney,
    isCanViewListLock,
    isCanViewListVendor,
    isCanViewListAccount,
    isCanViewListGroup,
    isCanViewListLocation,
    isCanViewListVehicle,
    isCanViewListDriver
  ]);

  const config: IConfigSideBar = useMemo(() => {
    const pathname = location?.pathname;
    const splitPathname = pathname.split(SIDEBAR_ITEM_HREF.home);
    const selectedKeys = splitPathname?.[1] || SIDEBAR_ITEM_KEY.home; // items được chọn
    let openKeys = ""; // items được mở
    let arrMenus = []; // danh sách menu sidebar
    let breadcrumbItems: ItemType[] = [
      {
        href: SIDEBAR_ITEM_HREF.home,
        title: t(BREADCRUMB_ITEM[SIDEBAR_ITEM_KEY.home].title),
      },
    ];

    for (let i = 0; i < items.length; i++) {
      if (Array.isArray(items[i]?.children)) {
        let newChild: ISidebarItemType[] = [];
        items[i]?.children?.map((child: ISidebarItemType) => {
          if (!child.hidden) {
            newChild.push(child);
          }
          return true;
        });
        if (newChild.length > 0) {
          arrMenus.push({
            ...items[i],
            children: newChild,
          });
        }
      } else {
        if (!items[i].hidden) {
          arrMenus.push({
            ...items[i],
          });
        }
      }

      if (items[i].key !== selectedKeys && Array.isArray(items[i]?.children)) {
        const child = items[i]?.children?.find((x: ISidebarItemType) => x.key === selectedKeys);
        if (child) {
          openKeys = items[i].key;
        }
      }
    }

    const currentBreadcumb: IBreadcrumbItemType | any = BREADCRUMB_ITEM?.[selectedKeys];
    if (currentBreadcumb) {
      if (splitPathname?.length > 2 && currentBreadcumb?.[splitPathname[2]]) {
        breadcrumbItems.push({
          title: t(currentBreadcumb.title) as string,
          href: currentBreadcumb.href,
        });
        breadcrumbItems.push({
          ...currentBreadcumb?.[splitPathname[2]],
          title: t(currentBreadcumb?.[splitPathname[2]].title) as string,
        });
      } else {
        breadcrumbItems.push({ title: t(currentBreadcumb.title) });
      }
    }

    return {
      selectedKeys,
      openKeys,
      arrMenus,
      breadcrumbItems,
    };
  }, [items, location?.pathname, t]);

  return (
    <Layout className={"fixed-sidebar full-height-layout"} style={{ minHeight: "100vh" }}>
      <Sidebar config={config} />
      <Layout className="main-layout fixed-header">
        <Header>
          <div className="main-header">
            <div className="main-header-left">
              <div className="page-logo">
                <Logo />
              </div>
              <div className="page-title">{t("layout.header.title")}</div>
            </div>
            <Space
              size={20}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                height: "100%",
              }}
            >
              <Language />
              <div className="main-header-user">
                <div className="main-header-user-name">{profile?.name}</div>
                <div className="main-header-user-position">{t("layout.header.securityStaff")}</div>
              </div>

              <HeaderProfile />
            </Space>
          </div>
        </Header>
        {config?.selectedKeys !== SIDEBAR_ITEM_KEY.home && (
          <CommonBreacrumb items={config?.breadcrumbItems} separator={<BeadcrumbIcon />} />
        )}

        <Content>{props.children}</Content>
      </Layout>
    </Layout>
  );
}

export default SystemLayout;
