import { Button } from "antd";
import L, { LatLngExpression } from "leaflet";
import Geocoder from "leaflet-control-geocoder";
import "leaflet-control-geocoder/dist/Control.Geocoder.css";
import { MarkGeocodeEvent } from "leaflet-control-geocoder/dist/control";
import { useEffect, useRef, useState } from "react";
import { CircleMarker, ZoomControl, useMap } from "react-leaflet";
import Control from "react-leaflet-custom-control";
import { leafIcon } from ".";
import { ReactComponent as LocationIcon } from "../../resources/images/location.svg";
import marker from "../../resources/images/marker/marker-icon.png";
import { SAMSUNG_LOCATION } from "../../utils/constans";
import { IMapComponent } from "../../utils/types";

const MapControls = ({ isShowSearch = false, isShowCurrentBtn = true }: IMapComponent) => {
  const map = useMap();
  const samsungLocation = useRef<LatLngExpression>(SAMSUNG_LOCATION as LatLngExpression);
  const circlePosition = useRef<any>(null);
  const [isFly, setIsFly] = useState<boolean>(true);
  const markerSearch = useRef<any>(null);
  const iconMarker = leafIcon(marker);

  // render marker search
  useEffect(() => {
    if (isShowSearch) {
      const GeocoderControl = new Geocoder();
      GeocoderControl.addTo(map);
      GeocoderControl.on("markgeocode", function (e: MarkGeocodeEvent) {
        var latlng = e.geocode.center;
        if (markerSearch.current) {
          map.removeLayer(markerSearch.current);
        }
        markerSearch.current = L.marker(latlng, {
          icon: iconMarker,
          alt: "Marker alt",
          title: e.geocode.name,
        })
          .addTo(map)
          .bindTooltip("click to edit", { permanent: false, direction: "bottom" })
          .bindPopup(e.geocode.name, { offset: [0, -25] })
          .openPopup();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iconMarker, map]);

  // fly đến vị trí hiện tại samsung
  useEffect(() => {
    if (isFly) {
      map.flyTo(samsungLocation.current, 16);
    }
    setIsFly(false);
  }, [isFly, map, samsungLocation]);
  // render vị trí hiện tại
  const handleClick = () => {
    if (isShowCurrentBtn) {
      map.locate().on("locationfound", function (e: L.LocationEvent) {
        // setMyLocation(e.latlng);
        if (circlePosition.current) {
          map.removeLayer(circlePosition.current);
        }
        circlePosition.current = L.circle(samsungLocation.current, 500, {
          // Number is in Meters
          color: "#136aec",
          fillOpacity: 0.2,
          fillColor: "#136aec",
          opacity: 1,
          weight: 0,
        }).addTo(map);
      });
    }
  };
  //cập nhật lại vị trí hiện tại
  useEffect(() => {
    if (isShowCurrentBtn) {
      const updateMyLocation = setInterval(
        (function check() {
          handleClick();
          return check;
        })(),
        10000,
      );

      return () => clearInterval(updateMyLocation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Control prepend position="topright">
        <ZoomControl position="topright" />
        {isShowCurrentBtn ? (
          <Button
            onClick={() => {
              handleClick();
              setIsFly(true);
            }}
            htmlType="button"
          >
            <LocationIcon />
          </Button>
        ) : (
          <></>
        )}
      </Control>
      {samsungLocation && isShowCurrentBtn ? (
        <CircleMarker
          center={samsungLocation.current}
          radius={9}
          color="white"
          fillColor="#2A93EE"
          fillOpacity={1}
          opacity={1}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default MapControls;
