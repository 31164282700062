// table drop row
import { MenuOutlined } from "@ant-design/icons";
import type { DragEndEvent } from "@dnd-kit/core";
import { DndContext } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
// table drop row

import { Col, notification, Row } from "antd";
import type { ColumnsType } from "antd/es/table";
import moment from "moment";
import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CommonButton from "../../../../components/Common/Button";
import CommonConfirmModal from "../../../../components/Common/ConfirmModal";
import CommonFormItem from "../../../../components/Common/FormItem";
import CommonSelect from "../../../../components/Common/Select";
import CommonTable from "../../../../components/Common/Table";
import { ReactComponent as DeleteIcon } from "../../../../resources/images/delete-red.svg";
import { ReactComponent as AddIcon } from "../../../../resources/images/plus-blue.svg";
import journeyServices from "../../../../services/journeyManagement.service";
import positionServices from "../../../../services/locationCategory.service";
import { DATE_TIME_FORMAT_SECOND, ROLE_LIST } from "../../../../utils/constans";
import { JOURNEY_STATUS_VALUES } from "../../../../utils/constans/journeyManagement";
import { LOCATION_VALUES } from "../../../../utils/constans/locationCategory";
import {
  ICompanyDeliverySites,
  ICompanyDeliverySitesAddData,
  IJourneyTabProps,
  IArea
} from "../../../../utils/types/journeyManagement";
import { ILocationDetailData } from "../../../../utils/types/locationCategory";
import { isHavePermission } from "../../../../utils/utilFunctions";
// Thêm context
import DataUpdateContext from "../context";
// Dragable
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  "data-row-key": string;
}

interface Gate {
  gateIn: { id: number | null, positionId: number | null }
  gateOut: { id: number | null, positionId: number | null }
}
// </>
interface FactoryArea {
  id: number
  rFAId: number
  name: string
  dateCheckin: number | null
  dateCheckout: number | null
}
// 

function InfoTab({ detailData, form, isEditing }: IJourneyTabProps) {
  const { t } = useTranslation();
  const [checkDuplicate, setCheckDuplicate] = useState<string[]>([]);
  const [positionSelectData, setPositionSelectData] = useState<ILocationDetailData[]>([]);
  const [itemDeleteId, setItemDeleteId] = useState<number | undefined>(undefined);
  const [typeVio, setTypeVio] = useState<number | undefined>(undefined);
  const [visible, setVisible] = useState<boolean>(false);
  const [visibleUnlockConfirm, setVisibleUnlockConfirm] = useState<boolean>(false);
  const [loadingConfirmUnlock, setLoadingConfirmUnlock] = useState<boolean>(false);
  const [positionIdUnlock, setPositionIdUnlock] = useState<number | undefined>(undefined);
  const [lockIdUnlock, setLockIdUnlock] = useState<number | undefined>(undefined);
  const [dataSource, setDataSource] = useState<ICompanyDeliverySitesAddData[]>([]);
  const [rowsDisableSort, setRowsDisableSort] = useState<any[]>([]);
  const [isLoadingUnlock, setIsLoadingUnlock] = useState<boolean>(false);
  const [isLoadingCreate, setIsLoadingCreate] = useState<boolean>(false);
  const { profile } = useSelector((state: any) => state?.profileReducer);
  const isCanEditDeli = isHavePermission(ROLE_LIST.QLHT_EDIT_POSITION, profile);
  // Thêm danh sách areaOrder
  const [areaOrderTab, setAreaOrderTab] = useState<Number>(0);
  // const [listAreaOrder, setListAreaOrder] = useState<string[]>([]);
  // const [gates, setGates] = useState<{ [key: string]: Gate }>({})
  // const [listCDS, setListCDS] = useState<{ [key: string]: ICompanyDeliverySitesAddData[] }>({})
  // Danh sách khu vực
  const [listArea, setListArea] = useState<IArea[]>([])
  // Sử dụng context lưu trữ dữ liệu
  const dataUpdateContext = useContext(DataUpdateContext)
  // Thêm dữ liệu FactoryArea
  const [listFactoryArea, setListFactoryArea] = useState<FactoryArea[]>([])
  const [listCDSFactory, setListCDSFactory] = useState<{ [key: string]: ICompanyDeliverySitesAddData[] }>({})
  const [gatesFactory, setGatesFactory] = useState<{ [key: string]: Gate }>({})
  const [currentArea, setCurrentArea] = useState<FactoryArea>({
    id: 0,
    rFAId: 0,
    name: "",
    dateCheckin: null,
    dateCheckout: null
  })
  const [isCantChange, setIsCantChange] = useState<boolean>(false)
  // Check khu hiện tại
  const [currentAreaCheck, setCurrentAreaCheck] = useState<{ name: string, action: string }>({
    name: '',
    action: ''
  })
  const [nameAreaFocus, setNameAreaFocus] = useState<string>('')
  // Ẩn tab tránh trường hợp vị trí giao nhận hàng trống nên bị đè
  const [isHidden, setIsHidden] = useState<boolean>(false)

  const getData = async () => {
    const paramsSearch = {
      page: 0,
      size: 1000,
      search: JSON.stringify({}),
    };

    //select vị trí
    const respPosition = await positionServices.getPosition(paramsSearch);
    const dataPosition = respPosition?.data;
    if (respPosition?.status === 200) {
      setPositionSelectData(dataPosition?.data?.content);
    } else {
      setPositionSelectData([]);
    }
  };

  const getDataArea = async () => {
    const res = await journeyServices.getAllArea()
    if (res.status === 200) {
      setListArea([...res.data.data])
    } else {
      setListArea([])
    }
  }

  useEffect(() => {
    getData();
    getDataArea();

    setListFactoryArea([])
    setGatesFactory({})
    let tempCDS: { [key: string]: ICompanyDeliverySitesAddData[] } = {}
    let isMoveDelay = false
    let isFocusDelay = false
    if (detailData.moveDelaySeconds !== null) {
      isMoveDelay = true
    }
    detailData.registerFactoryArea.map((value, index) => {
      const temp = {
        id: value.factoryArea.id,
        rFAId: value.id,
        name: `${value.factoryArea.name}/${index}`,
        dateCheckin: value.dateCheckin,
        dateCheckout: value.dateCheckout
      }
      if (!listFactoryArea.some(item =>
        item.id === temp.id &&
        item.rFAId === temp.rFAId &&
        item.name === temp.name &&
        item.dateCheckin === temp.dateCheckin &&
        item.dateCheckout === temp.dateCheckout
      )) {
        listFactoryArea.push(temp)
      }
      value.companyDeliverySites.map((v, i) => {
        if (gatesFactory[temp.name] === undefined) {
          gatesFactory[temp.name] = {
            gateIn: {
              id: 0,
              positionId: null
            },
            gateOut: {
              id: 0,
              positionId: null
            }
          }
        }
        if (tempCDS[temp.name] === undefined) {
          listCDSFactory[temp.name] = []
          tempCDS[temp.name] = []
        }
        if (v.orderNumber === 0) {
          gatesFactory[temp.name].gateIn.id = v.id as number
          gatesFactory[temp.name].gateIn.positionId = v.categoryPosition.id as number
        } else if (v.orderNumber === 1000) {
          gatesFactory[temp.name].gateOut.id = v.id as number
          gatesFactory[temp.name].gateOut.positionId = v.categoryPosition.id as number
        } else {
          listCDSFactory[temp.name].push({
            "cdsId": v.id!,
            "id": v.categoryPosition?.id!,
            "key": (v.orderNumber!).toString() as string,
            "index": v.orderNumber!,
            "name": v.categoryPosition?.name as string,
            "unlockTime": Number(v.unlockTime),
            "lockTime": Number(v.lockTime),
            "receiptNumber": Number(v.receiptNumber),
            "receiptUpdateTime": v.receiptUpdateTime as string,
            "disabled": v.unlockTime !== null
          })
        }
      })
      listCDSFactory[temp.name] = listCDSFactory[temp.name].sort((a, b) => a.index - b.index)
      if (isMoveDelay && temp.dateCheckin === null) {
        if (!isFocusDelay) {
          isFocusDelay = true
          setCurrentArea(listFactoryArea[index])
          setAreaOrderTab(index)
        }
      }
    })
    dataUpdateContext.setDataUpdate({
      ...dataUpdateContext.dataUpdate,
      gates: gatesFactory,
      listCDS: listCDSFactory
    })
    setListFactoryArea(listFactoryArea)
    if (listFactoryArea.length !== 0) {
      setListCDSFactory(listCDSFactory)
      setGatesFactory(gatesFactory)
      if (!isFocusDelay) {
        setCurrentArea(listFactoryArea[0])
      }
    }
    // console.log(`ListFactory: ${JSON.stringify(listFactoryArea)}`);
    // console.log(`Gates: ${JSON.stringify(gatesFactory)}`);
    // console.log(`ListCDSFactory: ${JSON.stringify(listCDSFactory)}`);
  }, []);

  // useEffect(() => {
  //   if (dataUpdateContext.dataUpdate.listAreaOrder === undefined) {
  //     dataUpdateContext.dataUpdate.listAreaOrder = []
  //   }
  //   dataUpdateContext.dataUpdate.listAreaOrder = listAreaOrder
  // }, [listAreaOrder])

  useEffect(() => {
    if (dataUpdateContext.dataUpdate.listAreaOrder === undefined) {
      dataUpdateContext.dataUpdate.listAreaOrder = []
    }
    dataUpdateContext.dataUpdate.listAreaOrder = listFactoryArea
  }, [listFactoryArea])

  // useEffect(() => {
  //   if (detailData.companyDeliverySites.length !== 0) {
  //     const test = {
  //       gateIn: gates[txtAreaOrder].gateIn.positionId,
  //       gateOut: gates[txtAreaOrder].gateOut.positionId
  //     }
  //     form.setFieldsValue(test)
  //     setDataSource(listCDS[txtAreaOrder])
  //   }
  // }, [txtAreaOrder])

  useEffect(() => {
    if (currentArea.id !== undefined) {
      if (currentArea.id === 0 || gatesFactory[currentArea.name] === undefined) {
        form.setFieldsValue({
          gateIn: null,
          gateOut: null,
        })
        setDataSource([])
        return
      } else {
        form.setFieldsValue({
          gateIn: gatesFactory[currentArea.name].gateIn.positionId,
          gateOut: gatesFactory[currentArea.name].gateOut.positionId,
        })
        setDataSource(listCDSFactory[currentArea.name])
      }
    } else {
      setDataSource([])
    }
    if (dataUpdateContext.dataUpdate.listAreaOrder !== undefined) {
      for (let i = 0; i < dataUpdateContext.dataUpdate.listAreaOrder.length; i++) {
        if (dataUpdateContext.dataUpdate.listAreaOrder[i].dateCheckin === null) {
          setNameAreaFocus(dataUpdateContext.dataUpdate.listAreaOrder[i].name)
          setCurrentAreaCheck({
            name: dataUpdateContext.dataUpdate.listAreaOrder[i].name,
            action: 'checkin'
          })
          break
        } else if (dataUpdateContext.dataUpdate.listAreaOrder[i].dateCheckout === null) {
          setNameAreaFocus(dataUpdateContext.dataUpdate.listAreaOrder[i].name)
          setCurrentAreaCheck({
            name: dataUpdateContext.dataUpdate.listAreaOrder[i].name,
            action: 'checkout'
          })
          break
        }
      }
    }
  }, [currentArea])

  useEffect(() => {
    if (detailData?.companyDeliverySites) {
      const newArr: any = [...detailData?.companyDeliverySites];
      const gateIn: number | undefined = newArr?.[0]?.categoryPosition?.id;
      const gateOut: number | undefined = newArr?.[newArr?.length - 1]?.categoryPosition?.id;
      newArr.pop(); //xóa cổng vào
      newArr.shift(); // xóa cổng ra
      // list những vị trí đã giao => không thể sort
      const rowsDisable: any = newArr?.filter((item: any) => item?.unlockTime || item?.unlockUser);
      setRowsDisableSort(rowsDisable);
      // form.setFieldsValue({
      //   gateIn,
      //   gateOut,
      //   rowsDisableSort: rowsDisable,
      // });
      // setDataSource(
      //   newArr?.map((item: any) => ({
      //     key: (item?.orderNumber).toString(),
      //     index: item?.orderNumber,
      //     name: item?.categoryPosition?.id,
      //     unlockTime: item?.unlockTime,
      //     lockTime: item?.lockTime,
      //     disabled:
      //       item?.unlockTime ||
      //         item?.unlockUser ||
      //         item?.orderNumber <= rowsDisable?.slice(-1)?.[0]?.orderNumber
      //         ? true
      //         : false,
      //   })),
      // );
    } else {
      setDataSource([]);
    }
  }, []);

  useEffect(() => {
    // listCDS[txtAreaOrder] = dataSource as []
    listCDSFactory[currentArea.name] = dataSource
  }, [dataSource])

  useEffect(() => {
    dataUpdateContext.setDataUpdate({
      ...dataUpdateContext.dataUpdate,
      currentArea: currentArea,
      gates: gatesFactory,
      listCDS: listCDSFactory
    })
    // console.log(`dataUpdate: ${JSON.stringify(dataUpdateContext.dataUpdate)}`);
  }, [listCDSFactory, gatesFactory, currentArea])

  useEffect(() => {
    form.setFieldValue("companyDeliverySites", dataSource);
  }, [dataSource, form]);

  const RowTable = ({ children, ...props }: RowProps) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({ id: props["data-row-key"] });
    const style: React.CSSProperties = {
      ...props.style,
      transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
      transition,
      ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
    };
    return (
      <tr {...props} ref={setNodeRef} style={style} {...attributes}>
        {React.Children.map(children, (child) => {
          if ((child as React.ReactElement).key === "sort") {
            return React.cloneElement(child as React.ReactElement, {
              // Dòng bị disable không hiện icon sort
              children:
                (child as any)?.props?.record?.disabled ||
                  !isCanEditDeli ||
                  !isEditing ||
                  ![
                    JOURNEY_STATUS_VALUES.CHUA_VAO_SEV,
                    JOURNEY_STATUS_VALUES.DA_GAN_KHOA_DANG_GIAO,
                  ].includes(detailData?.status) ? (
                  <></>
                ) : (
                  <MenuOutlined
                    ref={setActivatorNodeRef}
                    style={{ touchAction: "none", cursor: "move" }}
                    {...listeners}
                  />
                ),
            });
          }
          return child;
        })}
      </tr>
    );
  };

  // Thêm dòng
  const handleAddNewRow = () => {
    const newData: ICompanyDeliverySitesAddData = {
      cdsId: 0,
      id: 0,
      key: (dataSource?.length + 1).toString(),
      index: dataSource?.length + 1,
      name: "",
      receiptNumber: 0,
      receiptUpdateTime: '',
      disabled: false
    };
    const updatedDataSource = [...dataSource, newData];
    setDataSource(updatedDataSource);
    setListCDSFactory({ ...listCDSFactory, [currentArea.name]: updatedDataSource as [] })
  };

  //Sort row
  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      if (parseInt(over?.id as string) > (rowsDisableSort?.slice(-1)?.[0]?.orderNumber || 0)) {
        setDataSource((previous) => {
          const activeIndex = previous.findIndex((i) => i.key === active.id);
          const overIndex = previous.findIndex((i) => i.key === over?.id);
          return arrayMove(previous, activeIndex, overIndex);
        });
      } else {
        notification.error({
          message: t("journeyManagementPage.validate.enableSort"),
        });
      }
    }
  };

  const columns: ColumnsType<ICompanyDeliverySitesAddData> = [
    {
      key: "sort",
      width: "5%",
    },
    {
      title: t("journeyManagementPage.columns.ordernumber"),
      key: "index",
      align: "center",
      width: "10%",
      render: (value: any, record: ICompanyDeliverySitesAddData, index: number) => index + 1,
    },
    // Vị trí giao nhận hàng
    {
      title: t("journeyManagementPage.columns.name"),
      dataIndex: "name",
      key: "name",
      align: "center",
      width: "25%",
      render: (value: any, record: ICompanyDeliverySitesAddData, index: number) => {
        return (
          <div
            className={`row-table ${!value || checkDuplicate?.includes(record.key) ? "dangerous" : ""
              }`}
          >
            <CommonSelect
              style={{ width: "90%", textAlign: "left" }}
              disabled={
                record?.disabled ||
                !isCanEditDeli ||
                !isEditing ||
                ![
                  JOURNEY_STATUS_VALUES.CHUA_VAO_SEV,
                  JOURNEY_STATUS_VALUES.DA_GAN_KHOA_DANG_GIAO,
                ].includes(detailData?.status)
              }
              options={positionSelectData
                ?.filter((item: ILocationDetailData) => item.type === LOCATION_VALUES.WAREHOUSE && Number(item.factoryAreaId) === currentArea.id)
                ?.map((item: ILocationDetailData) => ({
                  value: item.id,
                  label: item.name,
                }))}
              placeholder={t("journeyManagementPage.placeholder.name")}
              onChange={(values) => {
                setDataSource((prevState) => {
                  const newData = [...prevState];
                  newData[index].id = values;
                  newData[index].name = getNamePositonByID(values);
                  return newData;
                });
              }}
              value={value || undefined}
              allowClear={false}
              showSearch
            />
          </div>
        );
      },
    },
    {
      title: t("journeyManagementPage.columns.amount"),
      key: "receiptNumber",
      align: "center",
      width: "10%",
      render: (value: any, record: ICompanyDeliverySitesAddData, index: number) => record.receiptNumber || '--',
    },
    {
      title: t("journeyManagementPage.columns.time"),
      key: "unlockTime",
      align: "center",
      fixed: "right",
      width: "40%",
      render: (value: any, record: ICompanyDeliverySitesAddData, index: number) => {
        return (
          <div style={{ textAlign: "left" }}>
            <div>
              {t("journeyManagementPage.columns.unlock")}:{" "}
              {record?.unlockTime
                ? moment(record?.unlockTime).format(DATE_TIME_FORMAT_SECOND)
                : "--"}
            </div>
            <div>
              {t("journeyManagementPage.columns.closeLock")}:{" "}
              {record?.lockTime ? moment(record?.lockTime).format(DATE_TIME_FORMAT_SECOND) : "--"}
            </div>
            {detailData.categoryCar.type !== 99 ? <div>
              {t("journeyManagementPage.columns.timeAmount")}:{" "}
              {record?.receiptUpdateTime ? moment(record?.receiptUpdateTime).format(DATE_TIME_FORMAT_SECOND) : "--"}
            </div> : <></>}
          </div>
        );
      },
    },
    {
      key: "action",
      align: "center",
      width: "10%",
      render: (cell: any, record: ICompanyDeliverySitesAddData, index: number) => {
        return record?.disabled ||
          !isCanEditDeli ||
          !isEditing ||
          ![
            JOURNEY_STATUS_VALUES.CHUA_VAO_SEV,
            JOURNEY_STATUS_VALUES.DA_GAN_KHOA_DANG_GIAO,
          ].includes(detailData?.status) ? (
          <></>
        ) : (
          <DeleteIcon
            className="delete-icon"
            onClick={() => {
              setVisible(true);
              setItemDeleteId(record.index);
            }}
          />
        );
      },
    },
  ];

  // Nếu là xe nội bộ thì xóa cột thứ 3-Số lượng carry out
  if (detailData.categoryCar.type === 99) {
    columns.splice(3, 1)
  }

  // check duplicate vị trí kho
  useEffect(() => {
    if (dataSource.length > 1) {
      const duplicateData = dataSource
        ?.filter((item: ICompanyDeliverySitesAddData, index: number) => {
          if (index === 0) {
            return item?.name && item?.name === dataSource[1]?.name;
          } else if (index === dataSource.length - 1) {
            return item?.name && item?.name === dataSource[dataSource.length - 2].name;
          } else {
            return (
              item?.name &&
              (item.name === dataSource[index + 1].name || item.name === dataSource[index - 1].name)
            );
          }
        })
        ?.map((item: ICompanyDeliverySitesAddData) => item.key);
      setCheckDuplicate(duplicateData);
      form.setFieldValue("checkDuplicate", duplicateData);
    } else {
      setCheckDuplicate([]);
      form.setFieldValue("checkDuplicate", []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource, t]);

  // Xoá một dòng vị trí
  const onDeleteRow = () => {
    setDataSource((prevData: ICompanyDeliverySitesAddData[]) => {
      const newDataSource = prevData.filter(
        (item: ICompanyDeliverySitesAddData) => item.index !== itemDeleteId,
      );
      return newDataSource;
    });
    setVisible(false);
  };

  // check để mở khóa
  const unlockBtnOnClick = async () => {
    setIsLoadingUnlock(true);
    if (!positionIdUnlock || !lockIdUnlock) {
      notification.error({
        message: t("journeyManagementPage.validate.unlockInfoRequired"),
      });
      setIsLoadingUnlock(false);
      return;
    }

    const arrCheck = detailData?.companyDeliverySites.slice(1, -1);
    const isNotInList = !arrCheck?.find(
      (item: Partial<ICompanyDeliverySites>) =>
        item?.categoryPosition?.id === positionIdUnlock && !item?.unlockTime && !item?.unlockUser,
    );
    const wrongOrder =
      arrCheck?.find(
        (item: Partial<ICompanyDeliverySites>) => !item?.unlockTime && !item?.unlockUser,
      )?.categoryPosition?.id !== positionIdUnlock;
    if (isNotInList) {
      setIsHidden(true)
      setVisibleUnlockConfirm(true);
      setTypeVio(2);
    } else if (wrongOrder) {
      setIsHidden(true)
      setVisibleUnlockConfirm(true);
      setTypeVio(1);
    } else {
      handleUnlock();
    }
    setIsLoadingUnlock(false);
  };

  // Mở khoá
  const handleUnlock = async (isVio?: boolean) => {
    setLoadingConfirmUnlock(true);
    const dataSubmit: any = {
      deviceId: lockIdUnlock,
      positionId: positionIdUnlock,
      registerCarId: detailData?.id,
      source: detailData?.source,
      registerFactoryAreaId: currentArea.rFAId
    };
    if (isVio) {
      dataSubmit["isVio"] = isVio;
      dataSubmit["type"] = typeVio;
    }

    const resp = await journeyServices.vendorUnLock(dataSubmit);
    const data = resp?.data;
    if (resp?.status === 200) {
      notification.success({
        message: t("journeyManagementPage.message.unlockSuccess"),
      });
      setPositionIdUnlock(undefined);
      setLockIdUnlock(undefined);
      setVisibleUnlockConfirm(false);
      setTypeVio(undefined);
      window.location.reload();
    } else {
      notification.error({
        message: data?.message || t("commonError.oopsSystem"),
      });
    }
    setIsLoadingUnlock(false);
    setLoadingConfirmUnlock(false);
  };

  // Get name by id
  const getNamePositonByID = (id: any) => {
    if (id) {
      const item = positionSelectData.find(position => position.id === id)
      return item!.name
    }
    return ''
  }

  // Thay đổi cổng vào
  const onChangeGateIn = (values: any) => {
    if (gatesFactory[currentArea.name] === undefined) {
      gatesFactory[currentArea.name] = {
        gateIn: {
          id: 0,
          positionId: null
        },
        gateOut: {
          id: 0,
          positionId: null
        }
      }
    }
    gatesFactory[currentArea.name].gateIn.positionId = values
  }

  // Thay đổi cổng ra
  const onChangeGateOut = (values: any) => {
    if (gatesFactory[currentArea.name] === undefined) {
      gatesFactory[currentArea.name] = {
        gateIn: {
          id: 0,
          positionId: null
        },
        gateOut: {
          id: 0,
          positionId: null
        }
      }
    }
    gatesFactory[currentArea.name].gateOut.positionId = values
  }

  // Xoá một lộ trình
  const handleRemoveRowJourney = (index: number) => {
    if (listFactoryArea.length > 1 && detailData.categoryCar.type !== 99) {
      form.setFieldsValue({
        [index]: null
      })
      if (listFactoryArea[index].id === currentArea.id) {
        form.setFieldsValue({
          gateIn: null,
          gateOut: null
        })
        currentArea.id = 0
        setAreaOrderTab(-1)
        setDataSource([])
      }
      let newA = [...listFactoryArea]
      let removeP: FactoryArea[] = newA.splice(index, 1)
      delete listCDSFactory[removeP[0].name]
      delete gatesFactory[removeP[0].name]
      dataUpdateContext.setDataUpdate({
        gates: gatesFactory,
        listCDS: listCDSFactory
      })
      if (listFactoryArea[index].id === 0) {
        setIsCantChange(true)
      } else {
        setIsCantChange(false)
      }
      setListFactoryArea(newA)
    } else {
      notification.error({
        message: t("journeyManagementPage.validate.deleteFactory"),
      });
    }
  }

  // Thêm một lộ trình
  const handleAddNewRowJourney = () => {
    setListFactoryArea(prev => [
      ...prev,
      {
        id: 0,
        rFAId: 0,
        name: "",
        dateCheckin: null,
        dateCheckout: null
      }
    ])
  }

  // Thay đổi view lộ trình
  const handleClickAreaOrder = (props: any, index: number) => {
    if (checkDuplicate.length > 0 && detailData.categoryCar.type !== 99) {
      notification.error({
        message: t("journeyManagementPage.validate.deliveryDuplicate"),
      });
      setIsLoadingCreate(false);
      return
    }
    setAreaOrderTab(index)
    setCurrentArea(listFactoryArea[index])
  }

  // Thay đổi một khu vực
  const handleChangeArea = (key: any, item: any) => {
    setIsCantChange(true)
    // Dữ liệu temp
    const temp = {
      id: 0,
      rFAId: 0,
      name: "",
      dateCheckin: null,
      dateCheckout: null
    }
    // Set dữ liệu
    if (item !== undefined) {
      listFactoryArea[item.index] = {
        ...listFactoryArea[item.index],
        id: key,
        rFAId: 0,
        name: `${item.label}/${item.index}`,
      }
      // Trường hợp nhiều hơn một khu vực trong lộ trình
      if (listFactoryArea.length > 1) {
        // Kiểm tra phần tử có vị trí đứng trước
        if (item.index - 1 >= 0) {
          if (listFactoryArea[item.index].id === listFactoryArea[item.index - 1].id) {
            delete gatesFactory[currentArea.name]
            delete listCDSFactory[currentArea.name]
            listFactoryArea[item.index] = temp
            setCurrentArea(temp)
            setIsCantChange(true)
            form.setFieldsValue({
              [item.index]: temp
            })
            notification.error({
              message: "Lộ trình gần nhau không được giống nhau",
            });
            return
          }
        }
        // Kiểm tra phần tử có vị trí đứng đằng sau
        if (item.index + 1 < listFactoryArea.length) {
          if (listFactoryArea[item.index].id === listFactoryArea[item.index + 1].id) {
            delete gatesFactory[currentArea.name]
            delete listCDSFactory[currentArea.name]
            listFactoryArea[item.index] = temp
            setCurrentArea(temp)
            setIsCantChange(true)
            form.setFieldsValue({
              [item.index]: temp
            })
            notification.error({
              message: "Lộ trình gần nhau không được giống nhau",
            });
            return
          }
        }
      }
    }
    setIsCantChange(false)
  }

  // Kéo thả khu vực
  const handleDragEnd = (result: any) => {
    // Nếu không có hành động nào
    if (!result.destination) {
      return
    }

    // Nếu không có quyền sửa đổi
    if (!isCanEditDeli) {
      notification.error({
        message: "Bạn không có quyền chỉnh sửa!",
      });
      return;
    }

    // Nếu không trong trạng thái được sửa đổi
    if (!isEditing) {
      notification.error({
        message: "Sang trạng thái chỉnh sửa để thực hiện thao tác này",
      });
      return;
    }

    // Nếu trong danh sách khu vực có một khu vực rỗng
    if (listFactoryArea.some(value =>
      value.id === 0
    )) {
      notification.error({
        message: "Tồn tại một khu vực chưa được xác định. Hãy xác định khu vực trước khi sắp xếp!",
      });
      return
    }

    // Tráo đổi vị trị sắp xếp
    const items = Array.from(listFactoryArea);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    // Nếu tồn tại một khu vực giống nhau ở gần nhau hoặc là có xáo trộn tới khu đã check in
    if (items.length > 1) {
      for (let i = 0; i < items.length - 1; i++) {
        if (listFactoryArea[i].dateCheckin !== null) {
          if (items[i].name !== listFactoryArea[i].name) {
            notification.error({
              message: "Không được điều chỉnh khu đã check-in!",
            });
            return
          }
        }
        if (items[i].id === items[i + 1].id) {
          notification.error({
            message: "Các khu vực giống nhau không được gần nhau!",
          });
          return
        }
      }
    }

    setAreaOrderTab(items.findIndex(value => value.name === currentArea.name))
    items.map((v, index) => {
      form.setFieldsValue({
        [index]: v.id
      })
    })

    setListFactoryArea(items);
  };

  return (
    <div className=" journey-box" hidden={isHidden}>
      <div className="list" style={{ flex: "0.6 1.4", width: "100%", marginRight: "10px" }}>
        <div className="form-title"
          onClick={() => {
            console.log(`ListFactory: ${JSON.stringify(listFactoryArea)}`)
            console.log(`gatesFactory: ${JSON.stringify(gatesFactory)}`)
            console.log(`listCDSFactory: ${JSON.stringify(listCDSFactory)}`)
            console.log(`DataUpdate: ${JSON.stringify(dataUpdateContext.dataUpdate)}`)
          }}>
          {t("journeyManagementPage.journeyListTitle")}
        </div>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {listFactoryArea.map((props, index) => (
                  <Draggable key={index} draggableId={index.toString()} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          cursor: "pointer",
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "space-between",
                          background: areaOrderTab === index ? "#3f80fe" : "#FFFFFF",
                          borderRadius: "8px",
                          paddingLeft: "15px",
                          paddingRight: "15px",
                          fontSize: "12px",
                          fontWeight: 500,
                          lineHeight: "30px",
                          marginBottom: "10px",
                          border: areaOrderTab === index ? "none" : "1px solid #d9d9d9",
                          color: areaOrderTab === index ? "#FFFFFF" : "#000000",
                          display: "flex",
                          ...provided.draggableProps.style
                        }}>
                        <div style={{
                          width: "30px",
                          borderRight: areaOrderTab === index ? "1px solid #FFFFFF" : "1px solid #000000",
                          margin: "10px"
                        }}>{index + 1}</div>
                        <div
                          onClick={() => handleClickAreaOrder(props, index)}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: '100%'
                          }}>
                          {/* {props.name === "" ? "Chưa có" : props.name} */}
                          {props.rFAId !== 0 ? <>{props.name.split('/')[0]}</> : <CommonFormItem
                            name={index}
                            label={null}
                            placeholder={t("journeyManagementPage.placeholder.area") as string}
                            options={listArea.map((item: IArea) => ({
                              value: item.id,
                              label: item.name,
                              index: index
                            }))}
                            disabled={!isEditing}
                            onChange={handleChangeArea}
                            allowClear={false}
                            type="select"
                            style={{ marginBottom: '0px', width: '75%' }}
                          />}
                        </div>
                        {/* {isCanEditDeli && isEditing && props.dateCheckin === null ? <DeleteIcon onClick={() => handleRemoveRowJourney(index)} /> : <></>} */}
                        <div style={{
                          // width: "75px",
                          // background: "red",
                          alignItems: "center",
                          justifyItems: "center"
                        }}>
                          {
                            props.dateCheckout !== null
                              ? "Checkout"
                              : props.dateCheckin !== null
                                ? "Checkin"
                                : isCanEditDeli && isEditing && props.dateCheckin === null ? <DeleteIcon onClick={() => handleRemoveRowJourney(index)} /> : <></>
                          }
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {isCanEditDeli && isEditing && detailData.categoryCar.type !== 99 ? <AddIcon className="add-icon" onClick={handleAddNewRowJourney} /> : <></>}
      </div>
      <div className="itemList" style={{ flex: "1.4 0.6", width: "100%", borderLeft: "1px solid #7f7f7f", paddingLeft: "10px" }}>
        <div className="form-title">
          {t("journeyManagementPage.deliveryTitle")}
        </div>
        {/* <h4 style={{ textAlign: "center", fontWeight: 800 }}>
          {txtAreaOrder}
        </h4> */}
        <Row gutter={20}>
          <Col span={12}>
            <CommonFormItem
              name="gateIn"
              onChange={onChangeGateIn}
              label={t("journeyManagementPage.label.gateIn")}
              placeholder={t("journeyManagementPage.placeholder.gateIn") as string}
              options={positionSelectData
                ?.filter((item: any) => item.type === LOCATION_VALUES.GATE_IN && Number(item.factoryAreaId) === currentArea.id)
                ?.map((item: any) => ({ value: item.id, label: item.name }))}
              type="select"
              disabled={
                // (isCantChange) || (detailData?.status !== JOURNEY_STATUS_VALUES.CHUA_VAO_SEV ||
                //   !isCanEditDeli ||
                //   !isEditing)
                (isCantChange && isCanEditDeli) || !isEditing
                  ? true
                  : listFactoryArea.find(value => value.name === currentArea.name)?.dateCheckin !== null ||
                    currentArea.id === 0
                    ? true
                    : false
              }
              showRequiredIcon={
                (detailData.categoryCar.type !== 99) && (!(
                  detailData?.status !== JOURNEY_STATUS_VALUES.CHUA_VAO_SEV ||
                  !isCanEditDeli ||
                  !isEditing
                ))
              }
            // rules={[
            //   {
            //     required: detailData.categoryCar.type !== 99,
            //     message: `${t("validate.select")} ${t("journeyManagementPage.label.gateIn")}.`,
            //   },
            // ]}
            />
          </Col>
          <Col span={12}>
            <CommonFormItem
              name="gateOut"
              onChange={onChangeGateOut}
              label={t("journeyManagementPage.label.gateOut")}
              placeholder={t("journeyManagementPage.placeholder.gateOut") as string}
              options={positionSelectData
                ?.filter((item: any) => item.type === LOCATION_VALUES.GATE_OUT && Number(item.factoryAreaId) === currentArea.id)
                ?.map((item: any) => ({ value: item.id, label: item.name }))}
              type="select"
              showRequiredIcon={
                (detailData.categoryCar.type !== 99) && (!(
                  ![
                    JOURNEY_STATUS_VALUES.CHUA_VAO_SEV,
                    JOURNEY_STATUS_VALUES.DA_GAN_KHOA_DANG_GIAO,
                  ].includes(detailData?.status) ||
                  !isCanEditDeli ||
                  !isEditing
                ))
              }
              // rules={[
              //   {
              //     required: detailData.categoryCar.type !== 99,
              //     message: `${t("validate.select")} ${t("journeyManagementPage.label.gateOut")}.`,
              //   },
              // ]}
              disabled={
                // (isCantChange) || (![
                //   JOURNEY_STATUS_VALUES.CHUA_VAO_SEV,
                //   JOURNEY_STATUS_VALUES.DA_GAN_KHOA_DANG_GIAO,
                // ].includes(detailData?.status) ||
                //   !isCanEditDeli ||
                //   !isEditing)
                (isCantChange && isCanEditDeli) || !isEditing
                  ? true
                  : listFactoryArea.find(value => value.name === currentArea.name)?.dateCheckout !== null ||
                    currentArea.id === 0
                    ? true
                    : false
              }
            />
          </Col>
          <Col span={24} className="mgt-10">
            <DndContext onDragEnd={onDragEnd}>
              <SortableContext
                // rowKey array
                items={dataSource.map((i) => i.key)}
                strategy={verticalListSortingStrategy}
              >
                <CommonTable
                  components={{
                    body: {
                      row: RowTable,
                    },
                  }}
                  scroll={{ y: "20.833vw" }}
                  pagination={false}
                  rowKey="key"
                  columns={columns}
                  dataSource={dataSource}
                />
              </SortableContext>
            </DndContext>

            {/* {[
              JOURNEY_STATUS_VALUES.CHUA_VAO_SEV,
              JOURNEY_STATUS_VALUES.DA_GAN_KHOA_DANG_GIAO,
            ].includes(detailData?.status) */}
            {isCanEditDeli && isEditing
              ? currentArea.id !== 0 && currentArea.dateCheckout === null
                ? <AddIcon className="add-icon mgt-20" onClick={handleAddNewRow} />
                : <></>
              : <></>
            }
          </Col>
        </Row>

        {detailData?.status === JOURNEY_STATUS_VALUES.DA_GAN_KHOA_DANG_GIAO && isCanEditDeli &&
          (currentArea.name === nameAreaFocus && currentAreaCheck.action === 'checkout') &&
          (detailData?.status !== 4 && detailData?.status !== 5) ? (
          <Row gutter={20}>
            <Col span={24}>
              <div className="mgt-30 form-title">{t("journeyManagementPage.unlockTitle")}</div>
            </Col>
            <Col span={12}>
              <div className="dpl-flex align-items-center" style={{ height: "100%" }}>
                <div className="txt-base mgr-10">
                  {t("journeyManagementPage.label.positionUnlock")}:
                </div>
                <CommonSelect
                  style={{ flex: 1 }}
                  placeholder={t("journeyManagementPage.placeholder.positionUnlock") as string}
                  options={positionSelectData
                    ?.filter((item: ILocationDetailData) => item?.type === LOCATION_VALUES.WAREHOUSE && Number(item.factoryAreaId) === currentArea.id)
                    ?.map((item: ILocationDetailData) => ({
                      value: item.id,
                      label: item.name,
                    }))}
                  onChange={(value) => setPositionIdUnlock(value)}
                  value={positionIdUnlock}
                  showSearch
                  allowClear
                />
              </div>
            </Col>
            <Col span={12}>
              <div className="dpl-flex align-items-center" style={{ height: "100%" }}>
                <div className="txt-base mgr-10">{t("journeyManagementPage.label.IMEIUnlock")}:</div>
                <CommonSelect
                  style={{ flex: 1 }}
                  placeholder={t("journeyManagementPage.placeholder.IMEIUnlock") as string}
                  options={detailData?.registerCarLocks?.map((item: any) => ({
                    value: item?.categoryLock?.id,
                    label: item?.categoryLock?.uniqueid,
                  }))}
                  onChange={(value) => setLockIdUnlock(value)}
                  value={lockIdUnlock}
                  showSearch
                  allowClear
                />
              </div>
            </Col>
            <Col span={24} className="mgt-30 dpl-flex align-items-center justify-content-center">
              <CommonButton
                size={"small"}
                btnType="warning"
                onClick={unlockBtnOnClick}
                loading={isLoadingUnlock}
              >
                {t("common.button.unlock")}
              </CommonButton>
            </Col>
          </Row>
        ) : (
          <></>
        )}
        {visible ? (
          <CommonConfirmModal
            onCancel={() => {
              setVisible(false);
            }}
            content={t("journeyManagementPage.message.deleteDelivery")}
            visible={visible}
            onOk={onDeleteRow}
          />
        ) : (
          <></>
        )}

        {visibleUnlockConfirm ? (
          <CommonConfirmModal
            onCancel={() => {
              setIsHidden(false);
              setVisibleUnlockConfirm(false);
              setTypeVio(undefined);
            }}
            content={
              <>
                <div style={{ textAlign: "left", marginBottom: "0.5rem" }}>
                  <div className="mgt-10">
                    <span className="mgr-20">{t("journeyManagementPage.label.positionUnlock")}:</span>
                    <span style={{ fontWeight: 500 }}>
                      {`${positionSelectData?.find((item) => item?.id === positionIdUnlock)?.name
                        }` || "--"}
                    </span>
                  </div>
                  <div className="mgt-10">
                    <span className="mgr-20">{t("journeyManagementPage.label.IMEIUnlock")}:</span>
                    <span style={{ fontWeight: 500 }}>
                      {detailData?.registerCarLocks?.find(
                        (item: any) => item?.categoryLock?.id === lockIdUnlock,
                      )?.categoryLock?.uniqueid || "--"}
                    </span>
                  </div>
                </div>
                <div className="mgt-10 txt-base" style={{ color: "#d83939", textAlign: "center" }}>
                  {t("journeyManagementPage.message.warningUnlockViolation")}
                </div>
              </>
            }
            title={t("journeyManagementPage.confirmUnlockViolation") as string}
            visible={visibleUnlockConfirm}
            onOk={() => {
              setIsHidden(false);
              handleUnlock(true);
            }}
            loadingBtnOk={loadingConfirmUnlock}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default InfoTab;
