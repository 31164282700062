import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { Col, Form, Row, Space } from "antd";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CommonButton from "../../components/Common/Button";
import CommonForm from "../../components/Common/Form";
import CommonFormItem from "../../components/Common/FormItem";
import CommonInput from "../../components/Common/Input";
import { ReactComponent as Search } from "../../resources/images/search-1.svg";
import vehicleTypeServices from "../../services/vehicleTypeCategory.service";
import { DEFAULT_PAGE_NUMBER, SEV_CODE } from "../../utils/constans";
import { JOURNEY_STATUS_OPTIONS, OPTION_DELIVERY } from "../../utils/constans/journeyManagement";
import { useQuery } from "../../utils/customHooks";
import { ICommonSelectDataType, ISearchBoxProps, IUseQueryResponse } from "../../utils/types";
import { IVehicleTypeDetailData } from "../../utils/types/vehicleType";
import { buildQueryString } from "../../utils/utilFunctions";

function SearchBox({ getData, componentPath }: ISearchBoxProps) {
  const navigate = useNavigate();
  const queryObj: IUseQueryResponse = useQuery();
  const { params = {}, search } = queryObj;
  const { advance: advanceQuery, search: searchQuery } = params;
  const searchQueryData = searchQuery ? JSON.parse(searchQuery) : {};
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [advance, setAdvance] = useState(advanceQuery === "true" || false);
  const [selectVehicleTypeData, setSelectVehicleTypeData] = useState<ICommonSelectDataType[]>([]);
  const { profile } = useSelector((state: any) => state?.profileReducer);
  const isSEV = profile?.source === SEV_CODE;

  const getDataSelect = useCallback(async () => {
    const paramsSearch = {
      page: 0,
      size: 1000,
    };
    if (isSEV) {
      //select loại xe
      const respVehicleType = await vehicleTypeServices.getListVehicleType(paramsSearch);
      const dataVehicleType = respVehicleType?.data;
      if (respVehicleType?.status === 200) {
        setSelectVehicleTypeData(
          dataVehicleType?.data?.content?.map((item: IVehicleTypeDetailData) => ({
            value: item?.id,
            label: item?.name,
          })),
        );
      } else {
        setSelectVehicleTypeData([]);
      }
    }
  }, [isSEV]);

  useEffect(() => {
    getDataSelect();
  }, [getDataSelect]);

  useEffect(() => {
    setAdvance(advanceQuery === "true");
  }, [advanceQuery]);

  const onFinish = (values: any) => {
    onSearch(values);
  };

  const onClear = (clearParams = {}) => {
    onSearch({ ...clearParams }, true);
    setTimeout(() => {
      form.resetFields();
    }, 100);
  };

  const onSearch = (data: any = {}, isClear?: boolean) => {
    const dataSearch = { ...data };
    if (data?.createDate?.length) {
      dataSearch["createDateFromLong"] = moment(data?.createDate?.[0]).startOf("day").valueOf();
      dataSearch["createDateToLong"] = moment(data?.createDate?.[1]).endOf("day").valueOf();
    }
    delete dataSearch.advance;
    delete dataSearch?.createDate;
    let query = {
      advance,
      page: DEFAULT_PAGE_NUMBER,
      search: JSON.stringify(dataSearch),
    };
    if (isClear) {
      query.advance = data.advance;
    }
    const queryString = buildQueryString(query);
    // detect if query not change => call api
    if (queryString !== search) {
      navigate(`${componentPath}${queryString}`);
    } else {
      if (getData) getData();
    }
  };

  return (
    <div className="avic-search-box">
      {!advance ? (
        <div className="normal-search-box">
          <CommonForm
            form={form}
            onFinish={onFinish}
            layout="horizontal"
            initialValues={{
              keyword: searchQueryData?.keyword,
            }}
          >
            <CommonFormItem name="keyword">
              <CommonInput
                placeholder={t("journeyManagementPage.placeholder.advancedSearch") as string}
                prefix={
                  <div
                    onClick={() => {
                      onClear({ advance: true });
                    }}
                    className="open-advance-search-btn"
                  >
                    {t("journeyManagementPage.label.advancedSearch")} <CaretDownOutlined />
                  </div>
                }
                addonAfter={
                  <div onClick={() => form.submit()} className="btn-normal-search">
                    <Search />
                  </div>
                }
              />
            </CommonFormItem>
          </CommonForm>
        </div>
      ) : (
        <div className="advance-search-box">
          <div
            className="close-advance-search-btn cursor-pointer"
            onClick={() => {
              onClear({ advance: false });
            }}
          >
            <div>{t("journeyManagementPage.searchTitle")}</div>
            <CaretUpOutlined className="cursor-pointer" />
          </div>

          <CommonForm
            form={form}
            onFinish={onFinish}
            layout="vertical"
            initialValues={{
              id: searchQueryData?.id, // mã hành trình
              statusStr: searchQueryData?.statusStr, // trạng thái hành trình
              plateNumber: searchQueryData?.plateNumber, // biển số xe
              optionDelivery: searchQueryData?.optionDelivery, // tùy chọn giao nhận
              createDate:
                searchQueryData?.createDateFromLong && searchQueryData?.createDateToLong
                  ? [
                    moment(searchQueryData?.createDateFromLong),
                    moment(searchQueryData?.createDateToLong),
                  ]
                  : [], // thời gian đăng ký
              // Ẩn tìm kiếm theo tên công ty
              // companyName: searchQueryData?.companyName, // tên công ty
              vehicleType: searchQueryData?.vehicleType, // Loại xe
            }}
          >
            <Row gutter={30}>
              <Col span={12}>
                <CommonFormItem
                  name="id"
                  label={t("journeyManagementPage.label.id")}
                  placeholder={t("journeyManagementPage.placeholder.id") as string}
                />
              </Col>
              <Col span={12}>
                <CommonFormItem
                  name="plateNumber"
                  label={t("journeyManagementPage.label.plateNumber")}
                  placeholder={t("journeyManagementPage.placeholder.plateNumber") as string}
                />
              </Col>
              <Col span={12}>
                <CommonFormItem
                  name="optionDelivery"
                  label={t("journeyManagementPage.label.optionDelivery")}
                  placeholder={t("journeyManagementPage.placeholder.optionDelivery") as string}
                  options={OPTION_DELIVERY?.map((x) => ({ ...x, label: t(x?.label) }))}
                  type="select"
                />
              </Col>
              {isSEV ? (
                <>
                  {/* Ẩn trường tìm kiếm theo tên công ty */}
                  {/* <Col span={12}>
                    <CommonFormItem
                      name="companyName"
                      label={t("journeyManagementPage.label.companyName")}
                      placeholder={t("journeyManagementPage.placeholder.companyName") as string}
                    />
                  </Col> */}
                  <Col span={12}>
                    <CommonFormItem
                      name="vehicleType"
                      label={t("journeyManagementPage.label.vehicleType")}
                      placeholder={t("journeyManagementPage.placeholder.vehicleType") as string}
                      options={selectVehicleTypeData}
                      type="select"
                    />
                  </Col>
                </>
              ) : (
                <></>
              )}
              <Col span={12}>
                <CommonFormItem
                  name="createDate"
                  type="rangePicker"
                  label={t("journeyManagementPage.label.createDate")}
                  placeholder={[t("common.fromDate"), t("common.toDate")]}
                />
              </Col>
              <Col span={12}>
                <CommonFormItem
                  name="statusStr"
                  label={t("journeyManagementPage.label.status")}
                  placeholder={t("journeyManagementPage.placeholder.status") as string}
                  options={JOURNEY_STATUS_OPTIONS?.map((x) => ({ ...x, label: t(x?.label) }))}
                  type="select"
                />
              </Col>
            </Row>
            <Space className="form-btn-container">
              <CommonButton
                btnType="default"
                size={"small"}
                onClick={() => onClear({ advance: true })}
              >
                {t("common.button.deleteCondition")}
              </CommonButton>
              <CommonButton btnType="primary" size={"small"} htmlType="submit">
                {t("common.button.search")}
              </CommonButton>
            </Space>
          </CommonForm>
        </div>
      )}
    </div>
  );
}

export default SearchBox;
