import { Col, Form, Row, Space, TreeDataNode, notification } from "antd";
import { useWatch } from "antd/es/form/Form";
import { Key, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import CommonButton from "../../components/Common/Button";
import CommonForm from "../../components/Common/Form";
import CommonFormItem from "../../components/Common/FormItem";
import CommonSpin from "../../components/Common/Spin";
import CommonTree from "../../components/Common/Tree";
import permissionsServices from "../../services/groupManagement.service";
import locationServices from "../../services/locationCategory.service";
import { COMMON_PAGE_STATUS } from "../../utils/constans";
import { GROUP_TYPE_OPTIONS, GROUP_TYPE_VALUES } from "../../utils/constans/groupManagement";
import { LOCATION_VALUES } from "../../utils/constans/locationCategory";
import { SIDEBAR_ITEM_HREF } from "../../utils/constans/sidebar";
import { ILocationDetailData } from "../../utils/types/locationCategory";
import { modifyArrTreePermission } from "../../utils/utilFunctions";
import CommonCheckbox from "./../../components/Common/Checkbox";
import { IAuthorityDetailData } from "./../../utils/types/groupManagement";

function GroupAddForm() {
  const componentPath = SIDEBAR_ITEM_HREF.group_management;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [checkedLeftKeys, setCheckedLeftKeys] = useState<React.Key[]>([]);
  const [leftTreeData, setLeftTreeData] = useState<TreeDataNode[]>([]);
  const [rightTreeData, setRightTreeData] = useState<TreeDataNode[]>([]);
  const [checkedRightKeys, setCheckedRightKeys] = useState<React.Key[]>([]);
  const [permissionSecurityId, setPermissionSecurityId] = useState<number>(0);
  const [permissionSecurityGateInId, setPermissionSecurityGateInId] = useState<number>(0);
  const [permissionSecurityGateOutId, setPermissionSecurityGateOutId] = useState<number>(0);
  const [permissionSecurityVendorId, setPermissionSecurityVendorId] = useState<number>(0);
  const [positionSelectData, setPositionSelectData] = useState<ILocationDetailData[]>([]);
  const [listFullAuth, setListFullAuth] = useState<IAuthorityDetailData[]>([]);
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);
  const [isCheckedWebsite, setIsCheckedWebsite] = useState<boolean>(true);

  const typeWebsiteValue = useWatch("typeWebsite", form);

  const getDataSelect = async () => {
    const paramsSearch = {
      page: 0,
      size: 1000,
      search: JSON.stringify({ status: 0 }),
    };

    //select vị trí
    const respPosition = await locationServices.getPosition(paramsSearch);
    const dataPosition = respPosition?.data;
    if (respPosition?.status === 200) {
      setPositionSelectData(dataPosition?.data?.content);
    } else {
      setPositionSelectData([]);
    }
  };

  useEffect(() => {
    getDataSelect();
  }, []);

  const getData = useCallback(async () => {
    setIsLoading(true);
    const resp = await permissionsServices.getFullAuthority();
    const data = resp?.data;
    if (resp?.status === 200) {
      setListFullAuth(data?.data);
    } else {
      setListFullAuth([]);
      notification.error({
        message: data?.message || t("commonError.oopsSystem"),
      });
    }
    setIsLoading(false);
  }, [t]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (listFullAuth?.length) {
      const test = [...listFullAuth];
      let obj: any = {};
      test.map((item) => {
        obj[item.authKey] = item.authKey + "-" + item.description;
        return item;
      });
      console.log(test.length, "first", obj);
      const dataRight = listFullAuth?.filter(
        (item: IAuthorityDetailData) =>
          !["SECURITY", "SECURITY_GATEIN", "SECURITY_GATEOUT", "SECURITY_VENDOR"]?.includes(
            item?.authKey,
          ) && item?.type?.includes(typeWebsiteValue) 
          // Ẩn tài khoản được sử dụng khoá và Tài khoản thuộc vendor được sử dụng khoá
          && item.id !== 31 && item.id !== 72 
      );
      const permissionSecurity = listFullAuth?.find(
        (item: IAuthorityDetailData) => item?.authKey === "SECURITY",
      );
      const permissionSecurityGateIn = listFullAuth?.find(
        (item: IAuthorityDetailData) => item?.authKey === "SECURITY_GATEIN",
      );
      const permissionSecurityGateOut = listFullAuth?.find(
        (item: IAuthorityDetailData) => item?.authKey === "SECURITY_GATEOUT",
      );
      const permissionSecurityVendor = listFullAuth?.find(
        (item: IAuthorityDetailData) => item?.authKey === "SECURITY_VENDOR",
      );
      setPermissionSecurityId(permissionSecurity?.id || 0);
      setPermissionSecurityGateInId(permissionSecurityGateIn?.id || 0);
      setPermissionSecurityGateOutId(permissionSecurityGateOut?.id || 0);
      setPermissionSecurityVendorId(permissionSecurityVendor?.id || 0);
      const newRightTreeData = modifyArrTreePermission(dataRight);
      setExpandedKeys(newRightTreeData?.map((item) => item?.key));
      setLeftTreeData(newRightTreeData);
      setRightTreeData([
        {
          title: permissionSecurity?.description,
          key: permissionSecurity?.id as number,
        },
        {
          title: permissionSecurityGateIn?.description,
          key: permissionSecurityGateIn?.id as number,
        },
        {
          title: permissionSecurityGateOut?.description,
          key: permissionSecurityGateOut?.id as number,
        },
        {
          title: permissionSecurityVendor?.description,
          key: permissionSecurityVendor?.id as number,
        },
      ]);
    } else {
      setPermissionSecurityId(0);
      setPermissionSecurityGateInId(0);
      setPermissionSecurityGateOutId(0);
      setPermissionSecurityVendorId(0);
      setExpandedKeys([]);
      setLeftTreeData([]);
      setRightTreeData([]);
    }
  }, [listFullAuth, typeWebsiteValue]);

  const onCheckLeft = (checkedKeysValue: any, noteCheck: any) => {
    setCheckedLeftKeys(checkedKeysValue);
  };

  const onCheckRight = (checkedKeysValue: any, noteCheck: any) => {
    form.setFieldValue("categoryPosition", undefined);
    if (noteCheck.checked) {
      setCheckedRightKeys([noteCheck.node.key]);
    } else {
      setCheckedRightKeys([]);
    }
  };

  const onFinish = async (values: any) => {
    if (checkedLeftKeys?.length === 0 && checkedRightKeys?.length === 0) {
      notification.error({
        message: t("groupManagementPage.message.permissionRequired"),
      });
      return;
    }

    setIsSubmitLoading(true);
    let body = {
      description: values?.description,
      authorities: checkedLeftKeys
        .concat(checkedRightKeys)
        .map((item: Key) => ({ id: item as number })),
      groupName: values?.groupName,
      status: values?.status,
      categoryPosition: values?.categoryPosition ? { id: values?.categoryPosition } : null,
      type: isCheckedWebsite
        ? values?.typeWebsite
        : checkedRightKeys.includes(permissionSecurityVendorId)
        ? GROUP_TYPE_VALUES.APP_VENDOR
        : GROUP_TYPE_VALUES.APP_SEV,
    };
    const resp = await permissionsServices.createPermission(body);
    const data = resp?.data;
    if (resp?.status === 200) {
      notification.success({
        message: t("groupManagementPage.message.createSuccess"),
      });
      navigate(`${componentPath}`);
    } else {
      notification.error({
        message: data?.message || t("commonError.oopsSystem"),
      });
    }
    setIsSubmitLoading(false);
  };

  const onExpand = (expandedKeysValue: Key[]) => {
    setExpandedKeys(expandedKeysValue);

    console.log(JSON.stringify(expandedKeysValue));
    
  };

  return (
    <div className="box-wrapper">
      <CommonForm
        form={form}
        onFinish={onFinish}
        layout="vertical"
        initialValues={{ typeWebsite: GROUP_TYPE_VALUES.WEB_SEV }}
      >
        <div className="avic-search-box">
          <div className="advance-search-box">
            <div className="form-title">{t("groupManagementPage.addTitle")}</div>
            <div className="group-box">
              <Row gutter={30}>
                <Col span={12}>
                  <CommonFormItem
                    name="groupName"
                    showRequiredIcon
                    maxLength={128}
                    label={t("groupManagementPage.label.name")}
                    placeholder={t("groupManagementPage.placeholder.name") as string}
                    rules={[
                      {
                        whiteSpace: true,
                        required: true,
                        message: `${t("validate.input")} ${t("groupManagementPage.label.name")}.`,
                      },
                    ]}
                  />
                </Col>
                <Col span={12}>
                  <CommonFormItem
                    name="status"
                    label={t("groupManagementPage.label.status")}
                    placeholder={t("groupManagementPage.placeholder.status") as string}
                    options={COMMON_PAGE_STATUS.map((x) => ({ ...x, label: t(x.label) }))}
                    type="select"
                    showRequiredIcon
                    rules={[
                      {
                        required: true,
                        message: `${t("validate.select")} ${t(
                          "groupManagementPage.label.status",
                        )}.`,
                      },
                    ]}
                  />
                </Col>
                <Col span={24}>
                  <CommonFormItem
                    name="description"
                    type="textArea"
                    rows={4}
                    maxLength={128}
                    label={t("groupManagementPage.label.description")}
                    placeholder={t("groupManagementPage.placeholder.description") as string}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </div>

        <div className="avic-search-box">
          <div className="advance-search-box">
            <div className="form-title">{t("groupManagementPage.permissionTitle")}</div>
            <Row className="group-line">
              <Col span={12} className="group-wrapper">
                <div className="group-item">
                  <div className="group-sub-title">
                    <CommonCheckbox
                      checked={isCheckedWebsite}
                      onChange={() => {
                        setIsCheckedWebsite(!isCheckedWebsite);
                        setCheckedRightKeys([]);
                        form.setFieldValue("categoryPosition", undefined);
                      }}
                    >
                      {t("groupManagementPage.permissionWebTitle")}
                    </CommonCheckbox>
                  </div>
                  <div>
                    <CommonFormItem
                      name="typeWebsite"
                      options={GROUP_TYPE_OPTIONS?.filter((x) =>
                        // Ẩn nhóm quyền Wbsiteb vendor
                        // [GROUP_TYPE_VALUES.WEB_SEV, GROUP_TYPE_VALUES.WEB_VENDOR].includes(
                        [GROUP_TYPE_VALUES.WEB_SEV].includes(
                          x.value as number,
                        ),
                      ).map((x) => ({ ...x, label: t(x.label) }))}
                      type="select"
                      disabled={!isCheckedWebsite}
                      onChange={() => {
                        setCheckedLeftKeys([]);
                      }}
                    />
                    <CommonSpin isLoading={isLoading}>
                      <CommonTree
                        key="leftTree"
                        checkable
                        selectable={false}
                        showLine={true}
                        onCheck={onCheckLeft}
                        checkedKeys={checkedLeftKeys}
                        // Ẩn Quản lý Vendor ở tab Quản lý nhóm quyền
                        // Ẩn quản lý danh mục loại xe
                        treeData={leftTreeData?.filter((v)=> v.key !== 3 && v.key !== 7)}
                        onExpand={onExpand}
                        expandedKeys={expandedKeys}
                        disabled={!isCheckedWebsite}
                      />
                    </CommonSpin>
                  </div>
                </div>
              </Col>
              <Col span={12} className="group-wrapper">
                <div className="group-item">
                  <div className="group-sub-title">
                    <CommonCheckbox
                      checked={!isCheckedWebsite}
                      onChange={() => {
                        setIsCheckedWebsite(!isCheckedWebsite);
                        setCheckedLeftKeys([]);
                      }}
                    >
                      {t("groupManagementPage.permissionAppTitle")}
                    </CommonCheckbox>
                  </div>
                  <div>
                    {checkedRightKeys?.includes(permissionSecurityId) ? (
                      <CommonFormItem
                        name="categoryPosition"
                        label={t("groupManagementPage.label.categoryPosition")}
                        placeholder={
                          t("groupManagementPage.placeholder.categoryPosition") as string
                        }
                        showRequiredIcon
                        options={positionSelectData
                          ?.filter(
                            (item: ILocationDetailData) => item.type === LOCATION_VALUES.WAREHOUSE,
                          )
                          ?.map((item: ILocationDetailData) => ({
                            value: item.id,
                            label: item.name + " - " + item.address,
                          }))}
                        type="select"
                        allowClear
                        rules={[
                          {
                            required: true,
                            message: `${t("validate.select")} ${t(
                              "groupManagementPage.label.categoryPosition",
                            )}.`,
                          },
                        ]}
                      />
                    ) : (
                      <></>
                    )}
                    {checkedRightKeys?.includes(permissionSecurityGateInId) ? (
                      <CommonFormItem
                        name="categoryPosition"
                        label={t("groupManagementPage.label.categoryPositionGateIn")}
                        placeholder={
                          t("groupManagementPage.placeholder.categoryPositionGateIn") as string
                        }
                        showRequiredIcon
                        options={positionSelectData
                          ?.filter(
                            (item: ILocationDetailData) => item.type === LOCATION_VALUES.GATE_IN,
                          )
                          ?.map((item: ILocationDetailData) => ({
                            value: item.id,
                            label: item.name + " - " + item.address,
                          }))}
                        type="select"
                        allowClear
                        rules={[
                          {
                            required: true,
                            message: `${t("validate.select")} ${t(
                              "groupManagementPage.label.categoryPositionGateIn",
                            )}.`,
                          },
                        ]}
                      />
                    ) : (
                      <></>
                    )}
                    {checkedRightKeys?.includes(permissionSecurityGateOutId) ? (
                      <CommonFormItem
                        name="categoryPosition"
                        label={t("groupManagementPage.label.categoryPositionGateOut")}
                        placeholder={
                          t("groupManagementPage.placeholder.categoryPositionGateOut") as string
                        }
                        showRequiredIcon
                        options={positionSelectData
                          ?.filter(
                            (item: ILocationDetailData) => item.type === LOCATION_VALUES.GATE_OUT,
                          )
                          ?.map((item: ILocationDetailData) => ({
                            value: item.id,
                            label: item.name + " - " + item.address,
                          }))}
                        type="select"
                        allowClear
                        rules={[
                          {
                            required: true,
                            message: `${t("validate.select")} ${t(
                              "groupManagementPage.label.categoryPositionGateOut",
                            )}.`,
                          },
                        ]}
                      />
                    ) : (
                      <></>
                    )}
                    {/* Ẩn quyền bảo vệ Vendor trong quuyền trên app */}
                    <CommonSpin isLoading={isLoading}>
                      <CommonTree
                        key="rightTree"
                        checkable
                        selectable={false}
                        showLine={true}
                        onCheck={onCheckRight}
                        checkedKeys={checkedRightKeys}
                        treeData={rightTreeData.filter(v => v.key !== 68)}
                        disabled={isCheckedWebsite}
                      />
                    </CommonSpin>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </CommonForm>

      <Space className="form-btn-container" style={{ justifyContent: "center" }}>
        <Link to={componentPath}>
          <CommonButton btnType="default" size={"large"}>
            {t("common.button.back")}
          </CommonButton>
        </Link>
        <CommonButton
          btnType="primary"
          size={"large"}
          loading={isSubmitLoading}
          onClick={() => {
            form.submit();
          }}
        >
          {t("common.button.addNew")}
        </CommonButton>
      </Space>
    </div>
  );
}

export default GroupAddForm;
