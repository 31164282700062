import { Avatar, Dropdown, Space } from "antd";
import { useEffect, useMemo, useState } from "react";
import { LANGUAGE_LIST } from "../../utils/constans";
import LocalStorage from "../../utils/localStorage";
import { ILANGUAGE_OPTIONS_TYPE } from "../../utils/types";

function Language() {
  const [language, setLanguage] = useState<ILANGUAGE_OPTIONS_TYPE>();

  useEffect(() => {
    const localLanguage = LocalStorage.getInstance().read("language");
    const curLang =
      LANGUAGE_LIST.find((x: ILANGUAGE_OPTIONS_TYPE) => x.value === localLanguage) ||
      LANGUAGE_LIST[0];
    setLanguage(curLang);
  }, []);

  const handleOnChange = (lang: ILANGUAGE_OPTIONS_TYPE) => {
    setLanguage(lang);
    LocalStorage.getInstance().save("language", lang?.value);
    window.location.reload();
  };

  const itemsDropdownProfile = useMemo(() => {
    return LANGUAGE_LIST.map((x: ILANGUAGE_OPTIONS_TYPE) => ({
      key: x.value,
      label: (
        <div onClick={() => handleOnChange(x)}>
          <Avatar className="header-language-dropdown-item" src={x?.icon} /> {x.label}
        </div>
      ),
    }));
  }, []);

  return (
    <Dropdown menu={{ items: itemsDropdownProfile }} trigger={["click"]} placement="bottomRight">
      <Space className="cursor-pointer">
        <Avatar className="header-language" src={language?.icon} />
      </Space>
    </Dropdown>
  );
}

export default Language;
