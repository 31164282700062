import { Button, Modal } from "antd";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as WarningIcon } from "../../resources/images/warning.svg";

export interface ConfirmModalProps {
  visible: boolean;
  onOk?: () => void;
  onCancel?: () => void;
  showOk?: boolean;
  showCancel?: boolean;
  okText?: string;
  cancelText?: string;
  content?: ReactNode;
  title?: string;
  danger?: boolean;
  onClose?: () => void;
  isUpdate?: boolean;
  loadingBtnOk?: boolean;
  isShowIcon?: boolean;
}

const CommonConfirmModal = ({
  visible,
  onOk,
  onCancel,
  cancelText,
  okText,
  showCancel = true,
  showOk = true,
  content,
  title,
  danger = false,
  onClose,
  loadingBtnOk = false,
  isUpdate = false,
  isShowIcon = true,
}: ConfirmModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal
      className="confirm-modal"
      onCancel={isUpdate ? onClose : onCancel}
      open={visible}
      maskClosable={false}
      centered
      footer={[
        ...(showCancel
          ? [
              <Button key="back" onClick={onCancel} className="confirm-modal-btn-close">
                {cancelText || t("common.button.cancel")}
              </Button>,
            ]
          : []),
        ...(showOk
          ? [
              <Button
                key="accept"
                className="confirm-modal-btn-ok"
                onClick={onOk}
                type="primary"
                danger={danger}
                loading={loadingBtnOk}
              >
                {okText || t("common.button.accept")}
              </Button>,
            ]
          : []),
      ]}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        {isShowIcon && <WarningIcon className="confirm-modal-svg" />}
        <span className="confirm-modal-title">{title || t("common.button.confirm")}</span>

        {content && <div className="confirm-modal-content">{content}</div>}
      </div>
    </Modal>
  );
};

export default CommonConfirmModal;
