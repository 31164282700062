import { useTranslation } from "react-i18next";
import CommonButton from "../../components/Common/Button";
import { SIDEBAR_ITEM_HREF } from "../../utils/constans/sidebar";

function Index() {
  const { t } = useTranslation();
  return (
    <div
      style={{
        height: "100%",
        flexDirection: "column",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <a
        href={`${process.env.REACT_APP_DOWNLOAD_URL}/apk-download`}
        style={{ textDecoration: "none" }}
      >
        <CommonButton
          btnType="primary"
          size="large"
          // style={{ minWidth: 200, minHeight: 60, fontSize: 20 }}
          style={{ transform: "scale(1.5)" }}
          className="mgbt-45"
        >
          {t("common.button.installApp")}
        </CommonButton>
      </a>
      <a href={`${SIDEBAR_ITEM_HREF.home}`} style={{ textDecoration: "none" }}>
        <CommonButton
          btnType="default"
          size="large"
          style={{ transform: "scale(1.5)" }}
          className="mgt-45"
        >
          {t("common.button.backHome")}
        </CommonButton>
      </a>
    </div>
  );
}

export default Index;
