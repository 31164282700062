import type { TabsProps } from "antd";
import { Tabs } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CommonConfirmModal from "../../../components/Common/ConfirmModal";
import { useQuery } from "../../../utils/customHooks";
import { IUseQueryResponse } from "../../../utils/types";
import { buildQueryString } from "../../../utils/utilFunctions";
import AccountChangePassword from "./AccountChangePassword";
import AccountProfile from "./AccountProfile";

function Profile() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryObj: IUseQueryResponse = useQuery();
  const { params = {} } = queryObj;
  const { tab: tabQuery } = params;
  const nextKey = useRef<string>("");
  const accountProfileRef = useRef<any>(null);
  const accountChangePasswordRef = useRef<any>(null);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [activeKey, setActiveKey] = useState<string>("account-profile");

  useEffect(() => {
    setActiveKey(tabQuery);
  }, [tabQuery]);

  const items: TabsProps["items"] = [
    {
      key: "account-profile",
      label: t("profilePage.accountProfileTabTitle"),
      children: <AccountProfile ref={accountProfileRef} />,
    },
    {
      key: "change-password",
      label: t("profilePage.changePasswordTabTitle"),
      children: <AccountChangePassword ref={accountChangePasswordRef} />,
    },
  ];

  const onChange = (key: string) => {
    if (!accountProfileRef?.current?.isEdit && !accountChangePasswordRef?.current?.formChanged) {
      let queryString = buildQueryString({
        tab: key,
      });
      navigate(`${queryString || ""}`);
    }
  };

  const onTabClick = (key: string) => {
    if (key !== activeKey) {
      if (accountProfileRef?.current?.isEdit || accountChangePasswordRef?.current?.formChanged) {
        setModalVisible(true);
        nextKey.current = key;
      } else {
        setActiveKey(key);
      }
    }
  };

  const onOk = () => {
    if (accountProfileRef?.current?.isEdit && accountProfileRef?.current?.onClear) {
      accountProfileRef?.current?.onClear();
    }
    if (
      accountChangePasswordRef?.current?.formChanged &&
      accountChangePasswordRef?.current?.onClear
    ) {
      accountChangePasswordRef?.current?.onClear();
    }
    setActiveKey(nextKey.current);
    setModalVisible(false);
    let queryString = buildQueryString({
      tab: nextKey.current,
    });
    navigate(`${queryString || ""}`);
  };

  return (
    <div className="profile-body">
      <Tabs activeKey={activeKey} items={items} onChange={onChange} onTabClick={onTabClick} />
      {modalVisible ? (
        <CommonConfirmModal
          onOk={onOk}
          onCancel={() => setModalVisible(false)}
          visible={modalVisible}
          content={<span>{t("profilePage.message.messageConfirm")}</span>}
        />
      ) : (
        <></>
      )}
    </div>
  );
}

export default Profile;
