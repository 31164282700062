import { ICommonSelectDataType } from "../types";

export const GROUP_TYPE_VALUES = {
  WEB_SEV: 1,
  WEB_VENDOR: 2,
  APP_SEV: 3,
  APP_VENDOR: 4,
};
export const GROUP_TYPE_OPTIONS: ICommonSelectDataType[] = [
  {
    value: GROUP_TYPE_VALUES.WEB_SEV,
    label: "common.options.permissionSEV",
  },
  {
    value: GROUP_TYPE_VALUES.WEB_VENDOR,
    label: "common.options.permissionVendor",
  },
  {
    value: GROUP_TYPE_VALUES.APP_SEV,
    label: "common.options.permissionAppSEV",
  },
  {
    value: GROUP_TYPE_VALUES.APP_VENDOR,
    label: "common.options.permissionAppVendor",
  },
];
