import L, { LatLngExpression } from "leaflet";
import _ from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Marker, Popup, useMap } from "react-leaflet";
import { leafIcon } from "../../../../components/Map";
import journeyMarkerIcon from "../../../../resources/images/marker/journey_marker.png";
import locationMarkerIcon from "../../../../resources/images/marker/location_marker.png";
import journeyServices from "../../../../services/journeyManagement.service";
import { SAMSUNG_LOCATION, SEV_CODE } from "../../../../utils/constans";
import { ReactComponent as LocationIcon } from "../../../../resources/images/location.svg";
import {
  IGPSData,
  IJourneyDetailData,
  IRealTimeLocationData,
} from "../../../../utils/types/journeyManagement";
import Control from "react-leaflet-custom-control";
import { Button } from "antd";

const RealTimeControl = ({
  detailData,
  tabKey,
}: {
  detailData: IJourneyDetailData;
  tabKey: string;
}) => {
  const mapComponent = useMap();
  const { t } = useTranslation();
  const [currentGPS, setCurrenGPS] = useState<IGPSData>();
  const popupRefs = useRef<any>();
  const journeyMarker = leafIcon(journeyMarkerIcon);
  const locationMarker = leafIcon(locationMarkerIcon);
  const [currentListData, setCurrentListData] = useState<IRealTimeLocationData[]>(); //list tất cả vị trí
  const [isFly, setIsFly] = useState<boolean>(true);

  const getDataGPS = useCallback(async () => {
    if (detailData?.id && tabKey === "history-tab") {
      const resp = await journeyServices.getGPS([detailData?.id]);
      const data = resp?.data;
      if (resp?.status === 200) {
        const listData = Object.values(_.groupBy(data?.data, "registrationCarId"))?.map(
          (item) => item?.[0],
        );
        setCurrenGPS(listData?.[0]);
      } else {
        setIsFly(false);
        setCurrenGPS(undefined);
      }
      const respLine = await journeyServices.getListAllLocation(detailData?.id);
      const dataLine = respLine?.data;
      if (respLine?.status === 200) {
        const convertData = _.sortBy(dataLine?.data, "deviceTime");
        const firstTime = convertData?.[0]?.deviceTime || 0;
        const newData = convertData?.map((item: any) => ({
          ...item,
          timeCover: Math.abs(item?.deviceTime - firstTime) / 60000,
        }));
        setCurrentListData(newData);
      } else {
        setCurrentListData([]);
      }
    } else {
      setIsFly(false);
      setCurrenGPS(undefined);
      setCurrentListData([]);
    }
  }, [detailData?.id, tabKey]);

  // lấy lại vị trí sau 5s
  useEffect(() => {
    getDataGPS();
    const interVal = setInterval(getDataGPS, 5000);
    return () => {
      clearInterval(interVal);
    };
  }, [getDataGPS]);

  // mở Popup của chuyến
  useEffect(() => {
    if (currentGPS?.lat && currentGPS?.lon && isFly) {
      mapComponent.flyTo([currentGPS?.lat, currentGPS?.lon], 18);
      popupRefs.current?.openPopup();
    }
    setIsFly(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentGPS, isFly]);

  // vẽ đường đã đi được
  useEffect(() => {
    if (currentListData && currentListData?.length > 0) {
      L.polyline(currentListData?.map((item: any) => [item.lat, item.lon]) as LatLngExpression[], {
        color: "#4285F4",
        weight: 10,
        opacity: 1,
      }).addTo(mapComponent);
    }
  }, [currentListData, mapComponent]);

  return (
    <>
      <Control prepend position="topright">
        <Button onClick={() => setIsFly(true)} htmlType="button">
          <LocationIcon />
        </Button>
      </Control>
      {/* Ví trí hiện tại của xe */}
      {currentGPS && (
        <Marker
          position={[currentGPS?.lat, currentGPS?.lon] as LatLngExpression}
          icon={journeyMarker}
          alt="Marker alt"
          title={`${currentGPS?.plateNumber} (${detailData?.driverName})`}
          ref={popupRefs}
        >
          <Popup>
            <h3 className="mgbt-10">{`${t("journeyManagementPage.label.plateNumber")}: ${
              currentGPS?.plateNumber || "--"
            }`}</h3>
            <h3 className="mgbt-10">{`${t("journeyManagementPage.label.driverName")}: ${
              detailData?.driverName || "--"
            }`}</h3>
          </Popup>
        </Marker>
      )}

      {/* Vị trí SEV */}
      <Marker
        position={SAMSUNG_LOCATION as LatLngExpression}
        icon={locationMarker}
        alt="Marker alt"
        title={SEV_CODE}
      >
        <Popup>
          <h3 className="mgt-20 text-center">{SEV_CODE}</h3>
        </Popup>
      </Marker>

      {/* Vị trí Vendor */}
      {detailData?.vendor?.lat && detailData?.vendor?.lon ? (
        <Marker
          position={[detailData?.vendor?.lat, detailData?.vendor?.lon] as LatLngExpression}
          icon={locationMarker}
          alt="Marker alt"
          title={detailData?.vendor?.companyName || "--"}
        >
          <Popup>
            <h3 className="mgt-20 text-center">{detailData?.vendor?.companyName || "--"}</h3>
          </Popup>
        </Marker>
      ) : (
        <></>
      )}
    </>
  );
};

export default RealTimeControl;
