import { Col, Form, Input, List, Row, Space, notification } from "antd";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CommonButton from "../../../components/Common/Button";
import CommonForm from "../../../components/Common/Form";
import CommonFormItem from "../../../components/Common/FormItem";
import { ReactComponent as Checkbox } from "../../../resources/images/checkbox-circle.svg";
import { ReactComponent as Error } from "../../../resources/images/error.svg";
import userServices from "../../../services/accountManagement.service";
import { AT_LEAST_1_NUMBER, AT_LEAST_8_CHARS, REGEX_PASSWORD } from "../../../utils/constans";
import { SIDEBAR_ITEM_HREF } from "../../../utils/constans/sidebar";
import LocalStorage from "../../../utils/localStorage";

export type StatusTypes = "error" | "success" | "warning" | "info";

function AccountChangPassword(props: any, ref: any) {
  const { t } = useTranslation();
  const [activeIndexes, setActiveIndexes] = useState<number[]>([]);
  const [repeatError, setRepeatError] = useState<boolean>(false);
  const [formChanged, setFormChanged] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { profile } = useSelector((state: any) => state?.profileReducer);
  const [form] = Form.useForm();
  const newPassword = Form.useWatch("newPassword", form);

  useImperativeHandle(ref, () => ({
    formChanged,
    onClear,
  }));

  const ListItems = [
    t("profilePage.condition.atLeast8Chars"),
    // t('profilePage.condition.atLeast1Uppercase'),
    t("profilePage.condition.atLeast1Number"),
  ];

  useEffect(() => {
    if (newPassword) {
      let newActiveIndexes: number[] = [];
      if (newPassword.match(AT_LEAST_8_CHARS)) {
        newActiveIndexes.push(0);
      }
      // if (newPassword.match(atLeast1Uppercase)) {
      //     newActiveIndexes.push(1);
      // }
      if (newPassword.match(AT_LEAST_1_NUMBER)) {
        newActiveIndexes.push(1);
      }
      setActiveIndexes(newActiveIndexes);
    } else {
      setActiveIndexes([]);
    }
  }, [newPassword]);

  const onFinish = async (values: any) => {
    setIsLoading(true);
    const resp = await userServices.changePassword({ ...values, userId: profile.id });
    if (resp?.status === 200) {
      notification.success({
        message: t("profilePage.message.changePasswordSuccess"),
      });
      LocalStorage.getInstance().save("accessToken", null);
      window.location.href = SIDEBAR_ITEM_HREF.home;
    } else {
      notification.error({
        message: resp?.data?.message || t("commonError.oopsSystem"),
      });
    }
    setIsLoading(false);
  };

  const onValuesChange = (changedValues: any) => {
    setFormChanged(true);
  };

  const onCancel = () => {
    form.resetFields();
  };

  const onClear = () => {
    form.resetFields();
    setFormChanged(false);
  };

  return (
    <>
      <div className="profile-tab-content">
        <div className="profile-tab-content-header">
          <div className="profile-tab-content-header-left">
            {t("profilePage.changePasswordTabTitle")}
          </div>
        </div>
        <CommonForm
          form={form}
          layout="vertical"
          name="change-password"
          requiredMark={false}
          onFinish={onFinish}
          onValuesChange={onValuesChange}
          scrollToFirstError={{ behavior: "smooth" }}
        >
          <Row gutter={[16, 24]}>
            <Col span={12}>
              <CommonFormItem
                validateTrigger={["onChange", "onBlur"]}
                name="oldPassword"
                showRequiredIcon
                label={t("profilePage.label.currentPassword")}
                rules={[
                  {
                    required: true,
                    message: `${t("validate.input")} ${t("profilePage.label.currentPassword")}.`,
                  },
                ]}
              >
                <Input.Password
                  allowClear
                  placeholder={t("profilePage.placeholder.currentPassword") as string}
                />
              </CommonFormItem>
              <CommonFormItem
                validateTrigger={["onChange", "onBlur"]}
                name="newPassword"
                label={t("profilePage.label.newPassword")}
                rules={[
                  {
                    required: true,
                    message: `${t("validate.input")} ${t("profilePage.label.newPassword")}.`,
                  },
                  { pattern: REGEX_PASSWORD, message: "" },
                ]}
              >
                <Input.Password
                  allowClear
                  placeholder={t("profilePage.placeholder.newPassword") as string}
                />
              </CommonFormItem>
              <CommonFormItem
                style={{ marginBottom: 0 }}
                validateTrigger={["onChange", "onBlur"]}
                name="confirmPassword"
                label={t("profilePage.label.reNewPassword")}
                rules={[
                  {
                    required: true,
                    message: t("profilePage.validate.reNewPassword") as string,
                  },

                  ({ getFieldValue }: any) => ({
                    validator(_: any, value: any) {
                      if (!value) {
                        setRepeatError(false);
                        return Promise.reject();
                      }

                      if (getFieldValue("newPassword") !== value) {
                        setRepeatError(true);
                        return Promise.reject();
                      }
                      setRepeatError(false);
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input.Password
                  allowClear
                  placeholder={t("profilePage.placeholder.reNewPassword") as string}
                />
              </CommonFormItem>
              {repeatError ? (
                <p className="password-error">{t("profilePage.validate.mismatched")}</p>
              ) : (
                <></>
              )}
            </Col>
            <Col
              span={12}
              style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            >
              <div className="change-password-condition">
                <div className="change-password-condition-title">
                  {t("profilePage.label.requirementNewPassword")}
                </div>
                <List
                  split={false}
                  dataSource={ListItems}
                  renderItem={(item, index) => (
                    <List.Item
                      className={
                        activeIndexes.includes(index)
                          ? "password-condition-active"
                          : newPassword
                          ? "password-condition-error"
                          : ""
                      }
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      {newPassword && !activeIndexes.includes(index) ? (
                        <>
                          <Error />
                          {item}
                        </>
                      ) : (
                        <>
                          <Checkbox />
                          {item}
                        </>
                      )}
                    </List.Item>
                  )}
                />
              </div>
            </Col>
          </Row>
          <Space size={16} className="mgt-30">
            <CommonButton size={"small"} onClick={onCancel}>
              {t("common.button.cancel")}
            </CommonButton>
            <CommonButton size={"small"} loading={isLoading} btnType="primary" htmlType="submit">
              {t("common.button.update")}
            </CommonButton>
          </Space>
        </CommonForm>
      </div>
    </>
  );
}

export default forwardRef(AccountChangPassword);
