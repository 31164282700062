import { Tabs, TabsProps } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ROLE_LIST } from "../../../../utils/constans";
import { SIDEBAR_ITEM_HREF } from "../../../../utils/constans/sidebar";
import { useQuery } from "../../../../utils/customHooks";
import { IUseQueryResponse } from "../../../../utils/types";
import { IGroupTabProps } from "../../../../utils/types/groupManagement";
import { buildQueryString, isHavePermission } from "../../../../utils/utilFunctions";
import AccountTab from "./AccountTab";
import PermissionTab from "./PermissionTab";

function GroupTabs({ dataDetail, form, isEditing }: IGroupTabProps) {
  const componentPath = SIDEBAR_ITEM_HREF.group_management;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryObj: IUseQueryResponse = useQuery();
  const { params = {} } = queryObj;
  const { tabKey: tabKeyQuery } = params;
  const [activeKey, setActiveKey] = useState<string>("info-tab");

  const { profile } = useSelector((state: any) => state?.profileReducer);
  const isCanEdit = isHavePermission(ROLE_LIST.MANAGER_GROUP_EDIT, profile);
  const isCanViewInfo = isHavePermission(ROLE_LIST.MANAGER_GROUP_VIEW_DETAIL_INFO, profile);
  const isCanViewUser = isHavePermission(ROLE_LIST.MANAGER_GROUP_VIEW_DETAIL_ACCOUNT, profile);

  useEffect(() => {
    const newTabKey = tabKeyQuery
      ? tabKeyQuery
      : isCanViewInfo || isCanEdit
      ? "info-tab"
      : "account-tab";
    setActiveKey(newTabKey);
    if (newTabKey !== tabKeyQuery && dataDetail?.id) {
      navigate(
        `${componentPath}/detail/${dataDetail?.id}${buildQueryString({ tabKey: newTabKey }) || ""}`,
      );
    }
  }, [componentPath, dataDetail?.id, isCanEdit, isCanViewInfo, navigate, tabKeyQuery]);

  const items: TabsProps["items"] = useMemo(() => {
    let newItems = [];
    if (isCanViewInfo || isCanEdit) {
      newItems.push({
        key: "info-tab",
        label: t("groupManagementPage.permissionTitle"),
        children: <PermissionTab dataDetail={dataDetail} form={form} isEditing={isEditing} />,
      });
    }
    if (isCanViewUser) {
      newItems.push({
        key: "account-tab",
        label: t("groupManagementPage.accountTabTitle"),
        children: <AccountTab />,
      });
    }
    return newItems;
  }, [isCanViewInfo, isCanEdit, isCanViewUser, t, dataDetail, form, isEditing]);

  const onChange = (key: string) => {
    navigate(`${componentPath}/detail/${dataDetail?.id}${buildQueryString({ tabKey: key }) || ""}`);
  };
  return (
    <>
      {items?.length > 0 && (
        <div className="avic-search-box">
          <div className="advance-search-box">
            <Tabs activeKey={activeKey} items={items} onChange={onChange} />
          </div>
        </div>
      )}
    </>
  );
}

export default GroupTabs;
