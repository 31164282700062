import { ICommonSelectDataType } from "../types";

export const LOCK_STATUS: ICommonSelectDataType[] = [
  {
    value: 1,
    label: "online",
    type: "success",
  },
  {
    value: 2,
    label: "offline",
    type: "default",
  },
];

// trạng thái sóng
export const SIGNAL_STRENGTH_LEVEL: ICommonSelectDataType[] = [
  {
    value: 1,
    label: "common.options.strong",
  },
  {
    value: 2,
    label: "common.options.weak",
  },
  {
    value: 3,
    label: "common.options.medium",
  },
  {
    value: 4,
    label: "common.options.disconnected",
  },
];

// Dung lượng pin
export const BATTERY_LEVEL: ICommonSelectDataType[] = [
  {
    value: 1,
    label: "common.options.over80",
  },
  {
    value: 2,
    label: "50-80%",
  },
  {
    value: 3,
    label: "20-50%",
  },
  {
    value: 4,
    label: "common.options.under20",
  },
];
