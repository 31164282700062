import { Input } from "antd";
import { TextAreaProps } from "antd/es/input";

interface CommonTextAreaProps extends TextAreaProps {
  isView?: boolean;
}

const { TextArea } = Input;

const CommonTextArea = (props: CommonTextAreaProps) => {
  if (props?.isView)
    return <div className="avic-input-view">{(props?.value as string) || "--"}</div>;
  const childProps = { ...props };
  delete childProps?.isView;
  return (
    <TextArea
      style={{ resize: "none" }}
      {...childProps}
      allowClear
      maxLength={childProps?.maxLength || 1500}
      className={`avic-input  ${childProps?.className || ""}`}
    />
  );
};

export default CommonTextArea;
