import { ICommonSelectDataType } from "../types";

export const LOCATION_VALUES = {
  GATE_OUT: 200,
  GATE_IN: 100,
  WAREHOUSE: 50,
};

export const LOCATION_TYPES: ICommonSelectDataType[] = [
  {
    value: LOCATION_VALUES.GATE_OUT,
    label: "common.options.gateOut",
  },
  {
    value: LOCATION_VALUES.GATE_IN,
    label: "common.options.gateIn",
  },
  {
    value: LOCATION_VALUES.WAREHOUSE,
    label: "common.options.warehouse",
  },
];

export const LOCATION_BUILDINGS: ICommonSelectDataType[] = [
  { value: "Không thuộc tòa nhà", label: "Không thuộc tòa nhà" },
  { value: "Mobile 1", label: "Mobile 1" },
  { value: "Mobile 2", label: "Mobile 2" },
  { value: "Linh kiện 1", label: "Linh kiện 1" },
  { value: "Linh kiện 2", label: "Linh kiện 2" },
  { value: "Linh kiện 3", label: "Linh kiện 3" },
  { value: "Linh kiện 4", label: "Linh kiện 4" },
  { value: "MM", label: "MM" },
  { value: "Network", label: "Network" },
  { value: "Garbage Center", label: "Garbage Center" },
  { value: "Training Center", label: "Training Center" },
  { value: "Canteen 2", label: "Canteen 2" },
];
