import { BreadcrumbProps, Breadcrumb } from "antd";

interface CommonBreacrumbProps extends BreadcrumbProps {}

const CommonBreacrumb = (props: CommonBreacrumbProps) => {
  const childProps = { ...props };

  return (
    <Breadcrumb {...childProps} className={`avic-breadcrumb ${props?.className || ""} `}>
      {props?.children}
    </Breadcrumb>
  );
};

export default CommonBreacrumb;
