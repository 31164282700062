import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { Col, Form, Row, Space } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CommonButton from "../../components/Common/Button";
import CommonForm from "../../components/Common/Form";
import CommonFormItem from "../../components/Common/FormItem";
import CommonInput from "../../components/Common/Input";
import { ReactComponent as Search } from "../../resources/images/search-1.svg";
import { COMMON_PAGE_STATUS, DEFAULT_PAGE_NUMBER } from "../../utils/constans";
import { useQuery } from "../../utils/customHooks";
import { buildQueryString } from "../../utils/utilFunctions";
import { ISearchBoxProps, IUseQueryResponse } from "../../utils/types";
import { GROUP_TYPE_OPTIONS } from "../../utils/constans/groupManagement";

function SearchBox({ componentPath, getData }: ISearchBoxProps) {
  const navigate = useNavigate();
  const queryObj: IUseQueryResponse = useQuery();
  const { params = {}, search } = queryObj;
  const { advance: advanceQuery, search: searchQuery } = params;
  const searchQueryData = searchQuery ? JSON.parse(searchQuery) : {};
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [advance, setAdvance] = useState(advanceQuery === "true" || false);

  useEffect(() => {
    setAdvance(advanceQuery === "true");
  }, [advanceQuery]);

  const onFinish = (values: any) => {
    onSearch(values);
  };

  const onClear = (clearParams = {}) => {
    onSearch({ ...clearParams }, true);
    setTimeout(() => {
      form.resetFields();
    }, 100);
  };

  const onSearch = (data: any = {}, isClear?: boolean) => {
    const dataSearch = { ...data };
    if (data?.modifiedDate?.length) {
      dataSearch["modifiedDateFromLong"] = moment(data?.modifiedDate?.[0]).startOf("day").valueOf();
      dataSearch["modifiedDateToLong"] = moment(data?.modifiedDate?.[1]).endOf("day").valueOf();
    }
    delete dataSearch.advance;
    delete dataSearch?.modifiedDate;
    let query = {
      advance,
      page: DEFAULT_PAGE_NUMBER,
      search: JSON.stringify(dataSearch),
    };
    if (isClear) {
      query.advance = data.advance;
    }
    const queryString = buildQueryString(query);
    // detect if query not change => call api
    if (queryString !== search) {
      navigate(`${componentPath}${queryString}`);
    } else {
      if (getData) getData();
    }
  };

  return (
    <div className="avic-search-box">
      {!advance ? (
        <div className="normal-search-box">
          <CommonForm
            form={form}
            onFinish={onFinish}
            layout="horizontal"
            initialValues={{
              keyword: searchQueryData?.keyword,
            }}
          >
            <CommonFormItem name="keyword">
              <CommonInput
                placeholder={t("groupManagementPage.placeholder.advancedSearch") as string}
                prefix={
                  <div
                    onClick={() => {
                      onClear({ advance: true });
                    }}
                    className="open-advance-search-btn"
                  >
                    {t("groupManagementPage.label.advancedSearch")} <CaretDownOutlined />
                  </div>
                }
                addonAfter={
                  <div onClick={() => form.submit()} className="btn-normal-search">
                    <Search />
                  </div>
                }
              />
            </CommonFormItem>
          </CommonForm>
        </div>
      ) : (
        <div className="advance-search-box">
          <div
            className="close-advance-search-btn cursor-pointer"
            onClick={() => {
              // onClear()
              // setAdvance(false)
              onClear({ advance: false });
            }}
          >
            <div>{t("groupManagementPage.searchTitle")}</div>
            <CaretUpOutlined className="cursor-pointer" />
          </div>

          <CommonForm
            form={form}
            onFinish={onFinish}
            layout="vertical"
            initialValues={{
              groupName: searchQueryData?.groupName,
              modifiedBy: searchQueryData?.modifiedBy,
              modifiedDate:
                searchQueryData?.modifiedDateFromLong && searchQueryData?.modifiedDateToLong
                  ? [
                      moment(searchQueryData?.modifiedDateFromLong),
                      moment(searchQueryData?.modifiedDateToLong),
                    ]
                  : [],
              status: searchQueryData?.status,
            }}
          >
            <Row gutter={30}>
              <Col span={12}>
                <CommonFormItem
                  name="groupName"
                  label={t("groupManagementPage.label.name")}
                  placeholder={t("groupManagementPage.placeholder.name") as string}
                />
              </Col>
              <Col span={12}>
                <CommonFormItem
                  name="type"
                  label={t("groupManagementPage.label.type")}
                  placeholder={t("groupManagementPage.placeholder.type") as string}
                  options={GROUP_TYPE_OPTIONS.map((x) => ({ ...x, label: t(x.label) }))}
                  type="select"
                />
              </Col>
              <Col span={12}>
                <CommonFormItem
                  name="modifiedBy"
                  label={t("groupManagementPage.label.modifiedBy")}
                  placeholder={t("groupManagementPage.placeholder.modifiedBy") as string}
                />
              </Col>
              <Col span={12}>
                <CommonFormItem
                  name="modifiedDate"
                  type="rangePicker"
                  label={t("groupManagementPage.label.modifiedDate")}
                  placeholder={[t("common.fromDate"), t("common.toDate")]}
                />
              </Col>
              <Col span={12}>
                <CommonFormItem
                  name="status"
                  label={t("groupManagementPage.label.status")}
                  placeholder={t("groupManagementPage.placeholder.status") as string}
                  options={COMMON_PAGE_STATUS.map((x) => ({ ...x, label: t(x.label) }))}
                  type="select"
                />
              </Col>
            </Row>

            <Space className="form-btn-container">
              <CommonButton
                btnType="default"
                size={"small"}
                onClick={() => onClear({ advance: true })}
              >
                {t("common.button.deleteCondition")}
              </CommonButton>
              <CommonButton btnType="primary" size={"small"} htmlType="submit">
                {t("common.button.search")}
              </CommonButton>
            </Space>
          </CommonForm>
        </div>
      )}
    </div>
  );
}

export default SearchBox;
