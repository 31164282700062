import L, { LatLngExpression } from "leaflet";
import _ from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Marker, Popup, useMap } from "react-leaflet";
import { leafIcon } from "../../components/Map";
import journeyMarker from "../../resources/images/marker/journey_marker.png";
import journeyMarkerSelected from "../../resources/images/marker/journey_marker_selected.png";
import locationMarker from "../../resources/images/marker/location_marker.png";
import journeyServices from "../../services/journeyManagement.service";
import { SAMSUNG_LOCATION } from "../../utils/constans";
import {
  IGPSData,
  IJourneyDetailData,
  IOperateControlProps,
  IRealTimeLocationData,
} from "../../utils/types/journeyManagement";

const OperateControl = ({
  listJourney,
  currentJourney,
  setCurrentJourney,
}: IOperateControlProps) => {
  const mapComponent = useMap();
  const { t } = useTranslation();
  const [listGPS, setListGPS] = useState<IGPSData[]>();
  const popupRefs = useRef<any[]>([]);
  const polylineRefs = useRef<any>();
  const iconMarker = leafIcon(journeyMarker);
  const iconMarkerSelected = leafIcon(journeyMarkerSelected);
  const iconMarkerLocation = leafIcon(locationMarker);
  const [currentListData, setCurrentListData] = useState<IRealTimeLocationData[]>(); //list tất cả vị trí

  const getDataGPS = useCallback(async () => {
    if (listJourney?.length > 0) {
      const resp = await journeyServices.getGPS(
        listJourney?.map((item: IJourneyDetailData) => item?.id),
      );
      const data = resp?.data;
      if (resp?.status === 200) {
        const listData = Object.values(_.groupBy(data?.data, "registrationCarId"))?.map(
          (item) => item?.[0],
        );
        setListGPS(listData);
      } else {
        setListGPS([]);
      }

      if (currentJourney?.id) {
        const respLine = await journeyServices.getListAllLocation(currentJourney?.id);
        const dataLine = respLine?.data;
        if (respLine?.status === 200) {
          const convertData = _.sortBy(dataLine?.data, "deviceTime");
          const firstTime = convertData?.[0]?.deviceTime || 0;
          const newData = convertData?.map((item: any) => ({
            ...item,
            timeCover: Math.abs(item?.deviceTime - firstTime) / 60000,
          }));
          setCurrentListData(newData);
        } else {
          setCurrentListData([]);
        }
      } else {
        setCurrentListData([]);
      }
    } else {
      setCurrentListData([]);
      setListGPS([]);
    }
  }, [listJourney, currentJourney?.id]);

  useEffect(() => {
    getDataGPS();
    const interVal = setInterval(getDataGPS, 5000);
    return () => {
      clearInterval(interVal);
    };
  }, [getDataGPS]);

  mapComponent.on("click", function (event) {
    setCurrentJourney(undefined);
  });

  useEffect(() => {
    if (polylineRefs?.current) {
      mapComponent.removeLayer(polylineRefs.current);
    }
    if (currentListData && currentListData?.length > 0 && currentJourney) {
      polylineRefs.current = L.polyline(
        currentListData?.map((item: any) => [item.lat, item.lon]) as LatLngExpression[],
        {
          color: "#4285F4",
          weight: 10,
          opacity: 1,
        },
      ).addTo(mapComponent);
    }
  }, [currentListData, mapComponent, currentJourney]);

  // fly đến vị trí được chọn
  useEffect(() => {
    const curLocation = listGPS?.find(
      (item: any) => item?.registrationCarId === currentJourney?.id,
    );
    if (curLocation && curLocation?.lat && curLocation?.lon) {
      mapComponent.flyTo([curLocation?.lat, curLocation?.lon], 18);
      // mở popup chuyến được chọn
      const selectedIndex = listGPS?.findIndex(
        (item: any) => item?.registrationCarId === currentJourney?.id,
      );
      if (
        typeof selectedIndex === "number" &&
        selectedIndex >= 0 &&
        popupRefs.current[selectedIndex]
      ) {
        popupRefs.current[selectedIndex]?.openPopup();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentJourney]);

  // ! khi mở  popup thì map render lại xe  bị fly về vị trí  popup  => tạm note  tìm các fix sau
  // // mở Popup tất cả vị trí
  // useEffect(() => {
  //   popupRefs.current?.map((item: any, index: number) => {
  //     if (item) {
  //       item?.openPopup();
  //     }
  //     return item;
  //   });
  // }, [listGPS]);

  return (
    <>
      {/* list vị trí các chuyến */}
      {listGPS?.map((item: any, index: number) =>
        item?.lat && item?.lon ? (
          <Marker
            key={item?.registrationCarId}
            position={[item?.lat, item?.lon] as LatLngExpression}
            icon={item?.registrationCarId === currentJourney?.id ? iconMarkerSelected : iconMarker}
            alt="Marker alt"
            title={`${item?.plateNumber} (${item?.registrationCarId})`}
            ref={(el) => (popupRefs.current[index] = el)}
          >
            <Popup>
              <h3 className="mgbt-10">{`${t("journeyManagementPage.label.plateNumber")}: ${
                item?.plateNumber || "--"
              }`}</h3>
              <h3 className="mgbt-10">{`${t("journeyManagementPage.label.driverName")}: ${
                listJourney?.find((x) => x.id === item?.registrationCarId)?.driverName || "--"
              }`}</h3>
            </Popup>
          </Marker>
        ) : (
          <></>
        ),
      )}

      {/* Vị trí SEV */}
      <Marker
        position={SAMSUNG_LOCATION as LatLngExpression}
        icon={iconMarkerLocation}
        alt="Marker alt"
        title={'SEVT'}
      >
        <Popup>
          <h3 className="mgt-20 text-center">{'SEVT'}</h3>
        </Popup>
      </Marker>

      {/* Vị trí Vendor */}
      {currentJourney?.vendor?.lat && currentJourney?.vendor?.lon ? (
        <Marker
          position={[currentJourney?.vendor?.lat, currentJourney?.vendor?.lon] as LatLngExpression}
          icon={iconMarkerLocation}
          alt="Marker alt"
          title={currentJourney?.vendor?.companyName || "--"}
        >
          <Popup>
            <h3 className="mgt-20 text-center">{currentJourney?.vendor?.companyName || "--"}</h3>
          </Popup>
        </Marker>
      ) : (
        <></>
      )}
    </>
  );
};

export default OperateControl;
