import { Result } from "antd";
import { useTranslation } from "react-i18next";
import { SIDEBAR_ITEM_HREF } from "../../utils/constans/sidebar";
import CommonButton from "./../../components/Common/Button";
import icon404 from "./../../resources/images/dashboard/404.png";

function Page404() {
  const { t } = useTranslation();
  return (
    <div className="App">
      <Result
        title="404"
        subTitle={t("layout.title404")}
        icon={
          <div className="login-container" style={{ backgroundImage: `url(${icon404})` }}></div>
        }
        extra={
          <CommonButton
            btnType="primary"
            onClick={() => {
              window.location.href = SIDEBAR_ITEM_HREF.home;
            }}
          >
            {t("common.button.backHome")}
          </CommonButton>
        }
      />
    </div>
  );
}

export default Page404;
