import { Col, Row, TreeDataNode, notification } from "antd";
import { useWatch } from "antd/es/form/Form";
import { Key, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CommonFormItem from "../../../../components/Common/FormItem";
import CommonSpin from "../../../../components/Common/Spin";
import CommonTree from "../../../../components/Common/Tree";
import permissionsServices from "../../../../services/groupManagement.service";
import locationServices from "../../../../services/locationCategory.service";
import { GROUP_TYPE_OPTIONS, GROUP_TYPE_VALUES } from "../../../../utils/constans/groupManagement";
import { LOCATION_VALUES } from "../../../../utils/constans/locationCategory";
import { IAuthorityDetailData, IGroupTabProps } from "../../../../utils/types/groupManagement";
import { ILocationDetailData } from "../../../../utils/types/locationCategory";
import { isHavePermission, modifyArrTreePermission } from "../../../../utils/utilFunctions";
import CommonCheckbox from "../../../../components/Common/Checkbox";
import { useSelector } from "react-redux";
import { ROLE_LIST } from "../../../../utils/constans";

function PermissionTab({ dataDetail, form, isEditing }: IGroupTabProps) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [checkedLeftKeys, setCheckedLeftKeys] = useState<React.Key[]>([]);
  const [leftTreeData, setLeftTreeData] = useState<TreeDataNode[]>([]);
  const [rightTreeData, setRightTreeData] = useState<TreeDataNode[]>([]);
  const [checkedRightKeys, setCheckedRightKeys] = useState<React.Key[]>([]);
  const [permissionSecurityId, setPermissionSecurityId] = useState<number>(0);
  const [permissionSecurityGateInId, setPermissionSecurityGateInId] = useState<number>(0);
  const [permissionSecurityGateOutId, setPermissionSecurityGateOutId] = useState<number>(0);
  const [permissionSecurityVendorId, setPermissionSecurityVendorId] = useState<number>(0);
  const [positionSelectData, setPositionSelectData] = useState<ILocationDetailData[]>([]);
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);
  const [listFullAuth, setListFullAuth] = useState<IAuthorityDetailData[]>([]);
  const [isCheckedWebsite, setIsCheckedWebsite] = useState<boolean>(true);

  const { profile } = useSelector((state: any) => state?.profileReducer);
  const isCanEdit = isHavePermission(ROLE_LIST.MANAGER_GROUP_EDIT, profile);

  const typeWebsiteValue = useWatch("typeWebsite", form);

  useEffect(() => {
    if (form) {
      form?.setFieldValue("authorities", checkedLeftKeys?.concat(checkedRightKeys));
    }
  }, [form, checkedLeftKeys, checkedRightKeys]);

  const getData = useCallback(async () => {
    setIsLoading(true);
    const resp = await permissionsServices.getFullAuthority();
    const data = resp?.data;
    if (resp?.status === 200) {
      setListFullAuth(data?.data);
    } else {
      setListFullAuth([]);
      notification.error({
        message: data?.message || t("commonError.oopsSystem"),
      });
    }
    setIsLoading(false);
  }, [t]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (listFullAuth?.length) {
      const dataRight = listFullAuth?.filter(
        (item: IAuthorityDetailData) =>
          !["SECURITY", "SECURITY_GATEIN", "SECURITY_GATEOUT", "SECURITY_VENDOR"]?.includes(
            item?.authKey,
          ) && item?.type?.includes(typeWebsiteValue)
          // Ẩn tài khoản được sử dụng khoá và Tài khoản thuộc vendor được sử dụng khoá
          && item.id !== 31 && item.id !== 72 
      );
      const permissionSecurity = listFullAuth?.find(
        (item: IAuthorityDetailData) => item?.authKey === "SECURITY",
      );
      const permissionSecurityGateIn = listFullAuth?.find(
        (item: IAuthorityDetailData) => item?.authKey === "SECURITY_GATEIN",
      );
      const permissionSecurityGateOut = listFullAuth?.find(
        (item: IAuthorityDetailData) => item?.authKey === "SECURITY_GATEOUT",
      );
      const permissionSecurityVendor = listFullAuth?.find(
        (item: IAuthorityDetailData) => item?.authKey === "SECURITY_VENDOR",
      );
      setPermissionSecurityId(permissionSecurity?.id || 0);
      setPermissionSecurityGateInId(permissionSecurityGateIn?.id || 0);
      setPermissionSecurityGateOutId(permissionSecurityGateOut?.id || 0);
      setPermissionSecurityVendorId(permissionSecurityVendor?.id || 0);
      const newRightTreeData = modifyArrTreePermission(dataRight);
      setExpandedKeys(newRightTreeData?.map((item) => item?.key));
      setLeftTreeData(newRightTreeData);
      setRightTreeData([
        {
          title: permissionSecurity?.description,
          key: permissionSecurity?.id as number,
        },
        {
          title: permissionSecurityGateIn?.description,
          key: permissionSecurityGateIn?.id as number,
        },
        {
          title: permissionSecurityGateOut?.description,
          key: permissionSecurityGateOut?.id as number,
        },
        {
          title: permissionSecurityVendor?.description,
          key: permissionSecurityVendor?.id as number,
        },
      ]);
    } else {
      setPermissionSecurityId(0);
      setPermissionSecurityGateInId(0);
      setPermissionSecurityGateOutId(0);
      setPermissionSecurityVendorId(0);
      setExpandedKeys([]);
      setLeftTreeData([]);
      setRightTreeData([]);
    }
  }, [listFullAuth, typeWebsiteValue]);

  const getDataSelect = async () => {
    const paramsSearch = {
      page: 0,
      size: 1000,
      search: JSON.stringify({}),
      // search: JSON.stringify({ status: 0 }),
    };

    //select vị trí
    const respPosition = await locationServices.getPosition(paramsSearch);
    const dataPosition = respPosition?.data;
    if (respPosition?.status === 200) {
      setPositionSelectData(dataPosition?.data?.content);
    } else {
      setPositionSelectData([]);
    }
  };

  useEffect(() => {
    getDataSelect();
  }, []);

  useEffect(() => {
    if (dataDetail) {
      const selectedLeftKeys = dataDetail?.authorities
        ?.filter(
          (item) =>
            !["SECURITY", "SECURITY_GATEIN", "SECURITY_GATEOUT", "SECURITY_VENDOR"]?.includes(
              item?.authKey,
            ),
        )
        ?.map((item) => item?.id);
      const selectedRightKeys = dataDetail?.authorities
        ?.filter((item) =>
          ["SECURITY", "SECURITY_GATEIN", "SECURITY_GATEOUT", "SECURITY_VENDOR"]?.includes(
            item?.authKey,
          ),
        )
        ?.map((item) => item?.id);

      setCheckedLeftKeys(selectedLeftKeys);
      setCheckedRightKeys(selectedRightKeys);
      setIsCheckedWebsite(
        dataDetail?.type === GROUP_TYPE_VALUES?.WEB_SEV ||
          dataDetail?.type === GROUP_TYPE_VALUES?.WEB_VENDOR,
      );
    }
  }, [dataDetail]);

  const onCheckLeft = (checkedKeysValue: any, noteCheck: any) => {
    setCheckedLeftKeys(checkedKeysValue);
  };

  const onCheckRight = (checkedKeysValue: any, noteCheck: any) => {
    form?.setFieldValue("categoryPosition", undefined);
    if (noteCheck.checked) {
      setCheckedRightKeys([noteCheck.node.key]);
      if (noteCheck.node.key === permissionSecurityVendorId) {
        form?.setFieldValue("type", GROUP_TYPE_VALUES.APP_VENDOR);
      } else {
        form?.setFieldValue("type", GROUP_TYPE_VALUES.APP_SEV);
      }
    } else {
      setCheckedRightKeys([]);
    }
  };

  const onExpand = (expandedKeysValue: Key[]) => {
    setExpandedKeys(expandedKeysValue);
  };

  return (
    <Row className="group-line">
      <Col span={12} className="group-wrapper">
        <div className="group-item">
          <div className="group-sub-title">
            <CommonCheckbox
              disabled={!isCanEdit || !isEditing}
              checked={isCheckedWebsite}
              onChange={(checkedValue) => {
                setIsCheckedWebsite(!isCheckedWebsite);
                setCheckedRightKeys([]);
                form?.setFieldValue("categoryPosition", undefined);
                if (checkedValue?.target?.checked) {
                  form?.setFieldValue("type", typeWebsiteValue);
                }
              }}
            >
              {t("groupManagementPage.permissionWebTitle")}
            </CommonCheckbox>
          </div>
          <div>
            <CommonFormItem
              name="typeWebsite"
              options={GROUP_TYPE_OPTIONS?.filter((x) =>
                [GROUP_TYPE_VALUES.WEB_SEV, GROUP_TYPE_VALUES.WEB_VENDOR].includes(
                  x.value as number,
                ),
              ).map((x) => ({ ...x, label: t(x.label) }))}
              type="select"
              disabled={!isCheckedWebsite || !isCanEdit || !isEditing}
              onChange={(value: any) => {
                setCheckedLeftKeys([]);
                form?.setFieldValue("type", value);
              }}
            />
            <CommonSpin isLoading={isLoading}>
              <CommonTree
                key="leftTree"
                checkable
                selectable={false}
                showLine={true}
                onCheck={onCheckLeft}
                checkedKeys={checkedLeftKeys}
                // Ẩn Quản lý Vendor ở tab Quản lý nhóm quyền
                treeData={leftTreeData.filter((v)=> v.key !== 3 && v.key !== 7)}
                onExpand={onExpand}
                expandedKeys={expandedKeys}
                disabled={!isCheckedWebsite || !isCanEdit || !isEditing}
              />
            </CommonSpin>
          </div>
        </div>
      </Col>
      <Col span={12} className="group-wrapper">
        <div className="group-item">
          <div className="group-sub-title">
            <CommonCheckbox
              disabled={!isCanEdit || !isEditing}
              checked={!isCheckedWebsite}
              onChange={(checkedValue) => {
                setIsCheckedWebsite(!isCheckedWebsite);
                setCheckedLeftKeys([]);
                if (!checkedValue?.target?.checked) {
                  form?.setFieldValue("type", isCheckedWebsite);
                }
              }}
            >
              {t("groupManagementPage.permissionAppTitle")}
            </CommonCheckbox>
          </div>
          <div>
            {checkedRightKeys?.includes(permissionSecurityId) ? (
              <CommonFormItem
                name="categoryPosition"
                label={t("groupManagementPage.label.categoryPosition")}
                placeholder={t("groupManagementPage.placeholder.categoryPosition") as string}
                showRequiredIcon={isCanEdit && isEditing}
                disabled={!isCanEdit || !isEditing}
                options={positionSelectData
                  ?.filter((item: ILocationDetailData) => item.type === LOCATION_VALUES.WAREHOUSE)
                  ?.map((item: ILocationDetailData) => ({
                    value: item.id,
                    label: item.name + " - " + item.address,
                  }))}
                type="select"
                allowClear
                rules={[
                  {
                    required: true,
                    message: `${t("validate.select")} ${t(
                      "groupManagementPage.label.categoryPosition",
                    )}.`,
                  },
                ]}
              />
            ) : (
              <></>
            )}
            {checkedRightKeys?.includes(permissionSecurityGateInId) ? (
              <CommonFormItem
                name="categoryPosition"
                label={t("groupManagementPage.label.categoryPositionGateIn")}
                placeholder={t("groupManagementPage.placeholder.categoryPositionGateIn") as string}
                showRequiredIcon={isCanEdit && isEditing}
                disabled={!isCanEdit || !isEditing}
                options={positionSelectData
                  ?.filter((item: ILocationDetailData) => item.type === LOCATION_VALUES.GATE_IN)
                  ?.map((item: ILocationDetailData) => ({
                    value: item.id,
                    label: item.name + " - " + item.address,
                  }))}
                type="select"
                allowClear
                rules={[
                  {
                    required: true,
                    message: `${t("validate.select")} ${t(
                      "groupManagementPage.label.categoryPositionGateIn",
                    )}.`,
                  },
                ]}
              />
            ) : (
              <></>
            )}
            {checkedRightKeys?.includes(permissionSecurityGateOutId) ? (
              <CommonFormItem
                name="categoryPosition"
                label={t("groupManagementPage.label.categoryPositionGateOut")}
                placeholder={t("groupManagementPage.placeholder.categoryPositionGateOut") as string}
                showRequiredIcon={isCanEdit && isEditing}
                disabled={!isCanEdit || !isEditing}
                options={positionSelectData
                  ?.filter((item: ILocationDetailData) => item.type === LOCATION_VALUES.GATE_OUT)
                  ?.map((item: ILocationDetailData) => ({
                    value: item.id,
                    label: item.name + " - " + item.address,
                  }))}
                type="select"
                allowClear
                rules={[
                  {
                    required: true,
                    message: `${t("validate.select")} ${t(
                      "groupManagementPage.label.categoryPositionGateOut",
                    )}.`,
                  },
                ]}
              />
            ) : (
              <></>
            )}
            a
            <CommonSpin isLoading={isLoading}>
              <CommonTree
                key="rightTree"
                checkable
                selectable={false}
                showLine={true}
                onCheck={onCheckRight}
                checkedKeys={checkedRightKeys}
                treeData={rightTreeData.filter(v => v.key !== 68)}
                disabled={isCheckedWebsite || !isCanEdit || !isEditing}
              />
            </CommonSpin>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default PermissionTab;
