// Api danh mục loại xe
import APIService from "../utils/apiServices";
import { ICommonSearchParams } from "../utils/types";
import {
  IVendorManagementAddData,
  IVendorManagementDetailData,
} from "../utils/types/vendorManagement";

class VendorManagementServices extends APIService {
  // Lấy danh sách
  async getListVendorManagement(params: ICommonSearchParams) {
    let apiParams = {
      page: params.page || 0,
      size: params.size || 10,
      sortType: params.sortType || "DESC",
      sortBy: params.sortBy || "modifiedDate",
      search: params.search || JSON.stringify({}),
    };
    return await this.request("GET", `/api/v1/vendor/getPage`, {}, { params: apiParams });
  }

  // chi tiết
  async detailVendorManagement(id: number) {
    return await this.request("GET", `/api/v1/vendor/${id}`);
  }

  // Thêm mới
  async createVendorManagement(data: IVendorManagementAddData) {
    return await this.request("POST", `/api/v1/vendor`, data);
  }

  // Chỉnh sửa
  async updateVendorManagement(id: number, data: IVendorManagementDetailData) {
    return await this.request("PUT", `/api/v1/vendor/${id}`, data);
  }

  // Xóa một
  async deleteVendorManagement(id: number) {
    return await this.request("DELETE", `/api/v1/vendor/${id}`);
  }

  // Xóa nhiều
  async deletesVendorManagement(data: number[]) {
    return await this.request("DELETE", `/api/v1/vendor/delete`, data);
  }

  // Xuất excel
  async exportVendorManagement(params: ICommonSearchParams) {
    return await this.request(
      "GET",
      `/api/v1/vendor/export`,
      {},
      {
        responseType: "blob",
        params: params,
      },
    );
  }
}
const service = new VendorManagementServices();
export default service;
