import { Col, Form, Row, message, notification } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import CommonButton from "../../components/Common/Button";
import CommonForm from "../../components/Common/Form";
import CommonFormItem from "../../components/Common/FormItem";
import lockServices from "../../services/lockManagement.service";
import { REGEX_ONLY_NUMBER } from "../../utils/constans";
import CommonModal from "./../../components/Common/Modal";
import { ILockCreateModalProps } from "./../../utils/types/lockManagement";

const LockCreateModal = ({ visible, handleOk, handleCancel }: ILockCreateModalProps) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onFinish = (values: any) => {
    onCreateLock(values);
  };

  const onCreateLock = async (values: any) => {
    setIsLoading(true);
    console.log(values)
    const resp = await lockServices.createLock(values);
    const data = resp?.data;
    if (resp?.status === 200) {
      notification.success({
        message: t("lockManagementPage.message.createSuccess"),
      });
      handleOk();
    } else {
      notification.error({
        message: data?.message || t("commonError.oopsSystem"),
      });
    }
    setIsLoading(false);
  };

  return (
    <CommonModal
      className="lock-modal-create"
      title={t("lockManagementPage.addTitle")}
      centered
      open={visible}
      maskClosable={false}
      onCancel={handleCancel}
      footer={[
        <CommonButton key="cancel" onClick={handleCancel} size={"small"} btnType="default">
          {t("common.button.back")}
        </CommonButton>,
        <CommonButton
          form="myForm"
          key="submit"
          onClick={() => form.submit()}
          btnType="primary"
          size={"small"}
          loading={isLoading}
        >
          {t("common.button.addLockNew")}
        </CommonButton>,
      ]}
    >
      <CommonForm form={form} onFinish={onFinish} layout="vertical">
        <Row gutter={30}>
          <Col span={24}>
            <CommonFormItem
              name="name"
              label={t("lockManagementPage.label.nameLock")}
              placeholder={t("lockManagementPage.placeholder.name") as string}
              showRequiredIcon
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: `${t("validate.input")} ${t("lockManagementPage.label.nameLock")}.`
                },
              ]}
            />
            <CommonFormItem
              name="uniqueid"
              label={t("lockManagementPage.label.uniqueidNew")}
              placeholder={t("lockManagementPage.placeholder.uniqueidNew") as string}
              showRequiredIcon
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: `${t("validate.input")} ${t("lockManagementPage.label.uniqueidNew")}.`,
                },
                {
                  pattern: REGEX_ONLY_NUMBER,
                  message: `${t("lockManagementPage.label.uniqueidNew")} ${t(
                    "validate.onlyNumber",
                  )}.`,
                },
              ]}
            />
          </Col>
        </Row>
      </CommonForm>
    </CommonModal>
  );
};

export default LockCreateModal;
