import { notification } from "antd";
import { LatLngExpression } from "leaflet";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Marker, Popup, useMap } from "react-leaflet";
import { useSelector } from "react-redux";
import CommonButton from "../../components/Common/Button";
import CommonConfirmModal from "../../components/Common/ConfirmModal";
import { leafIcon } from "../../components/Map";
import markerAdd from "../../resources/images/marker/marker-add.png";
import marker from "../../resources/images/marker/marker-icon.png";
import markerSelected from "../../resources/images/marker/marker_selected.png";
import locationServices from "../../services/locationCategory.service";
import { ROLE_LIST } from "../../utils/constans";
import { ILocationControlProps, ILocationDetailData } from "../../utils/types/locationCategory";
import { isHavePermission } from "../../utils/utilFunctions";
import LocationAddModal from "./LocationAddModal";

const LocationControl = ({ listLocation, currentLocation, getData }: ILocationControlProps) => {
  const mapComponent = useMap();
  const { t } = useTranslation();
  const newMarkerRef = useRef<any>(null);
  const [newLocation, setNewLocation] = useState<LatLngExpression | undefined>(undefined);
  const [visible, setVisible] = useState<boolean>(false);
  const [visibleDelete, setVisibleDelete] = useState<boolean>(false);
  const [loadingBtnOk, setLoadingBtnOk] = useState<boolean>(false);
  const [dataDetail, setDataDetail] = useState<ILocationDetailData>();
  const popupRefs = useRef<any[]>([]);

  const { profile } = useSelector((state: any) => state?.profileReducer);
  const isCanCreate = isHavePermission(ROLE_LIST.MANAGER_POSITION_CREATE, profile);
  const isCanViewDetail = isHavePermission(ROLE_LIST.MANAGER_POSITION_VIEW_DETAIL, profile);
  const isCanEdit = isHavePermission(ROLE_LIST.MANAGER_POSITION_EDIT, profile);
  const isCanDelete = isHavePermission(ROLE_LIST.MANAGER_POSITION_DELETE, profile);

  const iconMarker = leafIcon(marker);
  const iconMarkerAdd = leafIcon(markerAdd);
  const iconMarkerSelected = leafIcon(markerSelected);

  useEffect(() => {
    setDataDetail(currentLocation);
  }, [currentLocation]);

  // fly đến vị trí được chọn
  useEffect(() => {
    if (dataDetail && dataDetail?.latitude && dataDetail?.longitude) {
      mapComponent.flyTo([dataDetail?.latitude, dataDetail?.longitude], 18);
    }
  }, [dataDetail, mapComponent]);

  // Xử lý sự kiện khi chuột phải được nhấp vào bản đồ => render một marker mới
  mapComponent.on("contextmenu", function (event) {
    if (isCanCreate) {
      setNewLocation(event.latlng);
      handleCancel();
    }
  });
  mapComponent.on("click", function (event) {
    handleCancel();
  });
  useEffect(() => {
    if (newMarkerRef.current) {
      const marker = newMarkerRef.current;
      // Open the popup when the marker is rendered
      marker.openPopup();
      // Bắt sự kiện "popupclose"
      marker.on("popupclose", function () {
        setNewLocation(undefined);
      });
    }
  }, [newLocation]);

  // mở Popup vị trí được chọn
  useEffect(() => {
    const selectedIndex = listLocation?.findIndex((item) => item?.id === dataDetail?.id);
    if (
      typeof selectedIndex === "number" &&
      selectedIndex >= 0 &&
      popupRefs.current[selectedIndex]
    ) {
      popupRefs.current[selectedIndex]?.openPopup();
    }
  }, [dataDetail, listLocation]);

  const handleOk = () => {
    handleCancel();
    setNewLocation(undefined);
    getData();
  };

  const handleDelete = async () => {
    setLoadingBtnOk(true);
    const resp = await locationServices.deletePosition([dataDetail?.id as number]);
    const data = resp?.data;
    if (resp?.status === 200) {
      notification.success({
        message: t("locationCategoryPage.message.deleteSuccess"),
      });
      handleOk();
    } else {
      notification.error({
        message: data?.message || t("commonError.oopsSystem"),
      });
    }
    setLoadingBtnOk(false);
  };

  const handleCancel = () => {
    setVisible(false);
    setVisibleDelete(false);
    setDataDetail(undefined);
  };

  return (
    <>
      {newLocation && isCanCreate && (
        <Marker
          position={newLocation as LatLngExpression}
          icon={iconMarkerAdd}
          alt="Marker alt"
          title="marker alt"
          ref={newMarkerRef}
        >
          <Popup closeOnClick={true}>
            <CommonButton
              btnType="primary"
              size={"small"}
              onClick={() => {
                setVisible(true);
              }}
            >
              {t("common.button.addNew")}
            </CommonButton>
          </Popup>
        </Marker>
      )}

      {listLocation?.length > 0 ? (
        listLocation?.map((item, index) =>
          item?.latitude && item?.longitude ? (
            <Marker
              key={item?.id}
              position={[item?.latitude, item?.longitude] as LatLngExpression}
              icon={item?.id === dataDetail?.id ? iconMarkerSelected : iconMarker}
              alt="Marker alt"
              title={item?.name}
              ref={(el) => (popupRefs.current[index] = el)}
            >
              <Popup>
                <h3 className="mgbt-10 text-center"> {item?.name}</h3>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {(isCanViewDetail || isCanEdit) && (
                    <CommonButton
                      btnType="default"
                      size={"small"}
                      onClick={() => {
                        setVisible(true);
                        setDataDetail(item);
                      }}
                      className={isCanDelete ? "mgr-10" : ""}
                    >
                      {t("common.button.detail")}
                    </CommonButton>
                  )}
                  {isCanDelete && (
                    <CommonButton
                      btnType="danger"
                      size={"small"}
                      onClick={() => {
                        setVisibleDelete(true);
                        setDataDetail(item);
                      }}
                    >
                      {t("common.button.remove")}
                    </CommonButton>
                  )}
                </div>
              </Popup>
            </Marker>
          ) : (
            <></>
          ),
        )
      ) : (
        <></>
      )}

      {visible ? (
        <LocationAddModal
          visible={visible}
          handleOk={handleOk}
          handleCancel={() => setVisible(false)}
          newLocation={newLocation}
          dataDetail={dataDetail}
        />
      ) : (
        <></>
      )}

      {visibleDelete ? (
        <CommonConfirmModal
          content={t("locationCategoryPage.message.delete", { location: dataDetail?.name })}
          visible={visibleDelete}
          onOk={handleDelete}
          onCancel={() => {
            setVisibleDelete(false);
          }}
          loadingBtnOk={loadingBtnOk}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default LocationControl;
