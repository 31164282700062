import { Col, Form, Row, Space, notification } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import CommonButton from "../../../components/Common/Button";
import CommonForm from "../../../components/Common/Form";
import CommonFormItem from "../../../components/Common/FormItem";
import permissionsServices from "../../../services/groupManagement.service";
import { COMMON_PAGE_STATUS, ROLE_LIST } from "../../../utils/constans";
import { GROUP_TYPE_VALUES } from "../../../utils/constans/groupManagement";
import { SIDEBAR_ITEM_HREF } from "../../../utils/constans/sidebar";
import { IGroupAddData, IGroupDetailData } from "../../../utils/types/groupManagement";
import { isHavePermission } from "../../../utils/utilFunctions";
import GroupTabs from "./GroupTabs";

function GroupDetail() {
  const componentPath = SIDEBAR_ITEM_HREF.group_management;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const params = useParams();
  const [dataDetail, setDataDetail] = useState<IGroupDetailData>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const { profile } = useSelector((state: any) => state?.profileReducer);
  const isCanEdit = isHavePermission(ROLE_LIST.MANAGER_GROUP_EDIT, profile);
  const isCanViewInfo = isHavePermission(ROLE_LIST.MANAGER_GROUP_VIEW_DETAIL_INFO, profile);

  const getDataDetail = async () => {
    setIsLoading(true);
    if (params?.id) {
      const resp = await permissionsServices.getPermissionById(parseInt(params?.id));
      const data = resp?.data;
      if (resp?.status === 200) {
        setDataDetail(data?.data);
        form.setFieldsValue({
          groupName: data?.data?.groupName,
          description: data?.data?.description,
          status: data?.data?.status,
          categoryPosition: data?.data?.categoryPosition?.id,
          type: data?.data?.type || GROUP_TYPE_VALUES.WEB_SEV,
          typeWebsite:
            data?.data?.type === GROUP_TYPE_VALUES.WEB_VENDOR
              ? GROUP_TYPE_VALUES.WEB_VENDOR
              : GROUP_TYPE_VALUES.WEB_SEV,
        });
      } else {
        notification.error({
          message: data?.message || t("commonError.oopsSystem"),
        });
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getDataDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = async (values: any) => {
    if (values?.authorities?.length === 0) {
      notification.error({
        message: t("groupManagementPage.message.permissionRequired"),
      });
      return;
    }
    setIsSubmitLoading(true);
    const body: IGroupAddData = {
      ...dataDetail,
      description: values?.description,
      authorities: values?.authorities?.map((item: number) => ({ id: item })),
      groupName: values?.groupName,
      status: values?.status,
      categoryPosition: values?.categoryPosition ? { id: values?.categoryPosition } : null,
      type: values?.type,
    };
    const resp = await permissionsServices.updatePermission(dataDetail?.id as number, body);
    const data = resp?.data;
    if (resp?.status === 200) {
      notification.success({
        message: t("groupManagementPage.message.editSuccess"),
      });
      setIsEditing(false);
      navigate(`${componentPath}`);
    } else {
      notification.error({
        message: data?.message || t("commonError.oopsSystem"),
      });
    }
    setIsSubmitLoading(false);
  };

  return (
    <div className="box-wrapper">
      <CommonForm form={form} onFinish={onFinish} layout="vertical" isLoading={isLoading}>
        {(isCanEdit || isCanViewInfo) && (
          <div className="avic-search-box">
            <div className="advance-search-box">
              <div className="form-title">{t("groupManagementPage.editTitle")}</div>
              <div>
                <div>
                  {t("groupManagementPage.label.modifiedDate")}:{" "}
                  {dataDetail?.modifiedDateStr || "--"}
                </div>
                <div>
                  {t("groupManagementPage.label.modifiedBy")}: {dataDetail?.modifiedBy || "--"}
                </div>
              </div>
              <div className="group-box">
                <Row gutter={30}>
                  <Col span={12}>
                    <CommonFormItem
                      name="groupName"
                      disabled={!isCanEdit || !isEditing}
                      showRequiredIcon={isCanEdit && isEditing}
                      maxLength={128}
                      label={t("groupManagementPage.label.name")}
                      placeholder={t("groupManagementPage.placeholder.name") as string}
                      rules={[
                        {
                          whiteSpace: true,
                          required: true,
                          message: `${t("validate.input")} ${t("groupManagementPage.label.name")}.`,
                        },
                      ]}
                    />
                  </Col>
                  <Col span={12}>
                    <CommonFormItem
                      name="status"
                      label={t("groupManagementPage.label.status")}
                      placeholder={t("groupManagementPage.placeholder.status") as string}
                      options={COMMON_PAGE_STATUS.map((x) => ({ ...x, label: t(x.label) }))}
                      type="select"
                      disabled={!isCanEdit || !isEditing}
                      showRequiredIcon={isCanEdit && isEditing}
                      rules={[
                        {
                          required: true,
                          message: `${t("validate.select")} ${t(
                            "groupManagementPage.label.status",
                          )}.`,
                        },
                      ]}
                    />
                  </Col>
                  <Col span={24}>
                    <CommonFormItem
                      name="description"
                      type="textArea"
                      rows={4}
                      maxLength={128}
                      label={t("groupManagementPage.label.description")}
                      placeholder={t("groupManagementPage.placeholder.description") as string}
                      disabled={!isCanEdit || !isEditing}
                    />
                  </Col>
                  {/* chỉ để lưu quyền chọn => ẩn đi  */}
                  <Col span={24} style={{ visibility: "hidden", zIndex: -1, position: "fixed" }}>
                    <CommonFormItem name="authorities" options={[]} type="select" mode="multiple" />
                    <CommonFormItem name="type" />
                  </Col>
                  {/* */}
                </Row>
              </div>
            </div>
          </div>
        )}
        <GroupTabs dataDetail={dataDetail} form={form} isEditing={isEditing} />
      </CommonForm>

      <Space className="form-btn-container" style={{ justifyContent: "center" }}>
        {isEditing ? (
          <CommonButton onClick={() => setIsEditing(false)} size={"large"} btnType="default">
            {t("common.button.cancel")}
          </CommonButton>
        ) : (
          <Link to={componentPath}>
            <CommonButton btnType="default" size={"large"}>
              {t("common.button.exit")}
            </CommonButton>
          </Link>
        )}
        {isCanEdit ? (
          isEditing ? (
            <CommonButton
              loading={isSubmitLoading}
              btnType="primary"
              size={"large"}
              onClick={() => {
                form.submit();
              }}
            >
              {t("common.button.update")}
            </CommonButton>
          ) : (
            <CommonButton btnType="primary" size={"large"} onClick={() => setIsEditing(true)}>
              {t("common.button.edit")}
            </CommonButton>
          )
        ) : (
          <></>
        )}
      </Space>
    </div>
  );
}

export default GroupDetail;
