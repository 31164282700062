import { Col, Form, Row, Space, notification } from "antd";
import { useWatch } from "antd/es/form/Form";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import CommonButton from "../../../components/Common/Button";
import CommonConfirmModal from "../../../components/Common/ConfirmModal";
import CommonForm from "../../../components/Common/Form";
import CommonFormItem from "../../../components/Common/FormItem";
import lockServices from "../../../services/lockManagement.service";
import vendorServices from "../../../services/vendorManagement.service";
import { ROLE_LIST, SEV_CODE } from "../../../utils/constans";
import { LOCK_STATUS, SIGNAL_STRENGTH_LEVEL } from "../../../utils/constans/lockManagement";
import { SIDEBAR_ITEM_HREF } from "../../../utils/constans/sidebar";
import {
  IDecentralizationVendorUseLock,
  ILockDetailData,
} from "../../../utils/types/lockManagement";
import { IVendorManagementDetailData } from "../../../utils/types/vendorManagement";
import LockTabs from "./LockTabs";
import { isHavePermission } from "../../../utils/utilFunctions";

function LockDetail() {
  const componentPath = SIDEBAR_ITEM_HREF.lock_management;
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const [visible, setVisible] = useState<boolean>(false);
  const [visibleUnlock, setVisibleUnlock] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingConfirm, setIsLoadingConfirm] = useState<boolean>(false);
  const [isLoadingConfirmUnlock, setIsLoadingConfirmUnlock] = useState<boolean>(false);
  const [dataDetail, setDataDetail] = useState<ILockDetailData>();
  const [vendorDetail, setVendorDetail] = useState<IVendorManagementDetailData>();
  // const [vendorSelectData, setVendorSelectData] = useState<ICommonSelectDataType[]>([]);
  const { profile } = useSelector((state: any) => state?.profileReducer);
  const isSEV = profile?.source === SEV_CODE;
  // const isCanAssign = isHavePermission(ROLE_LIST.MANAGER_LOCK_TO_USER, profile);
  const isCanCViewDetail = isHavePermission(ROLE_LIST.MANAGER_LOCK_DETAIL_INFO, profile);
  const sourceValue = useWatch("source", form);
  // Thêm trạng thái chỉnh sửa 
  const [isEditing, setIsEditing] = useState<boolean>(false)
  // Mỗi 5s sẽ load lại dữ liệu 1 lần
  // Xác định load lần đầu và những lần sau không hiển thị loadding
  let isFirtLoad = false

  useEffect(() => {
    getDataDetail();
    // getDataSelect();
    // const interval = setInterval(getDataDetail, 5000)
    // return () => clearInterval(interval)
  }, []);

  // const getDataSelect = async () => {
  //   if (isSEV) {
  //     const paramsSearch = {
  //       page: 0,
  //       size: 1000,
  //       search: JSON.stringify({}),
  //     };

  //     select công ty quản lý
  //     const respVendor = await vendorServices.getListVendorManagement(paramsSearch);
  //     const dataVendor = respVendor?.data;
  //     if (respVendor?.status === 200) {
  //       setVendorSelectData(
  //         dataVendor?.data?.content?.map((item: IVendorManagementDetailData) => ({
  //           value: item?.vendorCode,
  //           label: item?.companyName,
  //         })),
  //       );
  //     } else {
  //       setVendorSelectData([]);
  //     }
  //   }
  // };

  const getDataDetail = async () => {
    if (!isFirtLoad) {
      setIsLoading(true);
      isFirtLoad = true
    }
    if (params?.id) {
      const resp = await lockServices.detailLock(parseInt(params?.id));
      const data = resp?.data;
      if (resp?.status === 200) {
        setDataDetail(data?.data);
        const dongLapLung =
          data?.data?.tcPosition?.donglaplung === 1
            ? t("lockManagementPage.lockStatus.closeBackCover")
            : data?.data?.tcPosition?.donglaplung === 0
              ? t("lockManagementPage.lockStatus.openBackCover")
              : "";
        const dongdaycap =
          data?.data?.tcPosition?.dongdaycap === 1
            ? t("lockManagementPage.lockStatus.closeZipper")
            : data?.data?.tcPosition?.dongdaycap === 0
              ? t("lockManagementPage.lockStatus.openZipper")
              : "";
        const cutcable = data?.data?.tcPosition?.cutcable
          ? t("lockManagementPage.lockStatus.brokenLockWire")
          : "";
        const curStatusLock = [dongLapLung, dongdaycap, cutcable]
          ?.filter((item) => item)
          ?.join(", ");

        form.setFieldsValue({
          name: data.data.name,
          uniqueid: data?.data?.uniqueid,
          battery:
            typeof data?.data?.battery === "number"
              ? data?.data?.battery === 255
                ? "Charging"
                : `${data?.data?.battery}%`
              : "--",
          source: data?.data?.vendor?.vendorCode || "--",
          statusLock: curStatusLock || "--",
          status: data?.data?.statusConvert || "--",
          signalStrengthLevel: data?.data?.signalStrengthLevel || "--",
          note: data?.data?.note,
        });
      } else {
        notification.error({
          message: data?.message || t("commonError.oopsSystem"),
        });
      }
    }
    setIsLoading(false);
  };

  const getVendorDetail = useCallback(async () => {
    if (sourceValue) {
      const paramsSearch = {
        page: 0,
        size: 10,
        search: JSON.stringify({ vendorCode: sourceValue }),
      };
      const resp = await vendorServices.getListVendorManagement(paramsSearch);
      const data = resp?.data;
      if (resp?.status === 200) {
        const curVendor = data?.data?.content?.[0];
        setVendorDetail(curVendor);
        form.setFieldsValue({
          taxCode: curVendor?.taxCode || "--",
        });
      } else {
        setVendorDetail(undefined);
        form.setFieldsValue({
          taxCode: "--",
        });
      }
    } else {
      setVendorDetail(undefined);
      form.setFieldsValue({
        taxCode: "--",
      });
    }
  }, [form, sourceValue]);

  useEffect(() => {
    getVendorDetail();
  }, [getVendorDetail]);

  const onFinish = (values: any) => {
    setVisible(true);
  };

  const handleOk = async () => {
    setIsLoadingConfirm(true);
    const dataSubmit: IDecentralizationVendorUseLock = {
      lockId: dataDetail?.id as number,
      source: form.getFieldValue("source"),
      type: dataDetail?.vendor?.id ? 2 : 1, //Gán/gỡ khóa cho công ty quản lý type 1 = gắn, 2 = gỡ
      note: form.getFieldValue("note"),
    };
    const resp = await lockServices.decentralizationVendorUseLock(dataSubmit);
    const data = resp?.data;
    if (resp?.status === 200) {
      notification.success({
        message: dataDetail?.vendor?.id
          ? t("lockManagementPage.message.cancelPermissionsSuccess")
          : t("lockManagementPage.message.grantPermissionsSuccess"),
      });
      navigate(`${componentPath}/detail/${dataDetail?.id}`);
      setVisible(false);
      getDataDetail();
    } else {
      notification.error({
        message: data?.message || t("commonError.oopsSystem"),
      });
    }
    setIsLoadingConfirm(false);
  };

  const handeUnlock = async () => {
    setIsLoadingConfirmUnlock(true);
    const resp = await lockServices.forceLock(dataDetail?.id as number);
    const data = resp?.data;
    if (resp?.status === 200) {
      notification.success({
        message: t("lockManagementPage.message.forceLockSuccess"),
      });
      navigate(`${componentPath}/detail/${dataDetail?.id}`);
      setVisibleUnlock(false);
      getDataDetail();
    } else {
      notification.error({
        message: data?.message || t("commonError.oopsSystem"),
      });
    }
    setIsLoadingConfirmUnlock(false);
  };

  // Thay đổi tên khoá
  const onUpdate = async () => {
    let name = form.getFieldValue('name')
    if (name === "" || name === null || name === undefined) {
      notification.error({
        message: t("tên khoá là trường bắt buộc")
      });
      return
    }

    const resp = await lockServices.updateNameLock(dataDetail?.id as number, {
      id: dataDetail?.id as number,
      name: name
    })
    const data = resp.data;
    if (resp.code == 408) {
      notification.error({
        message: data?.message || t("commonError.network"),
      });
      return
    }
    if (resp.status === 200) {
      notification.success({
        message: t("lockManagementPage.message.changeNameLockSuccess"),
      });
      navigate(`${componentPath}/detail/${dataDetail?.id}`);
      setIsEditing(false)
      getDataDetail();
    } else {
      notification.error({
        message: data?.message || t("commonError.oopsSystem"),
      });
    }
  }

  return (
    <div className="box-wrapper">
      {isCanCViewDetail && (
        <CommonForm form={form} onFinish={onFinish} layout="vertical" isLoading={isLoading}>
          <div className="avic-search-box">
            <div className="advance-search-box">
              <div className="form-title">{t("lockManagementPage.editTitle")}</div>
              {isSEV ? (
                <div className="lock-info-detail">
                  {/* <div className="info-left">
                    <div className="lock-title">{t("lockManagementPage.vendorInfoTitle")}</div>
                    <Row gutter={20}>
                      <Col span={24}>
                        <CommonFormItem
                          name="taxCode"
                          label={t("lockManagementPage.label.taxCode")}
                          placeholder={t("lockManagementPage.placeholder.taxCode") as string}
                          disabled
                        />
                      </Col>
                      Ẩn công ty quản lý
                      <Col span={24}>
                        <CommonFormItem
                          name="source"
                          label={t("lockManagementPage.label.source")}
                          placeholder={t("lockManagementPage.placeholder.source") as string}
                          options={vendorSelectData}
                          type="select"
                          disabled={!!dataDetail?.vendor?.id || !isCanAssign}
                          showRequiredIcon={!dataDetail?.vendor?.id && isCanAssign}
                          rules={[
                            {
                              required: true,
                              message: `${t("validate.select")} ${t(
                                "lockManagementPage.label.source",
                              )}.`,
                            },
                          ]}
                        />
                      </Col>
                      <Col span={24}>
                        <CommonFormItem
                          name="note"
                          label={t("lockManagementPage.label.note")}
                          placeholder={t("lockManagementPage.placeholder.note") as string}
                          type="textArea"
                          rows={7}
                          maxLength={128}
                          disabled={!!dataDetail?.vendor?.id || !isCanAssign}
                        />
                      </Col>
                    </Row>
                  </div> */}
                  {/* <div className="info-right"> */}
                  {/* <div className="lock-title">{t("lockManagementPage.lockInfoTitle")}</div> */}
                  <Row gutter={20}>
                    <Col span={24}>
                      <CommonFormItem
                        name="name"
                        label={t("lockManagementPage.label.name")}
                        placeholder={t("lockManagementPage.placeholder.name") as string}
                        disabled={
                          !isEditing
                        }
                      />
                    </Col>
                    <Col span={24}>
                      <CommonFormItem
                        name="uniqueid"
                        label={t("lockManagementPage.label.uniqueid")}
                        placeholder={t("lockManagementPage.placeholder.uniqueid") as string}
                        disabled
                      />
                    </Col>
                    <Col span={24}>
                      <CommonFormItem
                        name="battery"
                        label={t("lockManagementPage.label.batteryLevel")}
                        placeholder={t("lockManagementPage.placeholder.batteryLevel") as string}
                        disabled
                      />
                    </Col>
                    <Col span={24}>
                      <CommonFormItem
                        name="statusLock"
                        label={t("lockManagementPage.label.statusLock")}
                        placeholder={t("lockManagementPage.placeholder.statusLock") as string}
                        disabled
                      />
                    </Col>
                    <Col span={24}>
                      <CommonFormItem
                        name="status"
                        label={t("lockManagementPage.label.status")}
                        placeholder={t("lockManagementPage.placeholder.status") as string}
                        options={LOCK_STATUS.map((x) => ({ ...x, label: t(x.label) }))}
                        type="select"
                        disabled
                      />
                    </Col>
                    <Col span={24}>
                      <CommonFormItem
                        name="signalStrengthLevel"
                        label={t("lockManagementPage.label.signalStrengthLevel")}
                        placeholder={
                          t("lockManagementPage.placeholder.signalStrengthLevel") as string
                        }
                        options={SIGNAL_STRENGTH_LEVEL.map((x) => ({ ...x, label: t(x.label) }))}
                        disabled
                        type="select"
                      />
                    </Col>
                  </Row>
                  {/* </div> */}
                </div>
              ) : (
                <Row gutter={20}>
                  <Col span={12}>
                    <CommonFormItem
                      name="name"
                      label={t("lockManagementPage.label.name")}
                      placeholder={t("lockManagementPage.placeholder.name") as string}
                      disabled
                    />
                  </Col>
                  <Col span={12}>
                    <CommonFormItem
                      name="uniqueid"
                      label={t("lockManagementPage.label.uniqueid")}
                      placeholder={t("lockManagementPage.placeholder.uniqueid") as string}
                      disabled
                    />
                  </Col>
                  <Col span={12}>
                    <CommonFormItem
                      name="battery"
                      label={t("lockManagementPage.label.batteryLevel")}
                      placeholder={t("lockManagementPage.placeholder.batteryLevel") as string}
                      disabled
                    />
                  </Col>
                  <Col span={12}>
                    <CommonFormItem
                      name="statusLock"
                      label={t("lockManagementPage.label.statusLock")}
                      placeholder={t("lockManagementPage.placeholder.statusLock") as string}
                      disabled
                    />
                  </Col>
                  <Col span={12}>
                    <CommonFormItem
                      name="status"
                      label={t("lockManagementPage.label.status")}
                      placeholder={t("lockManagementPage.placeholder.status") as string}
                      options={LOCK_STATUS.map((x) => ({ ...x, label: t(x.label) }))}
                      type="select"
                      disabled
                    />
                  </Col>
                  <Col span={12}>
                    <CommonFormItem
                      name="signalStrengthLevel"
                      label={t("lockManagementPage.label.signalStrengthLevel")}
                      placeholder={
                        t("lockManagementPage.placeholder.signalStrengthLevel") as string
                      }
                      options={SIGNAL_STRENGTH_LEVEL.map((x) => ({ ...x, label: t(x.label) }))}
                      disabled
                      type="select"
                    />
                  </Col>
                </Row>
              )}
              <Space className="form-btn-container" style={{ justifyContent: "center" }}>
                {isEditing
                  ? <CommonButton
                    onClick={() => {
                      setIsEditing(false)
                    }}
                    btnType="default"
                    size={"small"}>
                    {t("common.button.cancel")}
                  </CommonButton>
                  : <Link to={componentPath}>
                    <CommonButton btnType="default" size={"small"}>
                      {t("common.button.back")}
                    </CommonButton>
                  </Link>}
                {/* Ẩn nút huỷ phân quyền */}
                {/* {isSEV && isCanAssign ? (
                  <CommonButton
                    btnType={!dataDetail?.vendor?.id ? "primary" : "danger"}
                    size={"small"}
                    htmlType="submit"
                  >
                    {dataDetail?.vendor?.id
                      ? t("common.button.cancelPermissions")
                      : t("common.button.grantPermissions")}
                  </CommonButton>
                ) : (
                  <></>
                )} */}
                {isEditing
                  ? <CommonButton
                    onClick={onUpdate}
                    btnType="primary"
                    size={"small"}>
                    {t("common.button.update")}
                  </CommonButton>
                  : <CommonButton
                    onClick={() => {
                      setIsEditing(true)
                    }}
                    btnType="primary"
                    size={"small"}>
                    {t("common.button.edit")}
                  </CommonButton>}
                <CommonButton
                  btnType="warning"
                  size={"small"}
                  onClick={() => setVisibleUnlock(true)}
                >
                  {t("common.button.unlock")}
                </CommonButton>
              </Space>
            </div>
          </div>
        </CommonForm>
      )}

      <LockTabs dataDetail={dataDetail} />

      {visible ? (
        <CommonConfirmModal
          onCancel={() => setVisible(false)}
          content={
            <>
              <div style={{ textAlign: "left", marginBottom: "0.5rem" }}>
                <div>
                  {t("lockManagementPage.label.uniqueid")}: {dataDetail?.uniqueid || "--"}
                </div>
                <div>
                  {t("lockManagementPage.label.source")}: {vendorDetail?.companyName || "--"}
                </div>
              </div>
              <div style={{ color: "#d83939" }}>
                <div>{t("lockManagementPage.message.warning")}</div>
                <div>
                  {dataDetail?.vendor?.id
                    ? t("lockManagementPage.message.warningCancelPermissions")
                    : t("lockManagementPage.message.warningGrantPermissions")}
                </div>
              </div>
            </>
          }
          visible={visible}
          onOk={handleOk}
          loadingBtnOk={isLoadingConfirm}
          title={
            dataDetail?.vendor?.id
              ? (t("lockManagementPage.message.confirmCancelPermissions") as string)
              : (t("lockManagementPage.message.confirmGrantPermissions") as string)
          }
        />
      ) : (
        <></>
      )}

      {visibleUnlock ? (
        <CommonConfirmModal
          onCancel={() => setVisibleUnlock(false)}
          content={t("lockManagementPage.message.warningForceLock")}
          visible={visibleUnlock}
          onOk={handeUnlock}
          loadingBtnOk={isLoadingConfirmUnlock}
        />
      ) : (
        <></>
      )}
    </div>
  );
}

export default LockDetail;
