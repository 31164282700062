// api quản lý khóa
import APIService from "../utils/apiServices";
import { ICommonSearchParams } from "../utils/types";
import {
  IDecentralizationAccountUseLock,
  IDecentralizationVendorUseLock,
  ILockAddData,
  ILockDetailData,
} from "../utils/types/lockManagement";

class LockManagementServices extends APIService {
  // lấy danh sách
  async getListLock(params: ICommonSearchParams) {
    let apiParams = {
      page: params.page || 0,
      size: params.size || 10,
      sortType: params.sortType || "DESC",
      sortBy: params.sortBy || "modifiedDate",
      search: params.search || JSON.stringify({}),
    };
    return await this.request(
      "GET",
      `/api/v1/system/category/lock/getPage`,
      {},
      { params: apiParams },
    );
  }

  // thêm mới
  async createLock(data: ILockAddData) {
    return await this.request("POST", `/api/v1/system/category/lock`, data);
  }

  // chi tiết
  async detailLock(id: number) {
    return await this.request("GET", `/api/v1/system/category/lock/${id}`);
  }

  // cập nhật
  async updateLock(id: number, data: ILockDetailData) {
    return await this.request("PUT", `/api/v1/system/category/lock/${id}`, data);
  }

  // Thay đổi tên của khoá
  async updateNameLock(id: number, data: {id: number, name: string}) {
    console.log(data)
    return await this.request("PUT", `/api/v1/system/category/lock/updateName/${id}`, data);
  }

  // xóa
  async deleteLocks(data: number[]) {
    return await this.request("DELETE", `/api/v1/system/category/lock`, data);
  }

  // khóa
  async lockLock(data: number[]) {
    return await this.request("POST", `/api/v1/system/category/lock/lock`, data);
  }

  // mở khóa
  async unlockLock(data: number[]) {
    return await this.request("POST", `/api/v1/system/category/lock/unlock`, data);
  }

  // Phân quyền/Hủy phân quyền vendor sử dụng khóa
  async decentralizationVendorUseLock(data: IDecentralizationVendorUseLock) {
    return await this.request("POST", `/api/v1/vendor/changeSource`, data);
  }

  // Phân quyền/Hủy phân quyền tài khoản sử dụng khóa
  async decentralizationAccountUseLock(data: IDecentralizationAccountUseLock) {
    return await this.request("POST", `/api/v1/system/user/addOrRemoveUserLock`, data);
  }

  // mở khóa khi chưa gán vào chuyến nào
  async forceLock(id: number) {
    return await this.request("POST", `/api/command/force-unlock/${id}`);
  }

  // lấy danh sách lịch sử sử dụng khóa
  async getHistoryLock(params: ICommonSearchParams) {
    let apiParams = {
      page: params.page || 0,
      size: params.size || 10,
      sortType: params.sortType || "DESC",
      sortBy: params.sortBy || "modifiedDate",
      search: params.search || JSON.stringify({}),
    };
    return await this.request("GET", `/api/v1/lock-history/getPage`, {}, { params: apiParams });
  }

  // Xuất excell
  async exportLock(params: ICommonSearchParams) {
    return await this.request(
      "GET",
      `/api/v1/system/category/lock/exportExcel`,
      {},
      {
        responseType: "blob",
        params: params,
      },
    );
  }
  // Xuất excell lịch sử sử dụng khóa
  async exportHistoryLock(params: ICommonSearchParams) {
    return await this.request(
      "GET",
      `/api/v1/lock-history/exportExcel`,
      {},
      {
        responseType: "blob",
        params: params,
      },
    );
  }
}
const service = new LockManagementServices();
export default service;
