import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { Col, Form, Row, Space } from "antd";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CommonButton from "../../components/Common/Button";
import CommonForm from "../../components/Common/Form";
import CommonFormItem from "../../components/Common/FormItem";
import CommonInput from "../../components/Common/Input";
import { ReactComponent as Search } from "../../resources/images/search-1.svg";
import permissionsServices from "../../services/groupManagement.service";
import vendorServices from "../../services/vendorManagement.service";
import { COMMON_PAGE_STATUS, DEFAULT_PAGE_NUMBER, SEV_CODE } from "../../utils/constans";
import { useQuery } from "../../utils/customHooks";
import { ICommonSelectDataType, ISearchBoxProps, IUseQueryResponse } from "../../utils/types";
import { IGroupDetailData } from "../../utils/types/groupManagement";
import { IVendorManagementDetailData } from "../../utils/types/vendorManagement";
import { buildQueryString } from "../../utils/utilFunctions";

function SearchBox({ getData, componentPath }: ISearchBoxProps) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const queryObj: IUseQueryResponse = useQuery();
  const { params = {}, search } = queryObj;
  const { advance: advanceQuery, search: searchQuery } = params;
  const searchQueryData = searchQuery ? JSON.parse(searchQuery) : {};
  const [groupsSelectData, setGroupSelectData] = useState<ICommonSelectDataType[]>([]);
  const [vendorSelectData, setVendorSelectData] = useState<ICommonSelectDataType[]>([]);
  const [advance, setAdvance] = useState<boolean>(advanceQuery === "true" || false);
  const { profile } = useSelector((state: any) => state?.profileReducer);
  const isSEV = profile?.source === SEV_CODE;

  useEffect(() => {
    setAdvance(advanceQuery === "true");
  }, [advanceQuery]);

  const getDataSelect = useCallback(async () => {
    const paramsSearch = {
      page: 0,
      size: 1000,
      search: JSON.stringify({}),
    };

    if (isSEV) {
      //select công ty quản lý
      const respVendor = await vendorServices.getListVendorManagement(paramsSearch);
      const dataVendor = respVendor?.data;
      if (respVendor?.status === 200) {
        setVendorSelectData(
          dataVendor?.data?.content?.map((item: IVendorManagementDetailData) => ({
            value: item?.vendorCode,
            label: item?.companyName,
          })),
        );
      } else {
        setVendorSelectData([]);
      }
    }

    //select nhóm quyền
    const respPermission = await permissionsServices.getPermission(paramsSearch);
    const dataPermission = respPermission?.data;
    if (respPermission?.status === 200) {
      setGroupSelectData(
        dataPermission?.data?.content?.map((item: IGroupDetailData) => ({
          value: item?.id,
          label: item?.groupName,
        })),
      );
    } else {
      setGroupSelectData([]);
    }
  }, [isSEV]);

  useEffect(() => {
    getDataSelect();
  }, [getDataSelect]);

  const onFinish = (values: any) => {
    onSearch(values);
  };

  const onClear = (clearParams = {}) => {
    onSearch({ ...clearParams }, true);
    setTimeout(() => {
      form.resetFields();
    }, 100);
  };

  const onSearch = (data: any = {}, isClear?: boolean) => {
    const dataSearch = { ...data, groups: data?.groups?.join(",") };
    if (data?.modifiedDate?.length) {
      dataSearch["modifiedDateFromLong"] = moment(data?.modifiedDate?.[0]).startOf("day").valueOf();
      dataSearch["modifiedDateToLong"] = moment(data?.modifiedDate?.[1]).endOf("day").valueOf();
    }
    delete dataSearch.advance;
    delete dataSearch?.modifiedDate;
    let query = {
      advance,
      page: DEFAULT_PAGE_NUMBER,
      search: JSON.stringify(dataSearch),
    };
    if (isClear) {
      query.advance = data.advance;
    }
    let queryString = buildQueryString(query);
    // detect if query not change => call api
    if (queryString !== search) {
      navigate(`${componentPath}${queryString}`);
    } else {
      if (getData) getData();
    }
  };

  return (
    <div className="avic-search-box">
      {!advance ? (
        <div className="normal-search-box">
          <CommonForm
            form={form}
            onFinish={onFinish}
            layout="horizontal"
            initialValues={{
              keyword: searchQueryData?.keyword,
            }}
          >
            <CommonFormItem name="keyword">
              <CommonInput
                placeholder={t("accountManagementPage.placeholder.advancedSearch") as string}
                prefix={
                  <div
                    onClick={() => {
                      onClear({ advance: true });
                    }}
                    className="open-advance-search-btn"
                  >
                    {t("accountManagementPage.label.advancedSearch")} <CaretDownOutlined />
                  </div>
                }
                addonAfter={
                  <div onClick={() => form.submit()} className="btn-normal-search">
                    <Search />
                  </div>
                }
              />
            </CommonFormItem>
          </CommonForm>
        </div>
      ) : (
        <div className="advance-search-box">
          <div
            className="close-advance-search-btn cursor-pointer"
            onClick={() => {
              onClear({ advance: false });
            }}
          >
            <div>{t("accountManagementPage.searchTitle")}</div>
            <CaretUpOutlined className="cursor-pointer" />
          </div>

          <CommonForm
            form={form}
            onFinish={onFinish}
            layout="vertical"
            initialValues={{
              username: searchQueryData?.username,
              name: searchQueryData?.name,
              source: searchQueryData?.source,
              groups: searchQueryData?.groups?.split(","),
              modifiedBy: searchQueryData?.modifiedBy,
              modifiedDate:
                searchQueryData?.modifiedDateFromLong && searchQueryData?.modifiedDateToLong
                  ? [
                      moment(searchQueryData?.modifiedDateFromLong),
                      moment(searchQueryData?.modifiedDateToLong),
                    ]
                  : [],
              status: searchQueryData?.status,
            }}
          >
            <Row gutter={30}>
              <Col span={12}>
                <CommonFormItem
                  name="username"
                  label={t("accountManagementPage.label.username")}
                  placeholder={t("accountManagementPage.placeholder.username") as string}
                />
              </Col>
              <Col span={12}>
                <CommonFormItem
                  name="name"
                  label={t("accountManagementPage.label.name")}
                  placeholder={t("accountManagementPage.placeholder.name") as string}
                />
              </Col>
              {/* Ẩn thông tin tìm kiếm với công ty quản lý */}
              {/* {isSEV ? (
                <Col span={12}>
                  <CommonFormItem
                    name="source"
                    label={t("accountManagementPage.label.source")}
                    placeholder={t("accountManagementPage.placeholder.source") as string}
                    options={vendorSelectData}
                    type="select"
                  />
                </Col>
              ) : (
                <></>
              )} */}
              <Col span={12}>
                <CommonFormItem
                  name="groups"
                  label={t("accountManagementPage.label.groups")}
                  placeholder={t("accountManagementPage.placeholder.groups") as string}
                  options={groupsSelectData}
                  type="select"
                  mode="multiple"
                />
              </Col>
              <Col span={12}>
                <CommonFormItem
                  name="modifiedBy"
                  label={t("accountManagementPage.label.modifiedBy")}
                  placeholder={t("accountManagementPage.placeholder.modifiedBy") as string}
                />
              </Col>
              <Col span={12}>
                <CommonFormItem
                  name="modifiedDate"
                  type="rangePicker"
                  label={t("accountManagementPage.label.modifiedDate")}
                  placeholder={[t("common.fromDate"), t("common.toDate")]}
                />
              </Col>
              <Col span={12}>
                <CommonFormItem
                  name="status"
                  label={t("accountManagementPage.label.status")}
                  placeholder={t("accountManagementPage.placeholder.status") as string}
                  options={COMMON_PAGE_STATUS.map((x) => ({ ...x, label: t(x.label) }))}
                  type="select"
                />
              </Col>
            </Row>
            <Space className="form-btn-container">
              <CommonButton
                btnType="default"
                size={"small"}
                onClick={() => onClear({ advance: true })}
              >
                {t("common.button.deleteCondition")}
              </CommonButton>
              <CommonButton btnType="primary" size={"small"} htmlType="submit">
                {t("common.button.search")}
              </CommonButton>
            </Space>
          </CommonForm>
        </div>
      )}
    </div>
  );
}

export default SearchBox;
