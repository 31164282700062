// Api danh mục loại xe
import APIService from "../utils/apiServices";
import { ICommonSearchParams } from "../utils/types";
import { IDriverAddData, IDriverDetailData } from "../utils/types/driverCategory";

class DriverCategory extends APIService {
  // Lấy danh sách
  async getListDriver(params: ICommonSearchParams) {
    let apiParams = {
      page: params.page || 0,
      size: params.size || 10,
      sortType: params.sortType || "DESC",
      sortBy: params.sortBy || "modifiedDate",
      search: params.search || JSON.stringify({}),
    };
    return await this.request(
      "GET",
      `/api/v1/system/category/driver/getPage`,
      {},
      { params: apiParams },
    );
  }

  // Thêm mới
  async createDriver(data: IDriverAddData) {
    return await this.request("POST", `/api/v1/system/category/driver`, data);
  }

  // Chỉnh sửa
  async updateDriver(id: number, data: IDriverDetailData) {
    return await this.request("PUT", `/api/v1/system/category/driver/${id}`, data);
  }

  // Xóa
  async deleteDriver(data: number[]) {
    return await this.request("DELETE", `/api/v1/system/category/driver`, data);
  }

  // Xuất excel
  async exportDriver(params: ICommonSearchParams) {
    return await this.request(
      "GET",
      `/api/v1/system/category/driver/exportExcel`,
      {},
      {
        responseType: "blob",
        params: params,
      },
    );
  }
}
const service = new DriverCategory();
export default service;
