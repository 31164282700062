import CommonModal from '../../components/Common/Modal'
import CommonForm from '../../components/Common/Form'
import { Col, Form, message, notification, Row } from 'antd';
import { IDriverAddModalProps } from '../../utils/types/driverType';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import CommonFormItem from '../../components/Common/FormItem';
import { COMMON_PAGE_STATUS, ROLE_LIST } from '../../utils/constans';
import driverCategory from "../../services/driverCategory.service";
import CommonButton from '../../components/Common/Button';
import { useSelector } from 'react-redux';
import { isHavePermission } from '../../utils/utilFunctions';

function DriverAddModal({
  dataDetail,
  visible,
  handleOk,
  handleCancel
}: IDriverAddModalProps) {
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isEditing, setIsEditing] = useState<boolean>(false)

  const { profile } = useSelector((state: any) => state?.profileReducer);
  const isCanEdit = isHavePermission(ROLE_LIST.MANAGER_DRIVER_EDIT, profile);

  const onFinish = async (values: any) => {
    setIsLoading(true)
    const dataUpdate = {...values}
    const resp = dataDetail?.id
      ? await driverCategory.updateDriver(dataDetail?.id, dataUpdate)
      : await driverCategory.createDriver(dataUpdate)
    if (resp?.status === 200) {
      notification.success({
        message: dataDetail?.id
          ? t("driverCategoryPage.message.editSuccess")
          : t("driverCategoryPage.message.createSuccess"),
      });
      setIsEditing(false);
      handleOk();
    } else {
      notification.error({
        message: t("driverCategoryPage.message.oopsSystem"),
      });
    }

    setIsLoading(false)
  }

  return (
    <CommonModal
      title={
        dataDetail?.id ? t("driverCategoryPage.editTitle") : t("driverCategoryPage.addTitle")
      }
      open={visible}
      maskClosable={false}
      onCancel={handleCancel}
      footer={[
        isEditing && dataDetail?.id ? (
          <CommonButton onClick={() => setIsEditing(false)} size={"small"} btnType="default">
            {t("common.button.cancel")}
          </CommonButton>
        ) : (
          <CommonButton onClick={handleCancel} size={"small"} btnType="default">
            {t("common.button.exit")}
          </CommonButton>
        ),
        isCanEdit && dataDetail?.id ? (
          isEditing ? (
            <CommonButton
              form="myForm"
              onClick={() => form.submit()}
              btnType="primary"
              size={"small"}
              loading={isLoading}
            >
              {t("common.button.update")}
            </CommonButton>
          ) : (
            <CommonButton onClick={() => setIsEditing(true)} btnType="primary" size={"small"}>
              {t("common.button.edit")}
            </CommonButton>
          )
        ) : (
          <></>
        ),

        !dataDetail?.id ? (
          <CommonButton
            form="myForm"
            onClick={() => form.submit()}
            btnType="primary"
            size={"small"}
            loading={isLoading}
          >
            {t("common.button.addNew")}
          </CommonButton>
        ) : (
          <></>
        ),
      ]}>
      <CommonForm
        form={form}
        onFinish={onFinish}
        layout='vertical'
        initialValues={{
          name: dataDetail?.name,
          uniqueid: dataDetail?.uniqueid,
          numberOfJourney: "0",
          status: ''
        }}>
        <Row gutter={30}>
          <Col span={24}>
            <CommonFormItem
              name="name"
              label={t("driverCategoryPage.label.name")}
              placeholder={t("driverCategoryPage.placeholder.name") as string}
              maxLength={128}
              showRequiredIcon={
                !(!!dataDetail?.id && (!isCanEdit || !isEditing))
              }
              disabled={!!dataDetail?.id && (!isCanEdit || !isEditing)}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: `${t("validate.input")} ${t("driverCategoryPage.label.name")}`
                }
              ]}
            />
          </Col>
          <Col span={24}>
            <CommonFormItem
              name="uniqueid"
              label={t("driverCategoryPage.label.identity")}
              placeholder={t("driverCategoryPage.placeholder.identity") as string}
              maxLength={128}
              showRequiredIcon={
                !(!!dataDetail?.id && (!isCanEdit || !isEditing))
              }
              disabled={!!dataDetail?.id && (!isCanEdit || !isEditing)}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: `${t("validate.input")} ${t("driverCategoryPage.label.identity")}`
                }
              ]}
            />
          </Col>
          {/* {dataDetail?.id ? <Col span={24}>
            <CommonFormItem
              type="select"
              name="status"
              label={t("driverCategoryPage.label.status")}
              placeholder={t("driverCategoryPage.placeholder.status") as string}
              maxLength={128}
              showRequiredIcon={
                !(!!dataDetail?.id && (!isCanEdit || !isEditing))
              }
              disabled={!!dataDetail?.id && (!isCanEdit || !isEditing)}
              options={COMMON_PAGE_STATUS.map((x) => ({ ...x, label: t(x.label) }))}
              rules={[
                {
                  required: true,
                  message: `${t("validate.input")} ${t("driverCategoryPage.label.status")}`
                }
              ]}
            />
          </Col> : <></>} */}
        </Row>
      </CommonForm>
    </CommonModal>
  )
}

export default DriverAddModal