import { ConfigProvider, notification } from "antd";
import enUS from "antd/es/locale/en_US";
import viVN from "antd/es/locale/vi_VN";
import moment from "moment";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Routes from "./Routes";
import { saveProfile } from "./redux/actions/profile.actions";
import userServices from "./services/accountManagement.service";
import { LANGUAGE_LIST, VI_MOMENT_CONFIG } from "./utils/constans";
import { SIDEBAR_ITEM_HREF } from "./utils/constans/sidebar";
import LocalStorage from "./utils/localStorage";

const localLanguage = LocalStorage.getInstance().read("language") || LANGUAGE_LIST[0]?.value;

let locale = viVN;
switch (localLanguage) {
  case LANGUAGE_LIST[1]?.value:
    locale = enUS;
    break;
  default:
    moment.locale("vi", VI_MOMENT_CONFIG);
    locale = viVN;
    break;
}

notification.config({
  duration: 10,
});

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    // initial load
    const accessToken = LocalStorage.getInstance().read("accessToken");
    // TEST: token
    // console.log(`token: ${accessToken}`);
    if (accessToken) {
      getProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProfile = async () => {
    const resp = await userServices.getProfile();
    const data = resp.data;
    if (resp?.status === 200) {
      dispatch(saveProfile(data?.data));
    } else if (resp?.code === "408" || resp?.code === "401") {
      LocalStorage.getInstance().save("accessToken", null);
      window.location.href = SIDEBAR_ITEM_HREF.home;
    }
  };

  return (
    <>
      <ConfigProvider locale={locale}>
        <Routes />
      </ConfigProvider>
    </>
  );
}

export default App;
