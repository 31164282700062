import L, { LatLngExpression } from "leaflet";
import _ from "lodash";
import moment from "moment";
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import { Marker, Popup, useMap } from "react-leaflet";
import { leafIcon } from "../../../../components/Map";
import journeyMarker from "../../../../resources/images/marker/journey_marker.png";
import locationMarkerIcon from "../../../../resources/images/marker/location_marker.png";
import journeyServices from "../../../../services/journeyManagement.service";
import { DATE_TIME_FORMAT_SECOND, SAMSUNG_LOCATION, SEV_CODE } from "../../../../utils/constans";
import {
  IJourneyDetailData,
  IRealTimeLocationData,
} from "../../../../utils/types/journeyManagement";

const PlayBackControl = ({
  detailData,
  tabKey,
  setIsPlay,
  currentTime,
}: {
  detailData: IJourneyDetailData;
  tabKey: string;
  setIsPlay: Dispatch<SetStateAction<boolean>>;
  currentTime: number;
}) => {
  const mapComponent = useMap();
  const popupRef = useRef<any>();
  const polylineRef = useRef<any>();
  const iconMarker = leafIcon(journeyMarker);
  const locationMarker = leafIcon(locationMarkerIcon);
  const [listAllData, setListAllData] = useState<IRealTimeLocationData[]>(); //list tất cả vị trí
  const [currentListData, setCurrentListData] = useState<IRealTimeLocationData[]>([]); // list các vị trí đến hiện tại

  const getData = useCallback(async () => {
    if (detailData?.id && tabKey === "history-tab") {
      const resp = await journeyServices.getListAllLocation(detailData?.id);
      const data = resp?.data;
      if (resp?.status === 200) {
        const convertData = _.sortBy(data?.data, "deviceTime");
        const firstTime = convertData?.[0]?.deviceTime || 0;
        const newData = convertData?.map((item: any) => ({
          ...item,
          timeCover: Math.abs(item?.deviceTime - firstTime) / 60000,
          date: item.deviceTime ? moment(item.deviceTime).format(DATE_TIME_FORMAT_SECOND) : "--",
        }));
        setListAllData(newData);
        return;
      }
    }
    setListAllData([]);
    return;
  }, [detailData?.id, tabKey]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (listAllData?.length && currentTime <= listAllData?.[listAllData?.length - 1]?.timeCover) {
      const newList = listAllData?.filter((item) => item?.timeCover <= currentTime);
      const lastLocation = newList?.[newList?.length - 1];
      setCurrentListData(newList);
      if (typeof lastLocation?.lat === "number" && typeof lastLocation?.lon === "number") {
        mapComponent.flyTo([lastLocation?.lat, lastLocation?.lon], 20);
        popupRef.current?.openPopup();
      }
    } else {
      setIsPlay(false);
    }
  }, [currentTime, listAllData, mapComponent, setIsPlay]);

  useEffect(() => {
    if (currentListData && currentListData?.length > 0) {
      if (polylineRef.current) {
        mapComponent.removeLayer(polylineRef.current);
      }
      polylineRef.current = L.polyline(
        currentListData?.map((item: any) => [item.lat, item.lon]) as LatLngExpression[],
        {
          color: "#4285F4",
          weight: 10,
          opacity: 1,
        },
      ).addTo(mapComponent);

      // mapComponent.fitBounds(polyline.getBounds());
    }
  }, [currentListData, mapComponent]);

  return (
    <>
      {currentListData?.[currentListData?.length - 1] &&
      currentListData?.[currentListData?.length - 1]?.lat &&
      currentListData?.[currentListData?.length - 1]?.lon > 0 ? (
        <Marker
          position={
            [
              currentListData?.[currentListData?.length - 1]?.lat,
              currentListData?.[currentListData?.length - 1]?.lon,
            ] as LatLngExpression
          }
          icon={iconMarker}
          alt="Marker alt"
          ref={popupRef}
        >
          <Popup>
            <h3 className="mgbt-10">{currentListData?.[currentListData?.length - 1]?.date}</h3>
          </Popup>
        </Marker>
      ) : (
        <></>
      )}

      {/* Vị trí SEV */}
      <Marker
        position={SAMSUNG_LOCATION as LatLngExpression}
        icon={locationMarker}
        alt="Marker alt"
        title={SEV_CODE}
      >
        <Popup>
          <h3 className="mgt-20 text-center">{SEV_CODE}</h3>
        </Popup>
      </Marker>

      {/* Vị trí Vendor */}
      {detailData?.vendor?.lat && detailData?.vendor?.lon ? (
        <Marker
          position={[detailData?.vendor?.lat, detailData?.vendor?.lon] as LatLngExpression}
          icon={locationMarker}
          alt="Marker alt"
          title={detailData?.vendor?.companyName || "--"}
        >
          <Popup>
            <h3 className="mgt-20 text-center">{detailData?.vendor?.companyName || "--"}</h3>
          </Popup>
        </Marker>
      ) : (
        <></>
      )}
    </>
  );
};

export default PlayBackControl;
