import { ICommonSelectDataType } from "../types";

export const JOURNEY_STATUS_VALUES = {
  CHUA_VAO_SEV: 1,
  DA_GAN_KHOA_DANG_GIAO: 2,
  CHECK_OUT_SEV: 3,
  GIAO_THANH_CONG: 4,
  HUY_GIAO_HANG: 5,
  DA_GAN_KHOA_CHUA_XAC_NHAN: 6,
  CHECK_OUT_VENDOR: 7,
  KHOI_TAO: 8,
};

export const JOURNEY_STATUS_OPTIONS: ICommonSelectDataType[] = [
  {
    value: "DA_DANG_KY_VAO_SEV",
    label: "common.options.registeredToSEV",
    type: "default",
  },

  {
    value: "DA_KHOI_HANH",
    label: "common.options.started",
    type: "info",
  },

  {
    value: "GIAO_HANG_THANH_CONG",
    label: "common.options.deliverySuccessful",
    type: "success",
  },
  {
    value: "DA_HUY",
    label: "common.options.cancelDelivery",
    type: "danger",
  },
];

export const JOURNEY_STATUS_OPTIONS_TABLE: ICommonSelectDataType[] = [
  {
    value: "Đã đăng ký vào SEV",
    label: "common.options.registeredToSEV",
    type: "default",
  },

  {
    value: "Đã khởi hành",
    label: "common.options.started",
    type: "info",
  },

  {
    value: "Giao hàng thành công",
    label: "common.options.deliverySuccessful",
    type: "success",
  },
  {
    value: "Đã hủy",
    label: "common.options.cancelDelivery",
    type: "danger",
  },
];

export const OPTION_DELIVERY: ICommonSelectDataType[] = [
  {
    value: 1,
    label: "common.options.haveMerchandise",
  },
  {
    value: 2,
    label: "common.options.haveNotMerchandise",
  },
];

export const ACTION_TYPE_VALUES = {
  ACCTION: 2,
  VIOLATION: 1,
};
export const ACTION_TYPE_OPTIONS = [
  {
    value: ACTION_TYPE_VALUES.ACCTION,
    label: "common.options.action",
  },
  {
    value: ACTION_TYPE_VALUES.VIOLATION,
    label: "common.options.violation",
  },
];
