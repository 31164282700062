import L, { LatLngExpression } from "leaflet";
import "leaflet/dist/leaflet.css";
import { useTranslation } from "react-i18next";
import { LayersControl, MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import markerShasow from "../../resources/images/marker/marker-shadow.png";
import ViIcon from "../../resources/images/vietnamese.svg";
import { HANOI_LOCATION, HOANGSA_LOCATION, TRUONGSA_LOCATION } from "../../utils/constans";
import { IMapComponent } from "../../utils/types";
import MapControls from "./MapControls";

export const leafIcon = (iconUrl: string) => {
  return new L.Icon({
    iconUrl: iconUrl,
    iconRetinaUrl: iconUrl,
    shadowUrl: markerShasow,
    shadowRetinaUrl: markerShasow,
    iconSize: [25, 41],
    shadowSize: [40, 20],
    shadowAnchor: [10, 20],
    iconAnchor: [12, 41],
    popupAnchor: [0, -30],
  });
};

function MapComponent({ isShowSearch = false, children, isShowCurrentBtn = true }: IMapComponent) {
  const { t } = useTranslation();
  const viIconMarker = new L.Icon({
    iconUrl: ViIcon,
    iconRetinaUrl: ViIcon,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [0, -30],
  });

  return (
    <div className="map-wrapper">
      <MapContainer center={HANOI_LOCATION as LatLngExpression} zoom={16} scrollWheelZoom={true}>
        <LayersControl>
          <LayersControl.BaseLayer name="Traffic View">
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              maxZoom={20}
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer checked name="Satellite View">
            <TileLayer
              url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
              maxZoom={20}
              subdomains={["mt1", "mt2", "mt3"]}
            />
          </LayersControl.BaseLayer>
        </LayersControl>
        <Marker
          position={HOANGSA_LOCATION as LatLngExpression}
          icon={viIconMarker}
          alt={t("common.hoangSa") as string}
          title={t("common.hoangSa") as string}
        >
          <Popup>
            <h2>{t("common.hoangSa")}</h2>
          </Popup>
        </Marker>
        <Marker
          position={TRUONGSA_LOCATION as LatLngExpression}
          icon={viIconMarker}
          alt={t("common.truongSa") as string}
          title={t("common.truongSa") as string}
        >
          <Popup>
            <h2>{t("common.truongSa")}</h2>
          </Popup>
        </Marker>
        <MapControls isShowSearch={isShowSearch} isShowCurrentBtn={isShowCurrentBtn} />
        {children}
      </MapContainer>
    </div>
  );
}

export default MapComponent;
